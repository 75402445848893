import React, { useState } from 'react'
import { Tabs } from 'antd'
import FundTeam from './component/FundTeam'
import FundCompanyInfo from './component/FundCompanyInfo'
import TopFormBg from 'comps/TopFormBg'
import moment from 'moment'
import ModalSuccess from 'comps/ModalSuccess'
import SubmitModal from 'comps/SubmitModal'
import axios from 'libs/axios'
import H5ModalSuccess from 'comps/H5ModalSuccess'

const { TabPane } = Tabs

const BusinessFund = props => {
    const [activeKey,setActiveKey ] = useState('1')
    const [allData, setAllData] = useState({})
    const [isShow, setIsShow] = useState(false)
    const [isTrue, setIsTrue] = useState(false)
    const [str, setStr] = useState('')
    const [h5IsShow, setH5IsShow] = useState(false)

    const tabChange = key => {
        setActiveKey(key)
    }
    // 下一步按钮 
    const nextStep = () => {
        if (Number(activeKey) < 2){
            setActiveKey(String(Number(activeKey) + 1))
        }
    }
    const tabFormCompany = v => {
        nextStep()
        let obj = {}
        obj = Object.assign(obj,{...v,founded: moment(v.founded).unix()})
        setAllData(Object.assign(allData, { 'company': obj }))
    }
    const tabFormTeam = v => {
        const newData = JSON.stringify(Object.assign(allData, {'team': v, type: 2, group: 2, date: 2021})) 
        setStr(newData)
        setIsTrue(true)
    }
    const submitForm = () => {
        axios.post('/apply', { data: str }).then(res => {
            if (res.code === 10000){
                if (window.innerWidth >= 750){
                    setIsTrue(false)
                    setIsShow(true)
                } else {
                    setIsTrue(false)
                    setH5IsShow(true)
                }
            } 
        })
    }
    const changeTrue = value => {
        setIsTrue(value)
    }
    return <>
        <TopFormBg type={2} />
        <section className='innovate-con-form fund-con container'>
            <h1>融资组/Without Funding</h1>
            <Tabs activeKey={activeKey} onChange={tabChange}>
                <TabPane tab="公司信息/Company Info" key="1">
                    <FundCompanyInfo onSubmit={tabFormCompany} />
                </TabPane>
                <TabPane tab="团队成员信息/Team" key="2">
                    <FundTeam onSubmit={tabFormTeam} />
                </TabPane>
            </Tabs>
        </section>
        <SubmitModal isTrue={isTrue} text='是否确认提交？' close={changeTrue} ok={submitForm}/>
        <ModalSuccess isShow={isShow} backURL='/signup/business' text='感谢报名Synbio Hive创业大赛！平台已开启“企业挑战”特别环节，方向符合的项目可选择（非必须）其中一个方向参与企业挑战。有关赛事的任何问题，欢迎联系：info@synbiohive.com'/>
        <H5ModalSuccess isShow={h5IsShow} backURL='/signup/business' text='感谢报名Synbio Hive创业大赛！平台已开启“企业挑战”特别环节，方向符合的项目可选择（非必须）其中一个方向参与企业挑战。有关赛事的任何问题，欢迎联系：info@synbiohive.com'/>
    </>
} 

export default BusinessFund
