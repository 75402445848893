
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Menu } from 'antd'
import { getStorage, clearStorage } from 'utils/storage'
import className from 'classname'
import 'styles/comps/nav.scss'
import { connect } from 'react-redux'
import { getUnReadNum } from '@redux/information/action'
@connect(state => state, { getUnReadNum })
class Nav extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navList: [
                { id: 1, title: '首页', link: '/home' },
                {
                    id: 2, title: '大赛报名', link: '/signup', items: [
                        { id: 1, title: '创新大赛', url: '/innovate' },
                        { id: 2, title: '创业大赛', url: '/business' },
                        { id: 3, title: '企业挑战', url: '/challenge' },
                    ]
                },
                { id: 6, title: '官方通知', link: '/notice' },
                { id: 3, title: '评委', link: '/jury' },
                { id: 4, title: '媒体资讯', link: '/news' },
                { id: 5, title: '相关视频', link: '/video' }
            ],
            currentIndex: 1,
            childIndex: null
        }

    }
    componentDidMount() {
        this.checkPath()
    }
    componentDidUpdate(preveProps, preveState) {
        if (preveProps.location.pathname !== this.props.location.pathname) {
            this.checkPath()
        }

    }
    //导航选中
    checkPath = () => {
        const findResult = this.state.navList.find(val => this.props.location.pathname.includes(val.link))
        if (findResult) {
            const childResult = findResult.items && findResult.items.find(val => this.props.location.pathname.includes(val.url))
            this.setState({
                currentIndex: findResult.id,
                childIndex: childResult && childResult.id
            })
        } else if (this.props.location.pathname === '/' || this.props.location.pathname === '/home') {
            this.setState({
                currentIndex: 1
            })
        } else {
            this.setState({
                currentIndex: null
            })
        }
    }

    //父导航切换
    handleClick = id => {
        this.setState({
            currentIndex: id,
        })
    }

    //子导航切换
    handleChildClick = (item, ite) => {
        this.setState({
            childIndex: ite.id,
        }, () => {
            window.location.href = item.link + ite.url
        })
    }

    goOut = () => {
        this.axios.post('/logout').then(res => {
            if (res.code === 10000) {
                clearStorage()
                window.location.reload(true)
            }
        })
    }

    render() {
        const { navList, currentIndex, childIndex} = this.state
        const {unReadNum} = this.props
        console.log('unReadNum', unReadNum)
        return (
            <div className="nav-container">
                <div className="nav-con">
                    {this.props.location.pathname.includes('/business') || this.props.location.pathname.includes('/challenge') ||  this.props.location.pathname === '/reading' ? 
                        <a href="/"><img src={require('assets/images/mainlogo.png')} alt="" className='nav-logo' /></a> :
                        this.props.location.pathname.includes('/innovate') ?  <a href="/"><img src={require('assets/images/mainlogo2.png')} alt="" className='nav-logo2' /></a> :
                            <a href="/"><img src={require('assets/images/mainlogo1.png')} alt="" className='nav-logo2' /></a> 
                            
                    }
                    <div className="nav-right">
                        <div className="nav-list">
                            {
                                navList.map((item, index) => {
                                    return <div className="nav-item"  key={index} >
                                        {
                                            item.items && item.items.length ? <span className={className(["links", { "links-active": currentIndex === item.id }])} >{item.title}
                                                {
                                                    <div className={className("nav-child", [{'nav-child2': this.props.location.pathname.includes('/business') || this.props.location.pathname.includes('/challenge')}])}>
                                                        {
                                                            item.items.map((ite, ind) => {
                                                                return <div key={ind} className={className([{ "child-links-active": childIndex === ite.id }])} onClick={() => this.handleChildClick(item, ite)}>{ite.name || ite.title}</div>
                                                            })
                                                        }
                                                    </div>
                                                }
                                            </span> : <Link to={item.link} key={item.id} className={className(["linkantd", { 'linkantd-active': currentIndex === item.id }])} onClick={() => this.handleClick(item.id)} >{item.title}</Link>
                                        }
                                    </div>
                                })
                            }
                        </div>
                        <div className="nav-login">
                            <a href="/information" className="infocen">
                                <img src={require('assets/images/xiaoxizhongxin.png')} alt="" />
                                {Number(unReadNum ) ? <div className="num">{unReadNum}</div> : null}
                            </a>
                            {
                                getStorage('token') ? <div className='person'>
                                    <img src={require('assets/images/gerenzhongxin.png')} alt="" onClick={() => window.location.href = '/usercenter'}/><div className="person-list">
                                        <div className="person-item" onClick={() => window.location.href = '/usercenter'}>个人中心</div>
                                        <div className="person-item go-out" onClick={() => { this.goOut() }}>退出登录</div>
                                    </div></div> :
                                    <div className='no-login'>
                                        <a href='/login'>登录</a>
                                        <a href='/register'>注册</a>
                                    </div> 
                            }
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default withRouter(Nav)

