import React, { useState, useEffect } from 'react'
import { Form, Input, Button, Select, message } from 'antd'
import TIPS from 'pages/json/tips.json'
import LABELS from 'pages/json/labels.json'
import AREA from 'pages/json/area.json'
import className from 'classname'
import axios from 'libs/axios'
import { OnlyCH,MobileDelBr } from 'utils'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'

import 'styles/user/business.scss'
import 'styles/form/innovate.scss'

const { Option } = Select
let province = []

const Innovate = props => {
    MobileDelBr(LABELS)
    const [form] = Form.useForm()
    const [isChange, setChange] = useState(false)
    const [name_cn, setName_cn] = useState('')
    const [name_en, setName_en] = useState('')
    const [email, setEmail] = useState('')
    const [city, setCity] = useState([])
    const [county, setCounty] = useState([])
    const [data, setData] = useState({})
    const [area, setArea] = useState(0)
    const [areaStr, setAreaStr] = useState('')
    const domainUrl = process.env.API_ENV === 'test' ? 'https://twww.synbiohive.com' : 'http://www.synbiochallenges.com'
    const [wikiUrl, setWikiUrl] = useState('')
    const [is_run, setIsRun] = useState(props.match.params.is_run || '')
    const onFinish = values => {
        let obj = {
            ...values,
            instructor_team_name: data.instructor_team_name,
            date: 2021,
            type: 1,
            id: props.match.params.id
        }
        axios.post('/apply', { data: JSON.stringify(obj) }).then(res => {
            if (res.code === 10000) {
                message.success('保存成功！')
                setChange(false)
            }
        })
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    //修改信息
    const changeMess = () => {
        setChange(true)
    }

    // 区域分级
    const change = () => {
        AREA.forEach(item => {
            if (item.parent === '100000') {
                province.push(item)
            }
        })
        province.forEach(item => {
            item.items = []
            AREA.find(i => {
                if (i.parent === item.id + '') {
                    item.items.push(i)
                }
                return null
            })
        })
        province.forEach(item => {
            item.items.forEach(i => {
                i.items = []
                AREA.find(n => {
                    if (n.parent === i.id + '') {
                        i.items.push(n)
                    }
                    return null
                })
            })
        })
    }

    const provinceChange = (value, type) => {
        let arr = []
        AREA.find(item => {
            if (item.parent === value + '') {
                arr.push(item)
            }
            return null
        })
        type === 1 ? setCity(arr) : setCounty(arr)
    }

    useEffect(() => {
        change()
        axios.post('/user/info').then(res => {
            if (res.code === 10000) {
                setName_cn(res.data.name_cn)
                setName_en(res.data.name_en)
                setEmail(res.data.email)
            }
        })
        axios.post('/game/get', { id: props.match.params.id }).then(res => {
            if (res.code === 10000) {
                setData(res.data)
                setArea(res.data.location.area)
                provinceChange(res.data.location.province, 1)
                provinceChange(res.data.location.city, 2)
                setWikiUrl(`${domainUrl}/wiki/${res.data.date}/${res.data.instructor_team_name}/index.html`)
                form.setFieldsValue({
                    ...res.data
                })
                let l = ''
                AREA.forEach(item=>{
                    if (item.id === res.data.location.province){
                        return l = l + item.fullname
                    }
                })
                AREA.forEach(item=>{
                    if (item.id === res.data.location.city){
                        return l = l + item.fullname
                    }
                })
                AREA.forEach(item=>{
                    if (item.id === res.data.location.area){
                        return l = l + item.fullname
                    }
                })
                setAreaStr(l)
            }
        })
    }, [form, isChange, props.match.params.id, props.match.params.is_run])

    return <div className='innovate-con container'>
        <div className="title">个人中心/Me <div className="bor"></div></div>
        <div className="title2">我的报名/My Projects-创新大赛</div>
        <div className="wiki">Wiki</div>
        <div className="url">     地址/URL         <a href={wikiUrl} target='_blank' rel="noopener noreferrer">{wikiUrl}</a></div>
        <p className='header-tip' dangerouslySetInnerHTML={{ __html: TIPS.innovation_contest_tips }}></p>
        <div className="title3"><span>队伍信息/Team</span> 
            { Number(is_run) === 1 ? <img src={require('assets/images/xiugai.png')} alt='xiugai' className="xiugai" onClick={changeMess} /> : null}
        </div>
        <Form
            {...layout}
            form={form}
            className='innovate-form'
            name="innovate"
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
        >
            {/* 负责老师姓名 */}
            <Form.Item
                className='double-input'
                label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_ch_name }}></i>}
            >
                <div className='double-input-div'>{data.name_cn}</div>
            </Form.Item>
            <Form.Item
                {...noLabelLayout}
            >
                <div className='double-input-div'>{data.name_en}</div>
            </Form.Item>

            {/* 负责老师邮箱 */}
            <Form.Item
                className='double-input'
                label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_ch_email }}></i>}
            >
                <div className='double-input-div'>{data.email}</div>
            </Form.Item>

            {/* 负责老师所在学校 */}
            <Form.Item
                className='double-input'
                label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_ch_institution }}></i>}
                name="instructor_ch_institution"
                rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }]}
            >
                {/* <Input placeholder='请输入负责老师所在学校' readOnly={!isChange} className={className([isChange && 'changemess'])} /> */}
                <div className='double-input-div'>{data.instructor_ch_institution}</div>
            </Form.Item>
            <Form.Item
                {...noLabelLayout}
                name="instructor_en_institution"
                rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }, { validator: OnlyCH }]}
            >
                {/* <Input placeholder="Instructor's Institution" readOnly={!isChange} className={className([isChange && 'changemess'])} /> */}
                <div className='double-input-div'>{data.instructor_en_institution}</div>
            </Form.Item>
            {/* <span className='btm-tip' dangerouslySetInnerHTML={{ __html: TIPS.ins_tip_institution }}></span> */}

            {/* 负责老师所在学院 */}
            <Form.Item
                className='double-input'
                label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_ch_department }}></i>}
                name="instructor_ch_department"
                rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }]}
            >
                <Input placeholder='请输入负责老师所在学院' readOnly={!isChange} className={className([isChange && 'changemess'])} />
            </Form.Item>
            <Form.Item
                {...noLabelLayout}
                name="instructor_en_department"
                rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }, { validator: OnlyCH }]}
            >
                <Input placeholder="Instructor's Department" readOnly={!isChange} className={className([isChange && 'changemess'])} />
            </Form.Item>
            <span className='btm-tip' dangerouslySetInnerHTML={{ __html: TIPS.ins_tip_department }}></span>

            {/* 队伍名称 */}
            <Form.Item
                className='double-input'
                label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_team_name }}></i>}
                // name="instructor_team_name"
                // rules={[{ required: true, message: '队伍名称不能为空！' }, { max: 20, message: '长度不能超过20个字符！' }]}
            >
                {/* <Input placeholder='请输入队伍名称/Team Name' readOnly={!isChange} className={className([isChange && 'changemess'])} /> */}
                <div className='double-input-div'>{data.instructor_team_name}</div>
            </Form.Item>
            {/* <span className='btm-tip' dangerouslySetInnerHTML={{ __html: TIPS.ins_tip_team_name }}></span> */}

            {/* 队伍所在地区 */}
            <Form.Item
                label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_team_location }}></i>}
                className="zone"
            >

                {
                    !isChange ? <div className='double-input-div'>{areaStr}</div>
                        :
                        <Input.Group compact>
                            <Form.Item
                                name={['location', 'province']}
                                noStyle
                                rules={[{ required: true, message: '请选择省份！' }]}
                            >
                                <Select placeholder="省" onChange={(value, type) => { provinceChange(value, 1) }}>
                                    {
                                        province.map((item, index) => {
                                            return <Option key={index} value={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={['location', 'city']}
                                noStyle
                                rules={[{ required: true, message: '请选择市！' }]}
                            >
                                <Select placeholder="市" onChange={(value, type) => { provinceChange(value, 2) }} >
                                    {
                                        city.map((item, index) => {
                                            return <Option key={index} value={item.id}>{item.name}</Option>
                                        })
                                    }
                                </Select>
                            </Form.Item>
                            {
                                <Form.Item
                                    name={['location', 'area']}
                                    noStyle
                                    rules={[{ required: true, message: '请选择区县！' }]}
                                >
                                    <Select placeholder="区县">
                                        {
                                            county.map((item, index) => {
                                                return <Option key={index} value={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            }
                        </Input.Group>
                }
            </Form.Item>

            <Form.Item {...buttonLayout} className='button-box'>
                {
                    isChange && <Button type="primary" htmlType="submit"> 保存 </Button>
                }
            </Form.Item>
        </Form>
    </div>
}

export default Innovate
