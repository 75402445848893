
import React, { Component } from 'react'
import { Form, Input, Button, Modal  } from 'antd'
import 'styles/login/login.scss'
import Dialog from './Dialog'
import { Msg, H5Msg } from 'utils'

class ResetPwd extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            isVisible: false,
            isShow: false,
            seconds: 60,
            isDisabled: false,
            dlgTipTxt: '发送验证码'
        }

    }
    componentDidMount(){
    }


    onFinish = values => {
        const { username, code, pwd1, pwd2 } = values
        this.axios.post('/find', { mobile: username, pwd1, pwd2, code }).then(res => {
            if (res.code === 10000) {
                if (window.innerWidth >= 750){
                    this.setState({ isShow: true })
                } else {
                    H5Msg('密码重置成功', 3)
                    this.props.history.push('/login')
                }
            }
        })
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    };

    //发送验证码
    sendCode = () => {
        this.formRef.current.validateFields(['username']).then(value => {
            this.axios.post('/code', { mobile: this.formRef.current.getFieldValue('username'), type: 2 }).then(res => {
                if (res.code === 10000) {
                    Msg('success', '短信验证码已发出！')
                    this.setState({ isDisabled: true })
                    // 验证码倒计时
                    let siv = setInterval(() => {
                        this.setState(preState => ({
                            seconds: preState.seconds - 1,
                            dlgTipTxt: `${preState.seconds - 1}s`
                        }), () => {
                            if (this.state.seconds === 0) {
                                clearInterval(siv)
                                this.setState({
                                    isDisabled: false, dlgTipTxt: "获取验证码", seconds: 60
                                })
                            }
                        })
                    }, 1000)
                }
            })
        }).catch(error => {
            console.log(error, 'error')
        })
    }

    //清空input
    clickDel = () => {
        console.log('清空')
    }

    //密码显隐
    clickVisible = () => {
        this.setState({
            isVisible: !this.state.isVisible
        })
    }

    //确定重置密码
    clickReset = () => {
        this.setState({
            isShow: true
        })
    }

    //关闭弹框
    closeDialog = () => {
        this.setState({
            isShow: false
        })
    }

    // pwd1
    checkPwd1 = (rule, value) => {
        const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/
        if (reg.test(value)){
            return Promise.resolve()
        }
        return Promise.reject('长度为6-12个字符，需含有字母大写、字母小写、数字，不允许有空格、中文！')
    }

    // pwd2
    checkPwd2 = (rule, value) => {
        if (value && value !== this.formRef.current.getFieldValue('pwd1')) {
            return Promise.reject('两次密码输入不一致！')
        }
        return Promise.resolve()
    }

    // username
    checkUserValue = (rule, value) => {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (reg.test(value) && value.length === 11) {
            return Promise.resolve()
        }
        if (!value || value.length === 0) {
            return Promise.reject('手机号码不能为空！')
        }
        return Promise.reject('手机号码格式错误！')
    }

    render() {
        const {isVisible, isShow, isDisabled, dlgTipTxt} = this.state
        return (
            <div className='content-box register-box reset-box'>
                <div className="login">
                    <div className="logo">
                        <img src={require('assets/images/logo.png')} alt='logo'/>
                    </div>
                    {/* <div className="logo-h5">
                        <img src={require('assets/images/h5-mainlogo.png')} alt=""/>
                    </div> */}
                    <div className='title'>重置密码</div>
                    <Form
                        ref={this.formRef}
                        name="basic"
                        initialValues={{ remember: true }}
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                        validateTrigger="onBlur" 
                    >
                        <Form.Item
                            name="username"
                            className='user'
                            rules={[{ validator: this.checkUserValue }]}
                        >
                            <Input autocomplete="off" prefix={<img src={require('assets/images/shouji.png')} alt='shouji'/>} placeholder='请输入手机号码'/>
                        </Form.Item>

                        <Form.Item
                            name="code"
                            className='code'
                            rules={[{ required: true, message: '短信验证码不能为空!' }]}
                        >
                            <Input autocomplete="off" prefix={<img src={require('assets/images/mima1.png')} alt='mima1'/>} suffix={<div disabled={isDisabled} className='sendcode' onClick={this.sendCode}>{dlgTipTxt}</div>} placeholder='请输入验证码'/>
                        </Form.Item>

                        <Form.Item
                            name="pwd1"
                            className='pwd1'
                            rules={[{ validator: this.checkPwd1 }]}
                        >
                            <Input.Password prefix={<img src={require('assets/images/mima1.png')} alt='mima1'/>}  placeholder='请输入6-12位登录密码'/>
                        </Form.Item>   

                        <Form.Item
                            name="pwd2"
                            className='pwd2'
                            rules={[{ required: true, message: '密码不能为空!' },{ validator: this.checkPwd2 }]}
                        >
                            <Input.Password prefix={<img src={require('assets/images/mima1.png')} alt='mima1'/>}  placeholder='请输入6-12位登录密码'/>
                        </Form.Item> 
                        <Button type="primary" htmlType="submit" >重置密码</Button>
                        <a href='/login' className='back'>返回登录</a>
                    </Form>
                    <img src={require('assets/images/shanchu1.png')} alt='shanchu1' className='cancel' onClick={()=>this.props.history.go(-1)} />
                </div>
                <Dialog isShow={isShow} type='reset' closeDialog={this.closeDialog} {...this.props}/>
            </div>
        )
    }
}
export default ResetPwd

