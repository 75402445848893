
import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import 'styles/user/isdel-dialog.scss'
class isDelDialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isShow: this.props.IsDelShow
        }
    }

    componentDidMount(){
    }

    handleOk = () => {

    }

    closeDialog = () => {
        this.setState({
            isShow: false
        })
    }
    render() {
        return (
            <Modal visible={this.props.IsDelShow}  onOk={this.handleOk} onCancel={this.props.closeDialog}  destroyOnClose footer={null} centered={true} className='isdel-dialog'>
                <div className="ask">是否确认删除？</div>
                <div className="btn-box">
                    <Button type="primary" htmlType="submit" className="ok" onClick={this.props.challengeDelOK}>确认</Button>
                    <div className="cancel" onClick={this.props.closeDialog}>取消</div>
                </div>
                <div className='btn-box-h5'>
                    <div className="cancel" onClick={this.props.closeDialog}>取消</div>
                    <div className="ok" onClick={this.props.challengeDelOK}>确认</div>
                </div>
            </Modal>
        )
    }
}
export default isDelDialog

