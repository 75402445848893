
import React, { Component } from 'react'
import 'styles/notice/notice.scss'
import { Skeleton, Pagination } from 'antd'
import NoticeItem from './NoticeItem'
import className from 'classname'
import moment from 'moment'
class Notice extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1,
            page_size: 5,
            noticeList: [],
            total: 0,
            isLoading: true,
            height: 0,
            h5NoticeList: [],
            h5List: []
        }
        this.saveRef = React.createRef()

    }
    componentDidMount(){
        this.getNoticeData()
        this.getH5NoticeData()
    }

    getNoticeData = () => {
        const {page, page_size} = this.state
        this.axios.post('/notice/index', {page, page_size}).then(res => {
            console.log('res', this.formatDataList(res.data.items,  'date'))
            let noticeList = this.formatDataList(res.data.items,  'date')
            if (res.code === 10000){
                this.setState({
                    noticeList,
                    total: res.data.total,
                    isLoading: false
                })
            }
        })
    }

    getH5NoticeData = () => {
        const {page, page_size} = this.state
        this.axios.post('/notice/index', {page, page_size}).then(res => {
            // console.log('res', res)
            if (res.code === 10000){
                let h5List = this.state.h5List
                h5List.push(...res.data.items)
                let data = this.formatDataList(h5List,  'date')
                this.setState({
                    h5NoticeList: data,
                    total: res.data.total,
                    isLoading: false,
                    h5List,
                })
            }
        })
    }

    checkUrl = url => {
        if (url){
            window.location.href = url
        }  
    }

    onChange = page =>{
        this.setState({page, isLoading: true}, ()=>{
            this.getNoticeData()
        })
    }

    onShowSizeChange = (current, size) => {
        // console.log('current, size', current, size)
        this.setState({
            page: current,
            page_size: size
        }, ()=>{
            this.getNoticeData()
        })
    }
    isToday = time => {
        let d = new Date(time).setHours(0, 0, 0, 0)
        let today = new Date().setHours(0, 0, 0, 0)
        let obj = {
            '-86400000': '昨天',
            0: '今天',
            86400000: '明天',
        }
        return obj[d - today] || false
    }

    formatDate = time => {
        let str
        if (this.isToday(time) === '今天'){
            str = '今天'
        } else {
            str = moment(time).format('YYYY-MM-DD')
        }
        return str
    }

    onTouchMove = () => {
        // console.log('滚动的高度', window.pageYOffset)
        // console.log('可使区高度', document.body.clientHeight)
        // console.log('页面高度', document.body.scrollHeight)
        let height = document.body.scrollHeight - document.body.clientHeight - window.pageYOffset
        this.setState({height})
    }

    //按照日期分类
    formatDataList = (data, attribute) => {
        data.forEach(item => {
            item.date = this.formatDate(item.start_time * 1000)
        })
        const arr = []
        const obj = {}
        data.forEach(element => {
            if (!obj[element[attribute]]) {
                arr.push({
                    date: element[attribute],
                    allData: [element],
                })
                obj[element[attribute]] = element
            } else {
                arr.forEach(ele => {
                    if (ele.date === element[attribute]) {
                        ele.allData.push(element)
                    }
                })
            }
        })
        return arr
    }

    onScroll = () => {
        let height = document.body.scrollHeight - document.body.clientHeight - window.pageYOffset
        if (Math.floor(height) <= 80 && this.state.total > this.state.h5List.length){
            this.setState({page: this.state.page + 1}, () => {
                this.getH5NoticeData()
            })
          
        }
    }
    render() {
        const {isLoading, noticeList, total, page, page_size, h5NoticeList} = this.state
        return (
            <div>
                {
                    window.innerWidth > 750 ? <div className="notice-list" onTouchEnd={this.onScroll}  onTouchMove={this.onTouchMove}>
                        <Skeleton active loading={isLoading}>
                            {
                                total ?  <div>
                                    {
                                        noticeList.map(item => {
                                            return <div className="note-item">
                                                <div className="time">{item.date}</div>
                                                {
                                                    item.allData.map(ite => {
                                                        return <div className={className(["content", {"content-active": ite.url}])} onClick={() => this.checkUrl(ite.url)}>
                                                            <div className="title">{ite.title}</div>
                                                            <NoticeItem maxHeight={110} introduce={ite.content}></NoticeItem>
                                                        </div>
                                                    })
                                                }
                                            </div> 
                                        })
                                    }
                                    <Pagination onChange={this.onChange} total={total} pageSize={page_size} current={page} onShowSizeChange={this.onShowSizeChange} showSizeChanger={false}/>
                                </div> : <div className="nodata">
                                    <img src={require('assets/images/nonotice.png')}  alt=""/>
                                    <div className="text">暂无官方通知～</div>
                                </div>
                            }
                            
     
                        </Skeleton>
                     
                    </div> : <div className="notice-list" onTouchEnd={this.onScroll}  onTouchMove={this.onTouchMove}>
                        <Skeleton active loading={isLoading}>
                            {
                                total ?  h5NoticeList.map(item => {
                                    return <div className="note-item">
                                        <div className="time">{item.date}</div>
                                        {
                                            item.allData.map(ite => {
                                                return <div className={className(["content", {"content-active": ite.url}])} onClick={() => this.checkUrl(ite.url)}>
                                                    <div className="title">{ite.title}</div>
                                                    <NoticeItem maxHeight={110} introduce={ite.content}></NoticeItem>
                                                </div>
                                            })
                                        }
                                    </div> 
                                }) : <div className="nodata">
                                    <img src={require('assets/images/nonotice.png')}  alt=""/>
                                    <div className="text">暂无官方通知～</div>
                                </div>
                            }
                        </Skeleton>
                
                    </div>
                }
            </div>
            
        )
    }
}
export default Notice

