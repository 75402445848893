
import React, { Component } from 'react'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import 'styles/comps/test.scss'

class Test extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: []
        }
    }

    componentDidMount() {
        this.instanceSwiper()
        this.axios.post('/banner', { type: 1 }).then(res => {
            if (res.code === 10000) {
                this.setState({ list: res.data })
            }
        })
    }

    instanceSwiper = () => {
        let that = this
        new Swiper('#test', {
            slidesPerView: 1,
            loop: false,
            autoplay: {// 自动滑动
                delay: 5000, //3秒切换一次
                // stopOnLastSlide: false,
                disableOnInteraction: false,//
            },
            spaceBetween: 0,
            observer: true,//修改swiper自己或子元素时，自动初始化swiper    重要
            observeParents: true,//修改swiper的父元素时，自动初始化swiper  重要
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                renderBullet: function (index, className) {
                    return '<span class="' + className + '"></span>'
                },
            },
        })
    }

    render() {
        const { list } = this.state
        return (
            <div className="test-box">
                <div className="test-content" id='test-content'>
                    <div className="swiper-container" id="test">
                        <div className="swiper-wrapper">
                            {
                                list.map((item, index) => {
                                    return <img key={index} src={item.img} alt="" className='swiper-slide' onClick={() => item.url ?  window.location.href = `${item.url}` : null}/>

                                })
                            }
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Test


// import { Carousel } from 'antd'
// import axios from 'libs/axios'
// import React, { useEffect, useState } from 'react'
// import { useHistory } from 'react-router-dom'

// function Test() {
//     let history = useHistory()
//     const [list, setList] = useState([])
//     const onChange = (a, b, c) => {
//         console.log(a, b, c)
//     }
//     useEffect(() => {
//         axios.post('/banner', { type: 1 }).then(res => {
//             if (res.code === 10000) {
//                 setList(res.data)
//             }
//         })
//     }, [])
//     const contentStyle = {
//         height: '100vh-90px',
//         width: '100%'
//     }
//     return <div id="test">
//         <Carousel>
//             {
//                 list.map((item, index) => {
//                     return <div key={index}>
//                         <img src={item.img} alt="" style={contentStyle} onClick={() => window.location.href = item.url} />
//                     </div>
//                 })
//             }
//         </Carousel>
//     </div>
// }

// export default Test