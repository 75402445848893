
import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import 'styles/signup/dialog.scss'
class Dialog2 extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }

    handleOk = () => {

    }

    handleCancel = () => {
        this.props.closeDialog()
    }

    login = () => {
        window.location.href = '/usercenter'
    }
    render() {
        const {isShow, isUserCheck, isPC} = this.props
        return (
            <>
                {
                    isPC ? <Modal  visible={isShow} onOk={this.handleOk} onCancel={this.handleCancel} destroyOnClose footer={null} className='sign-up-dialog' width={634}>
                        <img src={require('assets/images/shanchu1.png')} alt='del' className='del' onClick={this.handleCancel}/>
                        <img src={require('assets/images/human2.png')} alt='human' className='human'/>
                        <div className='title'>{!isUserCheck ? '请到个人中心进行个人信息完善' : '您已报过名，请到个人中心进行查看'}</div>
                        <Button type="primary" htmlType="submit" onClick={this.login}>进入个人中心</Button>
                    </Modal> : <Modal  visible={isShow} onOk={this.handleOk} onCancel={this.handleCancel} destroyOnClose footer={null} className='sign-up-dialog-h5' width={315}>
                        <div className='title'>{!isUserCheck ? '请到个人中心进行个人信息完善' : '您已报过名，请到个人中心进行查看'}</div>
                        <div className='btns'>
                            <div className="cancel" onClick={this.handleCancel}>取消</div>
                            <div className="ok" onClick={this.login}>进入个人中心</div>
                        </div>
                    </Modal>
                }
                
                
            </>
        )
    }
}
export default Dialog2

