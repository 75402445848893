import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Table, Skeleton } from "antd"
import "styles/user/myteam.scss"
import AddMemberDialog  from '../user/component/myTeam/AddMember'
import DeleteMemberDialog  from '../user/component/myTeam/DeleteMember'
import AuthorizationRole  from '../user/component/myTeam/AuthorizationRole'
class AddMember extends Component {
    constructor(props) {
        super(props)
        this.state = {
            columns: [
                {
                    title: '序号',
                    dataIndex: 'index',
                    key: 'index',
                    render: (text, record,index) => (
                        <div>{index + 1}</div>
                    ),
                },
                {
                    title: '手机号码',
                    dataIndex: 'mobile',
                    key: 'mobile',
                },
                {
                    title: '姓名',
                    dataIndex: 'name_cn',
                    key: 'name_cn',
                },
                {
                    title: '邮箱',
                    dataIndex: 'email',
                    key: 'email',
                },
                {
                    title: '角色',
                    dataIndex: 'role_name',
                    key: 'role_name',
                },
                {
                    title: '操作',
                    dataIndex: 'modify_auth',
                    key: 'modify_auth',
                    render: (text, record,index) => (
                        <div className="operate">{text ? <div><span onClick={() =>this.authorization(record)}>授权角色</span><span onClick={()=> this.deleteMember(record)}>移出队伍</span></div> : <div className="noright">您无操作权限</div>}</div>
                    ),
                }
            ],
            dataSource:[],
            pageSize: 10,
            total: 0,
            currentPage: 1,
            team_id: this.props.match.params.team_id,
            // team_id: 39,
            isLoading: true,
            team_name: '',
            user_info: {},
            isShowAddMember: false,
            isShowAuthorization: false,
            isShowDelMember: false,
            selectItem: {},
            roleOption: [],
            team_member_add_auth: false
        }
    }
    componentDidMount() {
        this.getMemberList()
        if (Number(this.props.match.params.is_run) === 2){
            this.setState({
                columns: [
                    {
                        title: '序号',
                        dataIndex: 'index',
                        key: 'index',
                        render: (text, record,index) => (
                            <div>{index + 1}</div>
                        ),
                    },
                    {
                        title: '手机号码',
                        dataIndex: 'mobile',
                        key: 'mobile',
                    },
                    {
                        title: '姓名',
                        dataIndex: 'name_cn',
                        key: 'name_cn',
                    },
                    {
                        title: '邮箱',
                        dataIndex: 'email',
                        key: 'email',
                    },
                    {
                        title: '角色',
                        dataIndex: 'role_name',
                        key: 'role_name',
                    }
                ]
            })
        }
    }
    onPageChange = value => {
        console.log(`selected ${value}`)
    };

    // 获取队伍列表
    getMemberList = () => {
        const {team_id} = this.state
        this.axios.post('/team/getTeamMemberList', { page: this.state.currentPage, size: this.state.pageSize, team_id})
            .then(res => {
                if (res.code === 10000){
                    this.setState({
                        total: res.data.total,
                        dataSource: res.data.member_list,
                        team_name: res.data.team_name,
                        user_info: res.data.user_info,
                        team_member_add_auth: res.data.team_member_add_auth,
                        isLoading: false
                    }, () => {
                        this.getRoleOption()
                    })
                }
            })
    }

    //添加成员
    AddMemberFn = () => {
        this.setState({
            isShowAddMember: true,
            // isShowAuthorization: true
        })
    }

    // 关闭弹框
    closeDialog = () => {
        this.setState({
            isShowAddMember: false,
            isShowAuthorization: false,
            isShowDelMember: false
        }, () => {
            this.getMemberList()
        })
    }

    // 授权
    authorization = record => {
        this.setState({isShowAuthorization: true, selectItem: record})
    }

    //移除队员
    deleteMember = record => {
        this.setState({isShowDelMember: true, selectItem: record})
    }

    getRoleOption = () => {
        this.axios.post('/role/getRoleOption')
            .then(res => {
                if (res.code === 10000){
                    let role_level = this.state.user_info.role_level
                    let roleOption = []
                    res.data.forEach(item => {
                        if (item.level > role_level){
                            roleOption.push(item)
                        }
                    })
                    this.setState({
                        roleOption
                    })
                }
            })
    }

    goBack = () => {
        this.props.history.push({pathname:'/usercenter', state:{defaultActiveKey: 2}})
    }
    render() {
        const { columns, dataSource, pageSize, total, currentPage, isLoading, team_name, user_info, isShowAddMember, isShowAuthorization, team_id, selectItem, isShowDelMember, roleOption, team_member_add_auth } = this.state
        const {is_run} = this.props.match.params
        return (
            <div className="myteam">
                <AddMemberDialog isShowAddMember={isShowAddMember} closeDialog={this.closeDialog} team_id={team_id} roleOption={roleOption}/>
                <DeleteMemberDialog isShowDelMember={isShowDelMember}  closeDialog={this.closeDialog}  team_id={team_id} selectItem={selectItem}/>
                <AuthorizationRole isShowAuthorization={isShowAuthorization} closeDialog={this.closeDialog}  team_id={team_id} user_id={selectItem.user_id} roleOption={roleOption}/>
                <Skeleton loading={isLoading} active>
                    <div className="maintitle">
                        <div className="title">当前队伍名称：<div className="name">{team_name}</div></div>
                        <div className="title">当前角色：<div className="name">{user_info.role_name}</div></div>
                        <div className="btns">
                            {(team_member_add_auth && Number(is_run) === 1) ? <div className="add" onClick={this.AddMemberFn} ><img src={require('assets/images/tianjia.png')}  alt=''/>添加队伍成员</div> : <div></div>}
                            <div className="add" onClick={this.goBack}>返回我的报名</div>
                        </div>
                    </div>
                    <Table columns={columns} dataSource={dataSource} pagination={false}
                        // pagination={{pageSize: pageSize, total: total, current: currentPage, onChange: this.onPageChange}} 
                    />
                </Skeleton>
            </div>
        )
    }
}
export default withRouter(AddMember)
