import React, { Component, useState, useEffect } from 'react'
import { Modal, Button, Radio, Skeleton } from 'antd'
import 'styles/comps/paymodal.scss'
import axios from 'libs/axios'
import QRCode from 'qrcode'
const PayModal = props => {
    const [value, setValue] = useState(1)  //类型，1微信，2阿里
    const [paymoney, setPayMoney] = useState()
    const [orderId, setOrderId] = useState() //订单id
    const [platform, setPlatform] = useState()  //打开方式,1PC，2H5，3微信浏览器
    const [alipayCon, setAlipayCon] = useState() //支付宝
    const [wechatCodeUrl, setWechatCodeUrl] = useState('') //微信支付二维码地址
    const [isLoading, setLoading] = useState(true)
    const options = platform === 3 ? [
        { label: '微信支付', value: 1 },
    ] :  [
        { label: '微信支付', value: 1 },
        { label: '支付宝支付', value: 2 },
    ] 
    const onChange = e =>{
        setValue(e.target.value)
    }
    useEffect(() => {
        userAgent()
        getOrder()


    }, [value, platform])

    //判断打开方式
    const userAgent = () => {
        if (props.isPC){
            // console.log('pc端')
            setPlatform(1)
        } else {
            var ua = navigator.userAgent.toLowerCase()
            var isWeixin = ua.indexOf('micromessenger') !== -1
            //在微信浏览器打开
            if (isWeixin) {
                // console.log('微信浏览器')
                setPlatform(3)
            } else {
                // console.log('h5')
                setPlatform(2)
            }
        }
    }

    //生成订单
    const getOrder = () => {
        // props.payId
        props.payId && axios.post('/order', {id: props.payId}).then(res => {
            // console.log('生成订单', res, value)
            if (res.code === 10000){
                setPayMoney(res.data.money)
                setOrderId(res.data.order_id)
                getPayCode(res.data.order_id)
                setLoading(false)
            }
        })
    }

    //支付
    const getPayCode = id => {
        // , test: 'test'
        if (platform && platform === 3){
            axios.post('/pay/openid', {id}).then(res => {
                console.log('微信浏览器打开', res)
                if (res.code === 10000){
                    setAlipayCon(res.data.url)
                }
            })
        } else {
            platform && axios.post('/pay', {id , type: value, platform}).then(res => {
                // console.log('订单二维码', res)
                if (res.code === 10000){
                    if (platform === 1){
                    //PC端支付
                        if (value === 1){
                            //微信生成二维码
                            QRCode.toDataURL(res.data)
                                .then(url => {
                                    // console.log(url)
                                    setWechatCodeUrl(url)
                                })
                                .catch(err => {
                                    console.error(err)
                                })
                        } else if (value === 2){
                            //唤起支付宝
                            getAlipay(res.data)
                        }
    
                    } else if (platform === 2){
                        setAlipayCon(res.data)
                    }
                }
            })
    
        }
        
        
    }

    //手机端确定支付
    const goToPay = () => {
        // console.log('手机端支付')
        if (platform === 2){
            //手机端支付, 非微信浏览器打开
            if (value === 1){
                //微信支付
                // console.log('h5非微信浏览器打开')
                window.location.href = alipayCon

            } else if (value === 2){
                //唤起支付宝
                getAlipay(alipayCon)
            }

        } else if (platform === 3){
            //微信浏览器打开
            // console.log('微信打开', alipayCon)
            window.location.href = alipayCon
            
        }

    }

    //唤起支付宝
    const getAlipay = form => {
        const div = document.createElement('div')
        div.id = 'alipayPc'
        div.innerHTML = form
        document.body.appendChild(div)
        console.log('document.querySelector', document.querySelector('#alipayPc'))
        document.querySelector('#alipayPc').children[0].submit()
    }

    //判断pc微信扫码是否支付完成
    const checkPay = () => {
        // console.log('orderId', orderId)
        props.closeDialog() 
        // setIsShowPcWechatPay(true)
        // orderId && axios.post('/pay/query', {id: orderId} ).then(res => {
        //     console.log('res', res)
        //     if (res.code === 10000){
        //         setIsShowPcWechatPay(true)
        //         //已经支付成功
        //         if (res.data === 10000){
        //             setIsShowPcWechatPayRe(true)
        //         } else {
        //             setIsShowPcWechatPayRe(false)
        //         }
                
        //     }
        // })
       
    }

    return (
        <div>
            <Modal title='订单支付' visible={props.isPayShow} centered={true} destroyOnClose footer={null} getContainer={false} className='pay-modal' onCancel={() => props.closeDialog()}>
                <Skeleton active loading={isLoading}>
                    <div className='body-dir'>
                        {
                            LaBel('订单详情：', '创新大赛报名费用')
                        }
                        {
                            LaBel('应付金额：', `${Math.floor(paymoney) === paymoney ? paymoney  + '.00' : paymoney}元`)
                        }
                        {
                            LaBel('支付方式：', <Radio.Group
                                options={options}
                                onChange={onChange}
                                value={value}
                                optionType="button"
                            />)
                        }
                    </div>
                    <div className='paycode-con'>
                        {
                            value === 1 && <img src={wechatCodeUrl} alt=""/>
                        }
                        {value === 1 && <p>打开手机微信扫一扫完成支付</p>}
                        {props.isPC && value === 1 && <p className='yes-pay' onClick={checkPay}>确认完成支付</p>}
                    </div>
                    <div className='tip'><span>支付须知：<br/>1.支付状态存在一定延迟，请等待一分钟后刷新列表查询支付状态  <br/>2.支付后不能退款，如需开发票，请联系info@synbiochallenges.com</span></div>
                    <div  className="btn-box-h5">
                        <div className="cancel" onClick={() => props.closeDialog()}>取消</div>
                        <div className="ok" onClick={goToPay}>支付</div>
                    </div>
                </Skeleton>
            </Modal>
        </div>
    )
}

export default PayModal

const LaBel = (title, content) => {

    return <div className='label'>
        <span className='title'>{title}</span>
        {
            typeof(content) === 'string' ? <span className='content'>{content}</span> : content
        }
    </div>
}


