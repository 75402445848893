/**
 * form width css,response
 * xs: '480px',
 * sm: '576px',
 * md: '768px',
 * lg: '992px',
 * xl: '1200px',
 * xxl: '1600px',
 */
module.exports = {
    layout: {
        labelCol: { 
            xs: { span:24 },
            sm: { span: 8 },
            md: { span: 7 },
        }, 
        wrapperCol: { 
            xs: { span:24 },
            sm: { span: 15 },
            md: { span: 15 } 
        }
    },
    noLabelLayout : {
        wrapperCol: {
            xs:{ span:24 },
            sm:{ offset: 8, span: 15 },
            md:{ offset: 7, span: 15}
        },
    },
    buttonLayout : {
        wrapperCol: { span: 24 }
    },
}