import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Table, Tabs, Pagination, Skeleton } from "antd"
import "styles/user/information.scss"
import TeamInvite  from '../user/component/myTeam/TeamInvite'
import { getStorage } from 'utils/storage'
import { connect } from 'react-redux'
import { getUnReadNum } from '@redux/information/action'
@connect(state => state, { getUnReadNum })
class Information extends Component {
    constructor(props) {
        super(props)
        this.state = {
            unread_count: 0,
            msg_id: '',
            state: 0,
            size: 10,
            page: 1,
            total:0,
            list:[],
            isLoading: true,
            isShowTeamInvite: false,
            selectItem: {},
            token: getStorage('token')
        }
    }
    componentDidMount() {
        if (this.state.token){
            this.getUnreadMessageNotice()
            this.getMessageList()
        } else {
            this.setState({isLoading: false})
        }
        
    }

    //分页
    onPageChange = value => {
        this.setState({page: value}, ()=> {
            this.getMessageList()
        })
    };

    //tab切换
    callback = key => {
        this.setState({state: key, page: 1}, ()=> {
            this.state.token && this.getMessageList()
        })
    }

    //消息列表
    getMessageList = () => {
        const {state, size, page} = this.state
        this.axios.post('/message/getMessageList', {state, size, page}).then(res => {
            if (res.code === 10000) {
                this.setState({
                    total:res.data.total,
                    list:res.data.items,
                    isLoading: false
                })
            }
        })
    }

    //未读消息
    getUnreadMessageNotice = () => {
        this.axios.post('/message/getUnreadMessageNotice').then(res => {
            if (res.code === 10000) {
                // console.log('getUnreadMessageNotice', res.data)
                this.setState({unread_count: res.data.unread_count})
                this.props.getUnReadNum(res.data.unread_count)
            }
        })
    }

    //消息已读
    readMessage = msg_id => {
        this.axios.post('/message/readMessage', {msg_id}).then(res => {
            if (res.code === 10000) {
                this.getMessageList()
                this.getUnreadMessageNotice()
            }
        })
    }

    //查看消息
    handleClick = item => {
        // console.log('item', item)
        Number(item.state) === 1 && this.readMessage(item.id)
        this.setState({
            isShowTeamInvite: true,
            selectItem: item
        })
    }

    // 关闭弹框
    closeDialog = () => {
        this.setState({
            isShowTeamInvite: false
        }, () => {
            this.getMessageList()
        })
        
    }

    handlelogin = () => {
        this.props.history.push('/login')
    }
    render() {
        const { TabPane } = Tabs
        const {unread_count, page, total, size, isLoading, list, isShowTeamInvite, selectItem, token} = this.state
        return (
            <div className="information">
                <Skeleton active loading={isLoading}>
                    <div className="info-container">
                        <div className="tablist">
                            <div className="item"><img src={require('assets/images/letter.png')} alt="" />消息</div>
                        </div>
                        <div className="container">
                            <Tabs defaultActiveKey="0" onChange={this.callback}>
                                <TabPane tab="全部" key="0"></TabPane>
                                <TabPane tab={
                                    <div className="infocen">
                                    未读
                                        {unread_count ? <div className="num">{unread_count}</div> : null}
                                    </div>
                                } key="1">
                                </TabPane>
                                <TabPane tab="已读" key="2"></TabPane>
                            </Tabs>
                            {
                                token ? <div className="info-list">
                                    {
                                        total ? <div>
                                            {
                                                list.map(item => (
                                                    <div className="item" onClick={() =>this.handleClick(item)}>
                                                        <div className="avatar">
                                                            {Number(item.state) === 1 ? <div className="circle"></div> : null}
                                                            <img src={require('assets/images/avatar.png')} alt="" />
                                                        </div>
                                                        <div className="info-inner">
                                                            <div className="title">{item.title}<div className="date">{item.create_time}</div></div>
                                                            <div className="inner">{item.content}</div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                            <Pagination defaultCurrent={1} total={total} current={page} pageSize={size} onChange={this.onPageChange}/>
                                        </div> :
                                            <div className="noinfo">
                                                <img src={require('assets/images/noinfo.png')} alt=""/>
                                                <div>暂无消息</div>
                                            </div>
                                    }
                                </div> : <div className="noinfo">
                                    <img src={require('assets/images/noinfo.png')} alt=""/>
                                    <img src={require('assets/images/logintocheck.png')} alt="" className="logintocheck" onClick={this.handlelogin}/>
                                </div>
                            }
                            
                        </div>
                    </div>
                    <TeamInvite isShowTeamInvite={isShowTeamInvite} closeDialog={this.closeDialog} selectItem={selectItem}/>
                </Skeleton>
            </div>
        )
    }
}
export default withRouter(Information)
