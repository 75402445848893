
import React, { Component } from 'react'
import { Tabs} from 'antd'
import NoFundTeam from './component/NoFundTeam'
import CompanyInfo from './component/CompanyInfo'
import NoFundBaseInfo from './component/NoFundBaseInfo'

import 'styles/user/business.scss'

class BusinessNoFund extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isChange: true,
            activeKey: '1',
            data:{},
            id:this.props.match.params.id
        }
    }

    componentDidMount(){
        this.getData()
    }
    getData = () => {
        const { id } = this.state
        // 请求未融资的报名数据
        this.axios.post('/game/get',{ id }).then(res => {
            if (res.code === 10000){
                this.setState({ data:res.data })
            }
        })
    }
    // 切换tab页
    tabChange = key => this.setState({ activeKey:key })
    // 是否进入编辑状态
    changeMess = () => this.setState({ isChange: false })
    // 保存成功之后 进入只读状态
    readStatus = () => this.setState({ isChange: true })

    render() {
        const { TabPane } = Tabs
        const { isChange,activeKey,data:{basicData,teamData,companyData},id } =  this.state
        return (
            <div className="business-nofund container">
                <div className="title">个人中心/Me <div className="bor"></div></div>
                <div className="title2"><span>我的报名/My Projects-创业大赛-未融资组</span> 
                    {/* <img src={require('assets/images/xiugai.png')} alt='xiugai' className="xiugai" onClick={this.changeMess}/> */}
                </div>
                <Tabs activeKey={activeKey} onChange={this.tabChange}>
                    <TabPane tab="基本信息/Base Info" key="1">
                        { typeof basicData === 'object' && <NoFundBaseInfo isRead={isChange} data={basicData} id={id} readStatus={this.readStatus} getData={this.getData}/> }
                    </TabPane>

                    <TabPane tab="团队主创成员信息/Team" key="2">
                        { typeof teamData === 'object' && <NoFundTeam isRead={isChange} data={teamData} id={id} readStatus={this.readStatus}/> }
                    </TabPane>

                    <TabPane tab="项目信息/Project" key="3">
                        { typeof companyData === 'object' && <CompanyInfo isRead={isChange} data={companyData} id={id} 
                            readStatus={this.readStatus} getData={this.getData}/> }
                    </TabPane>
                </Tabs>
            </div>
        )
    }
}
export default BusinessNoFund

