import React, { Component, useState, useEffect  } from 'react'
import { Modal, Button } from 'antd'
import 'styles/comps/paysuccessmodal.scss'

const PaySuccessModal = props =>{
    const [result, setResult] = useState(true)
    useEffect(() => {
        // console.log('getQueryString(', getQueryString('code'), getQueryString('code') * 1)
        if (getQueryString('code') * 1 === 10000){
            setResult(true)
        } else {
            setResult(false)
        }
    }, [])

    const getQueryString = name => {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
        var r = window.location.search.substr(1).match(reg)
        if (r != null) {return  unescape(r[2])} return null
    }
    return (
        <Modal visible={true} centered={true} destroyOnClose footer={null} getContainer={false} className='paysuccess-modal'>
            <img src={require(`assets/images/${result ? 'wancheng' : 'shibai'}.png`)} alt=""/>
            <p>{result ? '支付成功' : '支付失败'}</p>
            <div className='btn' onClick={() => window.location.href = '/usercenter'}>{result ? '完成' : '返回'}</div>
        </Modal>
    )
}

export default PaySuccessModal
