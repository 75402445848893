import React, { useEffect, useState } from 'react'
import { Form,Button,Input,DatePicker,Checkbox,message } from 'antd'
import LABELS from 'pages/json/labels.json'
import TIPS from 'pages/json/tips.json'
import moment from 'moment'
import axios from 'libs/axios'
import { OnlyCH,websiteRule,MobileDelBr } from 'utils'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'

const { TextArea } = Input

const CompanyInfo = props => {
    const { isRead,data } = props
    const [ form ] = Form.useForm()
    const [ checked, setChecked ] = useState(data.sector)
    const [ options, setOptions ] = useState([])
    const newData = {...data,founded:data.founded ? moment(data.founded) : 0}
    delete newData.sector // 去掉数据的赛道的数据，变成可控组件
    form.setFieldsValue(newData)

    // 时间选择范围
    const disabledDate = current=>{
        // Can not select days after today and today
        return current && current > moment().endOf('day')
    }

    // 限制checkbox
    const checkboxChange = v => {
        setChecked(v)
    }

    // 请求多个赛道
    useEffect(()=>{
        axios.post('/game',{type:2}).then(res=>{
            if (res.code === 10000){
                setOptions(res.data.items)
            }
        })
    },[])
    // 编辑 成功上传
    const onSubmit = v => {
        const data = Object.assign({},
            {'companyData':{...v,founded:v.founded ? v.founded.format('YYYY-MM-DD') : ''}},
            {date:2021,type:2,group:1,id:props.id
            })
        console.log('编辑成功的数据上传项目信息',data)
        axios.post('/apply',{ data:JSON.stringify(data) }).then(res => {
            if (res.code === 10000){
                message.success('保存成功！')
                props.readStatus()
                props.getData()
            }
        })
    }
    // 提交失败
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        if (errorFields.length > 0) {message.warning('项目信息不完整！')}
    }
    MobileDelBr(LABELS)
    return <Form {...layout} form={form} className='innovate-form busi-form' autoComplete="off"
        name="company-info"
        onFinish={onSubmit}
        onFinishFailed={onFinishFailed}
    >
        <h3 className='company-tip'>*详尽填写信息将有助于评委更好了解项目<br/>&nbsp;Filling in the information in detail will help the judges understand the project</h3>

        {/* 所处赛道 */}
        <Form.Item
            className='double-input sector-checkbox'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_sector}}></i>}
            name="sector"
            rules={[ { required: true, message: '该项是必填项！'}]}
            initialValue={checked}
        >
            <Checkbox.Group onChange={checkboxChange} disabled={isRead}>
                {
                    options.map(item => {
                        return <Checkbox value={item.id} disabled={!checked.includes(item.id) && checked.length === 2 }>
                            {item.title}
                        </Checkbox>
                    })
                }
            </Checkbox.Group>
        </Form.Item>

        {/* 成立时间 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_founded}}></i>}
            name="founded"
        >
            {
                isRead ? <span>{data.founded}</span> 
                    : <DatePicker placeholder='选填，请选择成立时间/Founded in' format='YYYY-MM-DD' disabledDate={disabledDate} allowClear={false}/>
            }
        </Form.Item>

        {/* 企业所在地*/}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_location}}></i>}
            name="ch_location"
            rules={[{type:'string',max:50,message:'长度不能超过50个字符！'}]}
        >
            <Input placeholder='请输入所在地' readOnly={isRead ? 'readOnly' : ''}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_location"
            rules={[{type:'string',max:50,message:'长度不能超过50个字符！'},{ validator:OnlyCH }]}
        > 
            <Input placeholder="Location" readOnly={isRead ? 'readOnly' : ''}/>
        </Form.Item>

        {/* 企业官网 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_website}}></i>}
            name="website"
            rules={[{validator:websiteRule}]}
        >
            <Input placeholder='选填，请输入官网地址/Official Website' readOnly={isRead ? 'readOnly' : ''}/>
        </Form.Item>

        {/* 主要产品 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_product}}></i>}
            name="ch_product"
            rules={[{type:'string',max:100,message:'长度不能超过100个字符！'}]}
        >
            <Input placeholder='请输入主要产品' readOnly={isRead ? 'readOnly' : ''}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_product" rules={[{ validator:OnlyCH },{type:'string',max:100,message:'长度不能超过100个字符！'}]}
        >
            <Input placeholder="Main Products" readOnly={isRead ? 'readOnly' : ''}/>
        </Form.Item>

        {/* 合作伙伴 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_partner}}></i>}
            name="ch_partner"
            rules={[{required: true,  message: '该项是必填项！'}, {type:'string',max:5000,message:'长度不能超过5000个字符！'}]}
        >
            <TextArea placeholder='必填，请输入现有客户案例' readOnly={isRead ? 'readOnly' : ''} autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_partner" rules={[{ validator:OnlyCH },{type:'string',max:10000,message:'长度不能超过10000个字符！'}]}
        >
            <TextArea placeholder='(Optional) Enter partner' readOnly={isRead ? 'readOnly' : ''} autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_company_parter}}></span>

        {/* 知识产权 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_award}}></i>}
            name="ch_award"
            rules={[{required: true,  message: '该项是必填项！'}, {type:'string',max:5000,message:'长度不能超过5000个字符！'}]}
        >
            <TextArea placeholder='请输入专利、奖励及证书等' readOnly={isRead ? 'readOnly' : ''} autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_award" rules={[{ validator:OnlyCH },{type:'string',max:10000,message:'长度不能超过10000个字符！'}]}
        >
            <TextArea placeholder='(Optional) Enter patents, awards and certificates' readOnly={isRead ? 'readOnly' : ''} autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_company_award}}></span>

        {/* 发展规划 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_development}}></i>}
            name="ch_develpment"
            rules={[{required: true,  message: '该项是必填项！'}, {type:'string',max:5000,message:'长度不能超过5000个字符！'}]}
        >
            <TextArea placeholder='必填，请输入发展规划' readOnly={isRead ? 'readOnly' : ''} autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_develpment" rules={[{ validator:OnlyCH },{type:'string',max:10000,message:'长度不能超过10000个字符！'}]}
        >
            <TextArea placeholder='(Optional) Enter development plan' readOnly={isRead ? 'readOnly' : ''} autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_company_plan}}></span>

        {/* 发展需求 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_need}}></i>}
            name="ch_need"
            rules={[
                // {required: true,  message: '该项是必填项！'}, 
                {type:'string',max:5000,message:'长度不能超过5000个字符！'}
            ]}
        >
            <TextArea placeholder='选填，请输入发展需求' readOnly={isRead ? 'readOnly' : ''} autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_need" rules={[{ validator:OnlyCH },{type:'string',max:10000,message:'长度不能超过10000个字符！'}]}
        >
            <TextArea placeholder='(Optional) Enter resources needed' readOnly={isRead ? 'readOnly' : ''} autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_company_need}}></span>
        {/* 是否可编辑的状态 */}
        {
            !isRead && <Form.Item {...buttonLayout} className='button-box'>
                <Button type="primary" htmlType='submit' disabled={isRead}> 保存 </Button>
            </Form.Item>
        }
    </Form>
}
export default CompanyInfo