import React, { useState } from 'react'
import { Form, Radio, Button } from 'antd'
import TopFormBg from 'comps/TopFormBg'
import { useHistory } from 'react-router-dom' 
import LABEL from 'pages/json/labels.json'
import { layout, buttonLayout } from 'pages/config'

const BusinessGroup = props => {
    const [radioValue,setRadioValue] = useState('1') // 默认值根据后台请求的数据更改
    let history = useHistory()

    // 改变组别
    const onChangeRadio = v => {
        setRadioValue(v.target.value)
    }

    // 提交不同的类型 跳转不同的路由
    const onSubmit = () => {
        history.push(radioValue === '1' ? '/signup/business/nofund' : '/signup/business/fund')
    }

    return <>
        <TopFormBg type={2} branch={true}/>
        <section className="innovate-con-form container">
            <h1>选择组别</h1>
            <Form
                {...layout}
                className='innovate-form busi-form'
            >
                <Form.Item name="group" className='group-radio'
                    label={<i dangerouslySetInnerHTML={{__html:LABEL.busi_group_radio}}></i>}
                    initialValue={radioValue}
                >
                    <Radio.Group onChange={onChangeRadio}>
                        <Radio value="1">未融资组/Without Funding</Radio>
                        <Radio value="2">融资组/With Fundings</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item {...buttonLayout} className='button-box'>
                    <Button type="primary" onClick={onSubmit} style={{ marginRight:60}}> 下一步 </Button>
                    {/* 回退到上一页 */}
                    <Button type="primary" onClick={() => history.goBack()}> 取消 </Button>
                </Form.Item>
            </Form>
        </section>
    </>
}

export default BusinessGroup