import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Modal, Select, Form, Button, message } from "antd"
import "styles/user/authorization-role.scss"
class AuthorizationRole extends Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            selectRorle: ''
        }
    }
    componentDidMount() {
        // this.getRoleOption()
    }
  handleChange = value => {
      //   console.log(`selected ${value}`)
  };

  getRoleOption = () => {
      this.axios.post('/role/getRoleOption')
          .then(res => {
              if (res.code === 10000){
                  this.setState({
                      list: res.data
                  }, () => {
                   
                  })
              }
          })
  }

  //   授权角色
  teamMemberRoleUpdate = values => {
      //   console.log('授权角色', values, this.props.user_id, this.props.team_id)
      const {user_id, team_id} = this.props
      this.axios.post('/team/teamMemberRoleUpdate', {...values, user_id, team_id})
          .then(res => {
              if (res.code === 10000){
                  message.success('授权成功')
                  this.props.closeDialog()
              }
          })
  }
  handleCancel = () => {
      this.props.closeDialog()
  }
  render() {
      const { isShowAuthorization, roleOption } = this.props
      const { list } = this.state
      const { Option } = Select
      return (
          <Modal
              visible={isShowAuthorization}
              destroyOnClose
              footer={null}
              className="authorization-role"
              centered={true}
              closable
              onCancel={this.handleCancel}
          >
              <div className="title">授权角色</div>
              <Form
                  name="basic"
                  autoComplete="off"
                  onFinish={this.teamMemberRoleUpdate}
              >
                  <Form.Item
                      name="role_id"
                      label="授权角色"
                      rules={[
                          {
                              required: true,
                          },
                      ]}
                  >
                      <Select
                          placeholder=""
                          onChange={this.handleChange}
                      >
                          {roleOption.map(item => (
                              <Option value={item.id}>{item.role_name}</Option>
                          ))}
                      </Select>
                  </Form.Item>
                  <Form.Item>
                      <div className="btns">
                          <Button type="primary" htmlType="submit" className="btn">确认</Button>
                          <Button htmlType="button" className="btn" onClick={this.handleCancel}>取消</Button>
                      </div>
                  </Form.Item>
              </Form>
          </Modal>
      )
  }
}
export default withRouter(AuthorizationRole)
