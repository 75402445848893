
import React, { Component } from 'react'
import { Tabs, Form, Input, Button, Radio, Upload, Table, Space,Skeleton, message, ConfigProvider,  Select, Popover, Badge, Pagination } from 'antd'
import className from 'classname'
import moment from 'moment'
import { getStorage } from 'utils/storage'
import { APIBaseURL, Msg, UploadIcon, UploadFileError } from 'utils'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'
import WikiUploadDialog from 'comps/WikiUploadDialog'
import UploadDialog from 'comps/signup/UploadDialog'
import IsDelDialog from './isDelDialog'
import PayModal from 'comps/PayModal'
import 'styles/user/center.scss'
import { connect } from 'react-redux'
import { getClientWidth } from '@redux/ispc/action'
@connect(state => state, { getClientWidth })

class UserCenter extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            gender: 1,
            isChange: false,
            userInfo: {},
            isLoading: true,
            defaultCnFileList: [],
            defaultEnFileList: [],
            currentPage: 1,
            total: 0,
            gamelist: [],
            email: '', 
            codeText:'发送邮箱验证码',
            codeBtnisAble: false,
            maxTime: 60,
            tabsIndex: 0,
            isPC: window.innerWidth > 750 ? true : false,
            isWikiShow: false,
            IsDelShow: false,
            IsUploadDialogShow: false,
            pageSize: 10, 
            UserColumns: [
                {
                    title: '队伍名称/公司名称',
                    dataIndex: 'team_name',
                    width: '24%',
                    key: 'team_name',
                    render: (text, record) => (
                        <div>{record.type_id === 3 ? <span>{record.team_name}</span> : <span>{record.team_info.cn_name && `${record.team_info.cn_name + '/'}`}{record.team_info.en_name}</span>}</div>
                    ),
                },
                {
                    title: '大赛类型',
                    dataIndex: 'type_id',
                    width: '14%',
                    key: 'type_id',
                    render: (text, record) => (
                        <span>{text === 1 ? '创新大赛' : text === 2 ? '创业大赛' : '企业挑战-' + this.tarckTitle(record.tarck_type_id) + '赛道'}</span>
                    ),
                },
                {
                    title: '最近更新日期',
                    dataIndex: 'update_time',
                    key: 'update_time',
                    width: '20%',
                    render: (text, record) => (
                        <span>{moment(text * 1000).format('YYYY-MM-DD')}</span>
                    ),
                },
                {
                    title: <div style={{textAlign:'center'}}>操作</div>,
                    dataIndex: 'id',
                    key: 'id',
                    render: (text,record) => {
                        return <div className='opera'>
                            {record.type_id !== 3 && <a href={this.goToDetailHref(record)} className='checkDetail'>查看详情</a>}
                            {record.type_id === 3 && <span onClick={() => this.handleChallenge(record.id)}>查看详情</span>}
                            {record.type_id === 3 && record.is_run === 1 && <span onClick={() =>this.challageReUp(record)}>重新上传材料</span>}
                            {record.type_id === 3 && record.is_run === 1 && <span onClick={() =>this.challengeDel(record.id)}>删除</span>}
                            {record.type_id === 1 && <span onClick={() =>this.wikiClick(record)}>Wiki</span>}
                            {/* {record.type_id === 2 && <a href='/signup/challenge'>企业挑战（必报)</a>} */}
                            {/* <a href='/signup/challenge'>{record.type_id === 1 ? '企业挑战（选报)' : record.type_id === 2 ? '企业挑战（必报)' : ''}</a> */}
                            {record.type_id === 1 && <a href={`/myteam/${record.team_id}/${record.is_run}`}>我的队伍</a>}
                            {record.type_id === 1 && <a href={`/supplementalresult/${record.id}/${record.is_run}`}>补充材料</a>}
                            {/* {record.type_id === 1 && this.state.isPaySuccess ? <Badge offset={[3,0]} size='small' count={<Popover content='如需开发票，请联系info@synbiochallenges.com' trigger='hover'><img src={require('assets/images/zhuyi.png')} alt=''/></Popover>}>
                                <span>已支付</span>
                            </Badge> : record.type_id === 1 && <span onClick={() => this.goToPCpay(record.id)}>去支付</span>} */}
                        </div>
                    }
                },
            ],
            challengeDelId: null,
            upItem: null,
            challengeTitle: [],
            wikiStatus: '',
            wikiItem: {},
            isPayShow: false,
            payId: '', //报名id
            isPaySuccess: false, //是否支付成功
            version: '',
            defaultActiveKey: this.props.location.state && this.props.location.state.defaultActiveKey ? this.props.location.state.defaultActiveKey : '1'
        }
    }

    componentDidMount(){
        this.getUserInfo()
        this.getUserList()
        this.getChallenge()
        // window.addEventListener('resize', this.getPageClientWidth)
    }
    
    componentWillUnmount(){
        // window.removeEventListener('resize',this.getPageClientWidth)
    }

    // getPageClientWidth = () => {
    //     this.setState({
    //         isPC: window.innerWidth >= 750 ? true : false
    //     })
    // }
    
    //PC端支付 
    goToPCpay = id => {
        this.setState({
            isPayShow: true,
            payId: id
        })
    }

    //手机支付
    goToH5Pay = id => {
        this.setState({
            isPayShow: true,
            payId: id
        })
    }

    //赛道信息
    getChallenge= () => {
        this.axios.post('/challenge').then(res => {
            if (res.code === 10000) {
                // setChallengeList(res.data)
                // let item = res.data.filter(item => item.title === itemsId)
                // setChallengeitem(...item)
                this.setState({
                    challengeTitle: res.data
                })
            }
        })
    }

    //获取对应赛道
    tarckTitle = id => {
        let title = this.state.challengeTitle && this.state.challengeTitle.filter(item => item.id === id)
        return title[0] && title[0].title
    }
    
    //点击删除企业挑战按钮
    challengeDel = id => {
        this.setState({IsDelShow: true, challengeDelId: id})
        
    }

    //确定删除企业挑战
    challengeDelOK = () => {
        this.axios.post('/challenge/del', {date: '2021', id: this.state.challengeDelId}).then(res => {
            if (res.code === 10000){
                this.closeDialog()
                this.getUserList()
            }
        })
    }

    //重新上传
    challageReUp = upItem => {
        // console.log('重新上传', upItem)
        this.setState({
            IsUploadDialogShow: true,
            upItem 
        })   
    }

    //保存编辑信息
    onFinish = values => {
        console.log('value',values)
        let userInfo = Object.assign({}, this.state.userInfo,
            values,
            {email: values.email ? values.email : this.state.userInfo.email},
            {code: values.code ? values.code : ''},
            {gender: this.state.gender})
        // 编辑完成
        this.axios.post('/user/edit', userInfo).then(res => {
            if (res.code === 10000){
                window.location.reload()
            }
        })
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    //input值修改
    onValuesChange = (changedValues, allValues) => {
        this.setState({
            email: allValues.email,
        })
    }

    //单选切换
    onRadioChange = e => {
        this.setState({
            gender: e.target.value
        })
    }

    //修改个人信息
    changeMess = () => {
        this.setState({ isChange: true })
    }

    //获取基本信息
    getUserInfo = () => {
        this.axios.post('/user/info').then(res => {
            if (res.code === 10000){
                let userInfo = Object.assign({}, res.data)
                let defaultCnFileList = res.data.cv_cn ? [{name: res.data.cv_cn, uid: res.data.cv_cn_url, url: res.data.cv_cn_url, status:'done'}] : []
                let defaultEnFileList = res.data.cv_en ? [{name: res.data.cv_en, uid: res.data.cv_en_url, url: res.data.cv_en_url, status:'done'}] : []
                this.setState({
                    userInfo,
                    isLoading: false,
                    defaultCnFileList,
                    defaultEnFileList,
                })
                this.formRef.current && this.formRef.current.setFieldsValue({ cv_cn_url: res.data.cv_cn_url})
                this.formRef.current && this.formRef.current.setFieldsValue({ cv_cn: res.data.cv_cn})
                this.formRef.current && this.formRef.current.setFieldsValue({ cv_en_url: res.data.cv_en_url})
                this.formRef.current && this.formRef.current.setFieldsValue({ cv_en: res.data.cv_en})
            }
        })
    }

    //获取报名列表
    getUserList = () => {
        this.axios.post('/user/gamelist', { page: this.state.currentPage, size: this.state.pageSize })
            .then(res => {
                if (res.code === 10000){
                    this.setState({
                        total: res.data.total,
                        gamelist: res.data.total ? res.data.items : []
                    }, () => {
                        if (res.data.total){
                            let Arr = res.data.items.filter(item => item.type_id === 1)
                            let id = Arr[0] && Arr[0].order_id
                            // this.CheckisPaySuccess(id)
                        }
                    })
                }
            })
    }

    //查询支付结果
    CheckisPaySuccess = orderId => {
        //有订单id查看是否支付成功，否则支付失败
        orderId ? this.axios.post('/pay/query', {id: orderId} ).then(res => {
            if (res.code === 10000){
                //已经支付成功
                if (res.data === 10000){
                    this.setState({
                        isPaySuccess: true
                    })
                }
                
            }
        }) : this.setState({
            isPaySuccess: false
        })
    }

    //分页
    onPageChange = page => {
        this.setState({
            currentPage: page,
        },() => {
            this.getUserList()
        })
    }

    // 文件上传前
    beforeUpload = file => {
        const isLt2M = file.size / 1024 / 1024 < 10
        if (!isLt2M) {
            message.error('文件大小不超过10M！')
        }
        return isLt2M
    }

    //上传文件
    handleChange = (type, info) => {
        console.log('info', info)
        if (info.file.status === 'uploading') {
            if (type === 'ch'){
                this.setState({ defaultCnFileList: [...info.fileList] })
            } else {
                this.setState({ defaultEnFileList: [...info.fileList] })
            }
        }
        // 删除文件
        if (info.file.status === 'removed'){
            if (type === 'ch'){
                this.formRef.current.setFieldsValue({ cv_cn_url: ''})
                this.formRef.current.setFieldsValue({ cv_cn: ''})
                this.setState({
                    defaultCnFileList: []
                }) 
            } else {
                this.formRef.current.setFieldsValue({ cv_en_url: ''})
                this.formRef.current.setFieldsValue({ cv_en: '' })
                this.setState({
                    defaultEnFileList: []
                }) 
            }
        }

        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.url, name = info.file.response.data.old_name
            const file = {name,url,status:'done',uid:url}
            if (type === 'ch') {
                this.formRef.current.setFieldsValue({ cv_cn_url: url})
                this.formRef.current.setFieldsValue({ cv_cn: name})
                this.setState({
                    defaultCnFileList: [file]
                }) 
            } else {
                this.formRef.current.setFieldsValue({ cv_en_url: url })
                this.formRef.current.setFieldsValue({ cv_en: name })
                this.setState({
                    defaultEnFileList: [file]
                }) 
            }
        } else if (info.file.status === 'done' && info.file.response.code !== 10000) {
            UploadFileError(info.file.response)
            const file = { name:info.file.name,url:'',status:'error',uid:info.file.uid }
            if (type === 'ch') {
                this.formRef.current.setFieldsValue({ cv_cn_url: ''})
                this.formRef.current.setFieldsValue({ cv_cn: ''})
                this.setState({ defaultCnFileList: [file] }) 
            } else {
                this.formRef.current.setFieldsValue({ cv_en_url: '' })
                this.formRef.current.setFieldsValue({ cv_en: '' })
                this.setState({ defaultEnFileList: [file] }) 
            }
        }
    }

    //发送邮箱验证码
    sendCode = () => {
        const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (reg.test(this.state.email)){
            this.setState({ codeBtnisAble:true, codeText: "加载中" })
          
            this.axios.post('/email/send', {email: this.state.email}).then(res => {
                console.log('sendCode', res)
                if (!res.data){
                    Msg('warning',res.message)
                    this.setState({
                        codeBtnisAble: false, codeText: "重新获取验证码", maxTime: 60
                    })
                } else {
                    let siv = setInterval(() => {
                        this.setState( preState => ({
                            maxTime: preState.maxTime - 1,
                            codeText: `${preState.maxTime - 1}s`
                        }), () => {
                            if (this.state.maxTime === 0) {
                                clearInterval(siv)
                                this.setState({
                                    codeBtnisAble: false, codeText: "重新获取验证码", maxTime: 60
                                })
                            }
                        })
                    }, 1000)
                }
            })
        }
    }

    //h5切换
    changeTabs = index => {
        // console.log('index', index)
        this.setState({
            tabsIndex: index
        })
    }

    //h5性别select切换
    selectHandleChange = value => {
        // console.log('val', value)
        this.setState({
            gender: value
        })
    }

    goToDetailHref = data => {
        let href
        if (data.type_id === 1){ // type_id === 1创新大赛
            href = `/usercenter/innovate/${data.id}/${data.is_run}`
        } else if (data.type_id === 2){ // type_id === 2 创业大赛  
            // group_id === 1 未融资组/usercenter/business/nofund/:id group_id === 2 融资组 /usercenter/business/fund/:id 
            href = `/usercenter/business/${data.group_id === 1 ? 'no' : ''}fund/${data.id}`
        }
        return href
    }
    
    //关闭弹框
    closeDialog = () => {
        this.setState({
            isWikiShow: false,
            IsUploadDialogShow: false,
            IsDelShow: false,
            isPayShow: false
        })        
    }

    //打开wiki
    wikiClick = wikiItem => {
        this.axios.post('/wiki/info', {id: wikiItem.id}).then(res => {
            if (res.code === 10000){
                if (this.props.isPC){
                    this.setState({
                        isWikiShow: true,
                        wikiStatus: res.data.is_apply,
                        wikiItem,
                        version: res.data.version
                    })
                } else {
                    const domainUrl = process.env.API_ENV === 'test' ? 'https://twww.synbiohive.com' : 'http://www.synbiochallenges.com'
                    let url = `${domainUrl}/wiki/${res.data.version}/${wikiItem.team_info.en_name}/index.html`
                    window.location.href = url
                }
                
            }
        })
    }

    tabChange = key => {
        this.props.history.push({pathname:'/usercenter', state:{defaultActiveKey: key}})
    }

    //企业挑战查看详情
    handleChallenge = id => {
        this.axios.post('/game/company', {id}).then(res => {
            if (res.code === 10000){
                // console.log('企业挑战res', res)
                window.open(res.data.file)
            }
        })
    }
    render() {
        const { TabPane } = Tabs
        const { isChange, userInfo, isLoading, total, currentPage, gamelist, codeBtnisAble, 
            codeText, defaultCnFileList, defaultEnFileList, tabsIndex, isWikiShow, IsDelShow, 
            UserColumns, IsUploadDialogShow, upItem, pageSize, wikiStatus, wikiItem, isPayShow,
            payId, isPaySuccess, version, defaultActiveKey} =  this.state
        const { Option } = Select
        const {isPC} = this.props
        // 上传配置项
        const uploadProps = {
            accept:'.pdf,.doc,.docx, application/msword, application/pdf',
            listType:'file',
            maxCount:1,
            action: `${APIBaseURL}upload`,
            headers: { token: getStorage('token') },
            beforeUpload: this.beforeUpload,
        }

        //数据缺省页
        const customizeRenderEmpty = () => (
            <div className='nodata'>
                <img src={require('assets/images/no_data.png')} alt='no_data' className="no_data"/>
                <div className="tip">暂无报名</div>
            </div>
        )
        return (
            <>
                {
                    isPC ? <div className="mess-sign container">
                        <div className="title"><div className="bor"></div><span>个人中心/Me</span></div>
                        <Skeleton loading={isLoading} active>
                            <Tabs defaultActiveKey={`${defaultActiveKey}`} onChange={this.tabChange}>
                                <TabPane tab="个人信息/Personal Info" key="1">
                                    <div className="messcont">
                                        <div className="tel">
                                            <div className="lable">手机号/Phone: </div>
                                            <div className="phone">{userInfo.mobile}</div>
                                            <img src={require('assets/images/xiugai.png')} alt='xiugai' className="xiugai" onClick={this.changeMess}/>
                                        </div>
                                        <div className='inp'>
                                            <Form
                                                name="basic"
                                                initialValues={{
                                                    ...userInfo,other_cn: userInfo.other_cn || '',other_en: userInfo.other_en || ''
                                                }}
                                                onFinish={this.onFinish}
                                                onFinishFailed={this.onFinishFailed}
                                                validateTrigger="onBlur" 
                                                ref={this.formRef}
                                                onValuesChange={this.onValuesChange}
                                            >
                                                <Form.Item
                                                    name="name_cn"
                                                    className='name'
                                                    label="姓名/Name:"
                                                    rules={[{ required: true, message: '该项是必填项！' },{ max: 50, message: '长度不能超过50个字符！' }]}
                                                >
                                                    {
                                                        !isChange ? <div className='haveinfo'>{userInfo.name_cn ? userInfo.name_cn : '未填写'}</div> : <Input placeholder='请输入姓名'/>
                                                    }
                                                </Form.Item>

                                                <Form.Item
                                                    name="name_en"
                                                    className='name-en'
                                                    label=""
                                                    rules={[{ required: true, message: '该项是必填项！' },{ max: 50, message: '长度不能超过50个字符！' }]}
                                                >
                                                    {
                                                        !isChange ? <div className='haveinfo'>{userInfo.name_en ? userInfo.name_en : '未填写'}</div> : <Input  placeholder='Name' />
                                                    }
                                                </Form.Item>
                                                <div className='email-box'>
                                                    <div className="cont">
                                                        <Form.Item
                                                            name="email"
                                                            className='email'
                                                            label="邮箱/Email:"
                                                            rules={[{ required:true, message:'该项是必填项！'}, { type:'email', message:'格式不正确，请重新输入！' }]}
                                                        >
                                                            {
                                                                userInfo.email ? <div className='haveinfo'>{userInfo.email}</div> : !isChange ? <div className='haveinfo'>未填写</div> : <Input  placeholder='请输入邮箱/Email' />
                                                            }
                                                        </Form.Item>
                                                        {
                                                            !userInfo.email && isChange && <Button className='send-code' onClick={this.sendCode} disabled={codeBtnisAble}>{codeText}</Button>
                                                        }
                                                    </div>
                                                    {
                                                        !userInfo.email && isChange && <div className="tips">
                                                            <div>提示：如是老师或学生，建议为学校官方邮箱，.edu后缀；请谨慎填写，设置完成提交后不可修改</div>
                                                            <div>Note: It is suggested to use the institution's email (e.g. johndoe@abcuni.edu) for teachers and students; once submit, the email cannot be changed</div>
                                                        </div>
                                                    }
                                                </div>
                                                {
                                                    !userInfo.email && isChange && <Form.Item
                                                        name="code"
                                                        className='code'
                                                        label={<div><div>邮箱验证码/</div><div>Email Verification Code:</div></div>}
                                                        rules={[{ required: true, message: '该项是必填项！' }]}
                                                    >
                                                        {
                                                            isChange && <Input  placeholder='请输入邮箱验证码/Email Verification Code' />
                                                        }
                                                    </Form.Item>
                                                }
                                                <Form.Item
                                                    name="age"
                                                    className='age'
                                                    label="年龄/Age:"
                                                    rules={[{ required: true, message: '该项是必填项！' }]}
                                                >
                                                    {
                                                        !isChange ? <div className='haveinfo'>{userInfo.age ? userInfo.age : '未填写'}</div> : <Input  placeholder='请输入年龄/Age' />
                                                    }
                                                </Form.Item>
                                                <div className="sex">
                                                    <div className="lable">性别/Sex:</div>
                                                    <Radio.Group onChange={this.onRadioChange} defaultValue={userInfo.gender * 1} disabled={!isChange}>
                                                        <Radio value={1}>男</Radio>
                                                        <Radio value={2}>女</Radio>
                                                    </Radio.Group>
                                                </div>
                                                <Form.Item
                                                    name="other_cn"
                                                    className='info'
                                                    // label="其他补充信息/"
                                                    label={<div><div>其他补充信息/</div><div>Other info to add (if any)：</div></div>}
                                                    // rules={[{ required: true, message: '该项是必填项！' }]}
                                                >
                                                    {
                                                        !isChange ? <div className='haveinfo'>{userInfo.other_cn ? userInfo.other_cn : '未填写'}</div> : <Input placeholder='请输入其他补充信息' />
                                                    }
                                        
                                                </Form.Item>

                                                <Form.Item
                                                    name="other_en"
                                                    className='info-en'
                                                    // label="Other info to add (if any)："
                                                    // rules={[{ required: true, message: '该项是必填项！' }]}
                                                >
                                                    {
                                                        !isChange ? <div className='haveinfo'>{userInfo.other_en ? userInfo.other_en : '未填写'}</div> : <Input  placeholder='Other info to add (if any)' />
                                                    }
                                        
                                                </Form.Item>
                                                <div className="up-lable">中英文简历/CV in Chinese and English：</div>
                                                <div className="update">
                                                    <div className="upcont">
                                                        <div className="up-box">
                                                            <Form.Item>
                                                                <Space>
                                                                    <Form.Item name="cv_cn" > <Input hidden/> </Form.Item>
                                                                    <Form.Item name="cv_en" > <Input hidden/> </Form.Item>
                                                                    <Form.Item
                                                                        name="cv_cn_url"
                                                                        rules={[ { required: true, message: '必须上传一个文件'}]}
                                                                    >
                                                                        <Upload {...uploadProps} onChange={ this.handleChange.bind(this,'ch')} disabled={!isChange} fileList={defaultCnFileList}>
                                                                            {UploadIcon('ch')}
                                                                        </Upload>
                                                                        {/* 隐藏的file地址 */}
                                                                        <Input hidden/>
                                                                    </Form.Item>
                                                                    <Form.Item
                                                                        name="cv_en_url"
                                                                        rules={[ { required: true, message: '必须上传一个文件'}]}
                                                                    >
                                                                        <Upload {...uploadProps} onChange={ this.handleChange.bind(this,'en')} disabled={!isChange} fileList={defaultEnFileList}>
                                                                            {UploadIcon('en')}
                                                                        </Upload>
                                                                        <Input hidden/>
                                                                    </Form.Item>
                                                                </Space>
                                                            </Form.Item>
                                                        </div>
                                                        <div className="hint">
                                                            <div>提示：请上传PDF或者Word文档，大小不超过10M</div>
                                                            <div>Note: {`<`}10M, in Word Doc or PDF format</div>
                                                        </div>
                                                        <Form.Item  className='btn'>
                                                            {
                                                                isChange && <Button type="primary" htmlType="submit" className='savebtn' >保存</Button>
                                                            }
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                            </Form>
                                
                                        </div>
                                    </div>
                                    
                                </TabPane>
                                <TabPane tab="我的报名/My Projects" key="2" className='tab2cont'>
                                    <ConfigProvider  renderEmpty={customizeRenderEmpty}>
                                        <Table columns={UserColumns} dataSource={gamelist} 
                                            pagination={{pageSize: pageSize, total: total, current: currentPage, onChange: this.onPageChange}} 
                                            className={className([total === 0 && "empty-ta"])}/>
                                    </ConfigProvider>
                                </TabPane>
                            </Tabs>
                        </Skeleton>
                    </div> : <div className="mess-sign-h5">
                        <Skeleton loading={isLoading} active>
                            <div className="mess-sign-tabs">
                                {
                                    ['个人信息/Personal Info', '我的报名/My Projects'].map((item, index) => {
                                        return <div onClick={() => this.changeTabs(index)} className={className([{'tabactive' : index === tabsIndex}])} key={index}>{item}</div>
                                    })
                                }
                            </div>
                            {
                                tabsIndex === 0 ? <div className="h5-mess">
                                    <div className="suggest">* 建议电脑端填写以下信息</div>
                                    <div className="excon tel ">
                                        <div className="lable">手机号/Phone</div>
                                        <div className="phone">{userInfo.mobile}</div>
                                    </div>
                                    <Form
                                        name="basic"
                                        initialValues={{
                                            ...userInfo,other_cn: userInfo.other_cn || '',other_en: userInfo.other_en || ''
                                        }}
                                        onFinish={this.onFinish}
                                        onFinishFailed={this.onFinishFailed}
                                        validateTrigger="onBlur" 
                                        ref={this.formRef}
                                        onValuesChange={this.onValuesChange}
                                    // {...layout}
                                    >
                                        <div className='excon namebox'>
                                            <div className="lable">姓名/Name</div>
                                            <Form.Item
                                                name="name_cn"
                                                className='name'
                                                label="姓名"
                                                rules={[{ required: true, message: '该项是必填项！' },{ max: 50, message: '长度不能超过50个字符！' }]}
                                            >
                                                {/* {
                                                !isChange ? <div className='h5-changemess'>{userInfo.name_cn ? userInfo.name_cn : '未填写'}<img src={require('assets/images/h5-xiugai.png')} alt='xiugai' className="h5-xiugai" onClick={this.changeMess}/></div> : <Input placeholder='请输入姓名'/>
                                            } */}
                                                <Input placeholder='请输入姓名'/>
                                            </Form.Item>

                                            <Form.Item
                                                name="name_en"
                                                className='name-en'
                                                label='Name'
                                                rules={[{ required: true, message: '该项是必填项！' },{ max: 50, message: '长度不能超过50个字符！' }]}
                                            >
                                                {/* {
                                                !isChange ? <div className='h5-changemess'>{userInfo.name_en ? userInfo.name_en : '未填写'}<img src={require('assets/images/h5-xiugai.png')} alt='xiugai' className="h5-xiugai" onClick={this.changeMess}/></div> : <Input  placeholder='Name' />
                                            } */}
                                                <Input  placeholder='Name' />
                                            </Form.Item>
                                        </div>
                                        <div className='excon email-box'>
                                            <div className="cont">
                                                <Form.Item
                                                    name="email"
                                                    className='email'
                                                    label="邮箱/Email"
                                                    rules={[{ required:true, message:'该项是必填项！'}, { type:'email', message:'格式不正确，请重新输入！' }]}
                                                >
                                                    {
                                                        userInfo.email ? <div className='h5-changemess'>{userInfo.email}</div> : <Input  placeholder='请输入邮箱/Email' />
                                                    }
                                                    {/* <Input  placeholder='请输入邮箱/Email' /> */}
                                                </Form.Item>
                                                <div className="btnbox">
                                                    {
                                                        !userInfo.email  && <Button className='send-code' onClick={this.sendCode} disabled={codeBtnisAble} type='primary'>{codeText}</Button>
                                                    }
                                                </div>
                                            </div>
                                        
                                            {
                                                !userInfo.email  && <Form.Item
                                                    name="code"
                                                    className='code'
                                                    label='邮箱码/Email Verification Code'
                                                    rules={[{ required: true, message: '该项是必填项！' }]}
                                                >
                                                    <Input  placeholder='请输入邮箱验证码/Email Verification Code' />
                                                </Form.Item>
                                            }
                                        </div>
                                        {
                                            !userInfo.email && <div className="tips">
                                                <div>提示：如是老师或学生，建议为学校官方邮箱，.edu后缀；请谨慎填写，设置完成提交后不可修改</div>
                                                <div>Note: It is suggested to use the institution's email (e.g. johndoe@abcuni.edu) for teachers and students; once submit, the email cannot be changed</div>
                                            </div>
                                        }
                                        <Form.Item
                                            name="age"
                                            className='excon age'
                                            label="年龄/Age"
                                            rules={[{ required: true, message: '该项是必填项！' }]}
                                        >
                                            {/* {
                                            !isChange ? <div className='h5-changemess'>{userInfo.age ? userInfo.age : '未填写'}<img src={require('assets/images/h5-xiugai.png')} alt='xiugai' className="h5-xiugai" onClick={this.changeMess}/></div> : <Input  placeholder='请输入年龄/Age' />
                                        } */}
                                            <Input  placeholder='请输入年龄/Age' />
                                        </Form.Item>
                                        <Form.Item label="性别/Sex" className='excon sex'>
                                            {/* <Select defaultValue={userInfo.gender * 1}  onChange={this.selectHandleChange}>
                                                <Option value={1}>男</Option>
                                                <Option value={2}>女</Option>
                                            </Select> */}
                                            <Radio.Group onChange={this.onRadioChange} defaultValue={userInfo.gender * 1}>
                                                <Radio value={1}>男</Radio>
                                                <Radio value={2}>女</Radio>
                                            </Radio.Group>
                                        </Form.Item>
                                        <div className='excon otherinfo'>
                                            <div className="lable">其他补充信息/Other info to add (if any)</div>
                                            <Form.Item
                                                name="other_cn"
                                                className='info'
                                                label="其他补充信息"
                                                // rules={[{ required: true, message: '该项是必填项！' }]}
                                            >
                                                {/* {
                                                !isChange ? <div className='haveinfo'>{userInfo.other_cn ? userInfo.other_cn : '未填写'}</div> : <Input.TextArea placeholder='请输入其他补充信息' />
                                            } */}
                                                <Input.TextArea placeholder='请输入其他补充信息' />
                                            </Form.Item>

                                            <Form.Item
                                                name="other_en"
                                                className='info-en'
                                                label="Other info to add (if any)"
                                                // rules={[{ required: true, message: '该项是必填项！' }]}
                                            >
                                                {/* {
                                                !isChange ? <div className='haveinfo'>{userInfo.other_en ? userInfo.other_en : '未填写'}</div> : <Input.TextArea  placeholder='Other info to add (if any)' />
                                            } */}
                                                <Input.TextArea  placeholder='Other info to add (if any)' />
                                            </Form.Item>
                                        </div>
                                    
                                    
                                        <div className="update">
                                            <div className="upcont">
                                                <div className="excon up-box">
                                                    <div className="lable">中英文简历/CV in Chinese and English</div>
                                                    <Form.Item>
                                                        <Space>
                                                            <Form.Item name="cv_cn" > <Input hidden/> </Form.Item>
                                                            <Form.Item name="cv_en" > <Input hidden/> </Form.Item>
                                                            <Form.Item
                                                                name="cv_cn_url"
                                                                rules={[ { required: true, message: '必须上传一个文件'}]}
                                                            >
                                                                <Upload {...uploadProps} onChange={ this.handleChange.bind(this,'ch')} fileList={defaultCnFileList}>
                                                                    {UploadIcon('ch')}
                                                                </Upload>
                                                                {/* 隐藏的file地址 */}
                                                                <Input hidden/>
                                                            </Form.Item>
                                                            <Form.Item
                                                                name="cv_en_url"
                                                                rules={[ { required: true, message: '必须上传一个文件'}]}
                                                            >
                                                                <Upload {...uploadProps} onChange={ this.handleChange.bind(this,'en')} fileList={defaultEnFileList}>
                                                                    {UploadIcon('en')}
                                                                </Upload>
                                                                <Input hidden/>
                                                            </Form.Item>
                                                        </Space>
                                                    </Form.Item>
                                                </div>
                                                <div className="tips">
                                                    <div>提示：请上传PDF或者Word文档，大小不超过10M</div>
                                                    <div>Note: {`<`}10M, in Word Doc or PDF format</div>
                                                </div>
                                                <Form.Item  className='btn'>
                                                    {/* {
                                                    isChange && <Button type="primary" htmlType="submit" className='savebtn' >保存</Button>
                                                } */}
                                                    <Button type="primary" htmlType="submit" className='savebtn' >保存</Button>
                                                </Form.Item>
                                            </div>
                                        </div>
                                    </Form>
                                </div> : <div className='h5-sign'>
                                    {
                                        total > 0 ? <div>
                                            {
                                                gamelist.map((item, index) => {
                                                    return <div className="sign-item" key={index}>
                                                        <div className="title">队伍名称/公司名称：{item.type_id === 3 ? <span>{item.team_name}</span> : <span>{item.team_info.cn_name && `${item.team_info.cn_name + '/'}`}{item.team_info.en_name}</span>}</div>
                                                        <div className="type">大赛类型：{item.type_id === 1 ? '创新大赛' : item.type_id === 2 ? '创业大赛' : '企业挑战-' + this.tarckTitle(item.tarck_type_id) + '赛道'}</div>
                                                        <div className="date">最近更新日期：{moment(item.update_time * 1000).format('YYYY-MM-DD')}</div>
                                                        <div className='btns'>
                                                            {
                                                                item.type_id !== 3 && <Button className='btn' onClick={() => window.location.href = this.goToDetailHref(item)} >查看详情</Button>
                                                            }
                                                            {item.type_id === 3 && item.is_run === 1 && <Button className='btn' onClick={() => this.challageReUp(item)}>重新上传材料</Button>}
                                                            {item.type_id === 3 && item.is_run === 1 && <Button className='btn'onClick={() =>this.challengeDel(item.id)}>删除</Button>}
                                                            {item.type_id === 1 && <Button className='btn' onClick={() =>this.wikiClick(item)}>Wiki</Button>}
                                                            {/* {item.type_id === 2 && <Button className='btn' onClick={() => window.location.href = '/signup/challenge'}>企业挑战（必报）</Button>} */}
                                                            {/* {item.type_id === 1 ? <Button className='btn' onClick={() => window.location.href = '/signup/challenge'}>我的队伍</Button> : null}
                                                            {item.type_id === 1 ? <Button className='btn' onClick={() => window.location.href = '/signup/challenge'}>补充材料</Button> : null} */}
                                                            {/* {item.type_id === 1 && isPaySuccess ? <Popover content='如需开发票，请联系info@synbiochallenges.com' trigger='click'>
                                                                <Button className='btn'>已支付</Button>
                                                            </Popover> : item.type_id === 1 && <Button className='btn' onClick={() =>this.goToH5Pay(item.id)}>去支付</Button>} */}
                                                        </div>
                                                    </div>
                                                })
                                            }
                                            <Pagination onChange={this.onPageChange} total={total} pageSize={pageSize} current={currentPage} size="small"/>
                                        </div> : <div className='nodata'>
                                            <img src={require('assets/images/no_data.png')} alt='no_data' className="no_data"/>
                                            <div className="tip">暂无报名</div>
                                        </div>
                                    
                                    }
                                    {/* <div className="sign-item">
                                        <div className="title">队伍名称/公司名称：MiaoMiaoTeam</div>
                                        <div className="type">大赛类型：创新大赛</div>
                                        <div className="date">最近更新日期：2021-03-01</div>
                                        <div className='btns'>
                                            <Button  className='btn' onClick={() => window.location.href = this.goToDetailHref({type_id: 1})}>查看详情</Button>
                                        </div>
                                    </div> */}
                                </div>
                            }
                        
                        </Skeleton>
                    </div>
                }
                {gamelist && isWikiShow && <WikiUploadDialog isWikiShow={isWikiShow} closeDialog={this.closeDialog} wikiStatus={wikiStatus} wikiItem={wikiItem} version={version}></WikiUploadDialog>}
                {IsUploadDialogShow && <UploadDialog isShow={IsUploadDialogShow} closeDialog={this.closeDialog} track_id={upItem && upItem.track_id} id={upItem && upItem.id}></UploadDialog>}
                {IsDelShow && <IsDelDialog IsDelShow={IsDelShow}  closeDialog={this.closeDialog} challengeDelOK={this.challengeDelOK}></IsDelDialog>}
                {isPayShow && <PayModal isPayShow={isPayShow} closeDialog={this.closeDialog} payId={payId} isPC={isPC}></PayModal>}
            </>
        )
    }
}
export default UserCenter

