import React, { useState } from 'react'
import { Drawer, Button } from 'antd'
import 'styles/comps/navh5.scss'
import { useHistory } from "react-router-dom"
import { useEffect } from 'react'
import Classname from 'classname'
import axios from 'libs/axios'
import { getStorage, clearStorage } from 'utils/storage'
const Navh5 = () => {
    let history = useHistory()
    const [visible, setVisible] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [childIndex, setChildIndex] = useState(null)
    const showDrawer = () => {
        setVisible(true)
    }
    const onClose = () => {
        setVisible(false)
    }

    const [navList, setNavList] = useState([
        { title: '首页', id: 1, icon: require('assets/images/shouye.png'), to: '/home', items: [] },
        {
            title: '大赛报名', id: 2, icon: require('assets/images/dasaibaoming.png'), to: '/signup', items: [
                { title: '创新大赛', id: 1, icon: '', to: '/signup/innovate', items: [] },
                { title: '创业大赛', id: 2, icon: '', to: '/signup/business', items: [] },
                { title: '企业挑战', id: 3, icon: '', to: '/signup/challenge', items: [] }
            ]
        },
        { title: '官方通知', id: 3, icon: require('assets/images/gonggao.png'), to: '/notice', items: [] },
        { title: '评委', id: 4, icon: require('assets/images/boshimao.png'), to: '/jury', items: [] },
        { title: '媒体资讯', id: 5, icon: require('assets/images/meitibaodao.png'), to: '/news', items: [] },
        { title: '相关视频', id: 6, icon: require('assets/images/shipinbofangyingpian.png'), to: '/video', items: [] },
        { title: '登录', id: 7, icon: require('assets/images/denglu.png'), to: '/login', items: [] },
        { title: '注册', id: 8, icon: require('assets/images/zhuce.png'), to: '/register', items: [] }
    ])

    const [navList1, setNavList1] = useState([
        { title: '首页', id: 1, icon: require('assets/images/shouye.png'), to: '/home', items: [] },
        {
            title: '大赛报名', id: 2, icon: require('assets/images/dasaibaoming.png'), to: '/signup', items: [
                { title: '创新大赛', id: 1, icon: '', to: '/signup/innovate', items: [] },
                { title: '创业大赛', id: 2, icon: '', to: '/signup/business', items: [] },
                { title: '企业挑战', id: 3, icon: '', to: '/signup/challenge', items: [] }
            ]
        },
        { title: '官方通知', id: 3, icon: require('assets/images/gonggao.png'), to: '/notice', items: [] },
        { title: '评委', id: 4, icon: require('assets/images/boshimao.png'), to: '/jury', items: [] },
        { title: '媒体资讯', id: 5, icon: require('assets/images/meitibaodao.png'), to: '/news', items: [] },
        { title: '相关视频', id: 6, icon: require('assets/images/shipinbofangyingpian.png'), to: '/video', items: [] },
        { title: '个人中心', id: 7, icon: require('assets/images/gerenzhongxin2.png'), to: '/usercenter', items: [] },
        { title: '退出登录', id: 8, icon: require('assets/images/tuichudenglu.png'), to: '/register', items: [] }
    ])

    const loginout = () => {
        axios.post('/logout').then(res => {
            if (res.code === 10000) {
                clearStorage()
                window.location.href = '/home'
            }
        })
    }

    useEffect(() => {
        checkPath()
    }, [history.location])

    const checkPath = () => {
        if (!getStorage('token')) {
            const findResult = navList.find(val => history.location.pathname.includes(val.to))
            if (findResult) {
                const childResult = findResult.items && findResult.items.find(val => history.location.pathname.includes(val.to))
                if (childResult === undefined) {
                    setCurrentIndex(findResult.id - 1)
                    setChildIndex(null)
                } else {
                    setCurrentIndex(findResult.id - 1)
                    setChildIndex(childResult.id - 1)
                }
            } else if (history.location.pathname === '/' || history.location.pathname === '/home') {
                setCurrentIndex(0)
                setChildIndex(null)
            } else {
                setCurrentIndex(null)
                setChildIndex(null)
            }
        } else {
            const findResult = navList1.find(val => history.location.pathname.includes(val.to))
            if (findResult) {
                const childResult = findResult.items && findResult.items.find(val => history.location.pathname.includes(val.to))
                if (childResult === undefined) {
                    setCurrentIndex(findResult.id - 1)
                    setChildIndex(null)
                } else {
                    setCurrentIndex(findResult.id - 1)
                    setChildIndex(childResult.id - 1)
                }
            } else if (history.location.pathname === '/' || history.location.pathname === '/home') {
                setCurrentIndex(0)
                setChildIndex(null)
            } else {
                setCurrentIndex(null)
                setChildIndex(null)
            }
        }

    }

    const historyTo = href => {
        // history.push(href)
        window.location.href = href
        checkPath()
        onClose()
    }
    return <>
        <div className='h5-title'>
            <img src={require('assets/images/ic_cela.png')} onClick={showDrawer} alt=''/>
            {
                history.location.pathname.includes('/business') || history.location.pathname.includes('/challenge') ||  history.location.pathname === '/reading' ? 
                    <img src={require('assets/images/logoh5.svg')}  alt=''/>  :
                    history.location.pathname.includes('/innovate') ? 
                        <img src={require('assets/images/logoh52.svg')}  alt='' className='logoh52'/> :
                        <img src={require('assets/images/logoh51.svg')}  alt='' className='logoh52'/>
                        
            }
            
        </div>
        <Drawer
            title={<div onClick={onClose}><img src={require('assets/images/ic_cela.png')} style={{ marginRight: 20 }}  alt=''/>
                {history.location.pathname.includes('/business') || history.location.pathname.includes('/challenge') || history.location.pathname === '/reading'? 
                    <img src={require('assets/images/logoh5.svg')} className='drawer-h5-logo'  alt=''/> : 
                    history.location.pathname.includes('/innovate') ?  
                        <img src={require('assets/images/logoh52.svg')}  alt='' className='logoh52'/> :
                        <img src={require('assets/images/logoh51.svg')}  alt='' className='logoh52'/>
                        
                }
            </div>}
            placement="left"
            closable={false}
            onClose={onClose}
            visible={visible}
        >
            <ul className='nav-h5'>
                {
                    !getStorage('token') ?
                        <>
                            {
                                navList.map((item, index) => {
                                    return <>
                                        {
                                            item.items.length === 0 ?
                                                <>
                                                    {
                                                        index === 5 ?
                                                            <>
                                                                <li key={index} onClick={() => { historyTo(item.to) }} className={Classname([{ 'active': index === currentIndex }])}><img src={item.icon}  alt=''/>
                                                                    {item.title}
                                                                </li>
                                                                <div className="hr"></div>
                                                            </>
                                                            :
                                                            <li key={index} onClick={() => { historyTo(item.to) }} className={Classname([{ 'active': index === currentIndex }])}><img src={item.icon}  alt=''/>
                                                                {item.title}
                                                            </li>
                                                    }
                                                </>

                                                :
                                                <>
                                                    <li key={index}><img src={item.icon}  alt=''/>{item.title}</li>
                                                    <ul className='nav-h5-list'>{item.items.map((ite, ind) => { return <li key={ind} onClick={() => { historyTo(ite.to) }} className={Classname([{ 'cavtive': ind === childIndex }])}>{ite.title}</li> })}
                                                    </ul>
                                                </>
                                        }
                                    </>
                                })
                            }
                        </>
                        :
                        <>
                            {
                                navList1.map((item, index) => {
                                    return <>
                                        {
                                            item.items.length === 0 ?
                                                <>
                                                    {
                                                        index === 5 ?
                                                            <>
                                                                <li key={index} onClick={() => { historyTo(item.to) }} className={Classname([{ 'active': index === currentIndex }])}><img src={item.icon}  alt=''/>
                                                                    {item.title}
                                                                </li>
                                                                <div className="hr"></div>
                                                            </>
                                                            :
                                                            <>
                                                                {
                                                                    index === 7 ?
                                                                        <li key={index} onClick={() => { loginout() }} className={Classname([{ 'active': index === currentIndex }])}><img src={item.icon}  alt=''/>
                                                                            {item.title}
                                                                        </li>
                                                                        :
                                                                        <li key={index} onClick={() => { historyTo(item.to) }} className={Classname([{ 'active': index === currentIndex }])}><img src={item.icon}  alt=''/>
                                                                            {item.title}
                                                                        </li>
                                                                }
                                                            </>

                                                    }
                                                </>

                                                :
                                                <>
                                                    <li key={index}><img src={item.icon}  alt=''/>{item.title}</li>
                                                    <ul className='nav-h5-list'>{item.items.map((ite, ind) => { return <li key={ind} onClick={() => { historyTo(ite.to) }} className={Classname([{ 'cavtive': ind === childIndex }])}>{ite.title}</li> })}
                                                    </ul>
                                                </>
                                        }
                                    </>
                                })
                            }
                        </>
                }
            </ul>
        </Drawer>
    </>
}

export default Navh5