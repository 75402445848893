
import React, { Component } from 'react'
import className from 'classname'
import { getStorage, clearStorage } from 'utils/storage'
import UploadDialog from 'comps/signup/UploadDialog'
import ChallengeTopicsItemDialog2 from './ChallengeTopicsItemDialog2'
import { Msg } from 'utils'
import { connect } from 'react-redux'
import { getClientWidth } from '@redux/ispc/action'
@connect(state => state, { getClientWidth })

class ChallengeTopicsItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isZhankai: false,
            isShow: false, //信息完善弹框显隐
            isUserCheck: true,
            upIsShow: false, //上传弹框显隐
            upItem: []
        }
    }

    componentDidMount(){
        // this.setState({
        //     isZhankai: this.props.index === 1 ? true : false
        // })
    }
    
    componentDidUpdate(preProps, preState){
        if (preProps.topicsData !== this.props.topicsData) {
            this.setState({
                isZhankai: false
            })
        }

    }
    onClickZhankai = () => {
        this.setState({
            isZhankai: !this.state.isZhankai
        })
    }

    toSignUp = () => {
        //已经登录查看是否信息完善---->信息完善---->是否报名过
        getStorage('token') ? this.checkUser() : window.location.href = '/login'
    }

    //是否信息完善
    checkUser = () => {
        this.axios.post('/user/check').then(res => {
            // console.log('是否信息完善', res)
            if (res.code === 10000){
                if (res.data){
                    this.getChallengeInfo()
                } else {
                    // window.location.href = '/usercenter'
                    this.setState({
                        isShow: true, 
                        isUserCheck: false
                    })
                }
            }
        })
    }

    //是否报名过
    checkSignUp = () => {
        this.axios.post('/challenge/check', {date: 2021, new_date: 2021, company_date: 2021}).then(res => {
            // console.log('报名res', res)
            if (res.code === 10000){
                this.getChallengeInfo()
            } else if (res.code === 20102){
                Msg('warning','参加企业挑战赛，需要报名创新或创业大赛，您还没有过报名')
            } else {
                Msg('warning',res.message)
            }
        })
    }


    //获取报名信息
    getChallengeInfo = () => {
        this.setState({
            upIsShow: true
        })
        var date = new Date()
        var year = date.getFullYear()
        this.axios.post('/challenge/info', { date: '2021' }).then(res => {
            // console.log(res)
            if (res.code === 10000){
                let upItem = res.data.filter(item => item.track_id === this.props.topicsData.id)
                this.setState({
                    upItem
                })
                console.log('upItem', upItem)
            }
        })
    }

    //关闭弹框
    closeDialog = () => {
        this.setState({
            isShow: false, 
            upIsShow: false
        })         
    }

    render() {
        const {topicsData, index, isPC, status} = this.props
        const {isZhankai, isShow, upIsShow, upItem} = this.state
        return (
            <div className="topics-item" key={topicsData.id}>
                <div className="title">
                    <span>赛题{index}</span>
                    <img src={require(`assets/images/${isZhankai ? 'zhankai' : 'shouqi'}.png`)} alt="zhankai" onClick={this.onClickZhankai}/>
                </div>
                <div className={className(["content", {'content-active': isZhankai}])}>
                    <div className="content-item">
                        <div className="titles">出题方公司：</div>
                        <div className='cont'>{topicsData.supplier}</div>
                    </div>
                    <div className="content-item">
                        <div className="titles">赛题名称：</div>
                        <div className='cont'>{topicsData.title}</div>
                    </div>
                    <div className="content-item">
                        <div className="titles">提交要求：</div>
                        <div className='cont' dangerouslySetInnerHTML={{__html: topicsData.question}}></div>
                    </div>
                    {
                        status === 4 ? <div className="sign-btn"  onClick={this.toSignUp}>报名</div> : <div className="sign-btn-no" >报名未开始</div>
                    }
                </div>
                <UploadDialog isShow={upIsShow} isChallSignup={true} closeDialog={this.closeDialog} track_id={topicsData.id} id={upItem[0] ? upItem[0].id : ''}></UploadDialog>
                <ChallengeTopicsItemDialog2 isShow={isShow} closeDialog={this.closeDialog} isPC={isPC}></ChallengeTopicsItemDialog2>
            </div>
        )
    }
}
export default ChallengeTopicsItem

