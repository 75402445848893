import React, { useState, useEffect } from 'react'
import { Form, Button, Input, Upload, message, DatePicker, Checkbox,Space } from 'antd'
import LABELS from 'pages/json/labels.json'
import TIPS from 'pages/json/tips.json'
import { getStorage } from 'utils/storage'
import { APIBaseURL, OnlyCH, UploadIcon, websiteRule,UploadFileError,MobileDelBr } from 'utils'
import axios from 'libs/axios'
import moment from 'moment'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'

import 'styles/form/fundCompanyInfo.scss'

const { TextArea } = Input

const CompanyInfo = props => {
    const [form] = Form.useForm()
    const [ checked, setChecked ] = useState([])
    const [ options, setOptions ] = useState([])
    const [ fileLists, setFileLists ] = useState([])
    const [ fileEnLists, setFileEnLists ] = useState([])

    const beforeUpload = file => {
        const isLt2M = file.size / 1024 / 1024 < 30
        if (!isLt2M) {
            message.error('文件大小不超过30M！')
        }
        return isLt2M
    }
    // 文件上传过程中
    const handleChange = (type,info) => {
        // 上传中
        if (info.file.status === 'uploading') {
            if (type === 'ch'){
                setFileLists([...info.fileList])
            } else {
                setFileEnLists([...info.fileList])
            }
        }
        // 删除文件
        if (info.file.status === 'removed'){
            if (type === 'ch'){
                setFileLists([])
                form.setFieldsValue({ ch_plan: ''})
                form.setFieldsValue({ ch_plan_name: ''})
            } else {
                setFileEnLists([])
                form.setFieldsValue({ en_plan: ''})
                form.setFieldsValue({ en_plan_name: ''})
            }
        }
        // 上传成功
        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.url,name = info.file.response.data.old_name
            const file = {name,url,status:'done',uid:url}
            if (type === 'ch'){
                setFileLists([file])
                form.setFieldsValue({ ch_plan: url})
                form.setFieldsValue({ ch_plan_name: name})
            } else {
                setFileEnLists([file])
                form.setFieldsValue({ en_plan: url})
                form.setFieldsValue({ en_plan_name: name})
            }
        } else if (info.file.status === 'done' && info.file.response.code !== 10000) {
            UploadFileError(info.file.response)
            const file = { name:info.file.name,url:'',status:'error',uid:info.file.uid }
            if (type === 'ch'){
                setFileLists([file])
                form.setFieldsValue({ ch_plan: ''})
                form.setFieldsValue({ ch_plan_name: ''})
            } else {
                setFileEnLists([file])
                form.setFieldsValue({ en_plan: ''})
                form.setFieldsValue({ en_plan_name: ''})
            }
        }
    }
    const checkPhone = (rule, value) => {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (reg.test(value)) {
            return Promise.resolve()
        }
        if (!value || value.length === 0) {
            return Promise.reject('该项是必填项！')
        }
        return Promise.reject('格式不正确，请重新输入！')
    }
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        if (errorFields.length > 0) { message.warning('公司信息不完整！') }
    }
    // 上传配置项
    const uploadProps = {
        accept: '.pdf,.ppt,.pptx, application/pdf',
        listType: 'file',
        maxCount: 1,
        action: `${APIBaseURL}/upload`,
        headers: { token: getStorage('token') },
        beforeUpload,
    }
    // 限制checkbox
    const checkboxChange = v => {
        setChecked(v)
    }
    const disabledDate = current => {
        return current && current > moment().endOf('day')
    }
    // 请求多个赛道
    useEffect(()=>{
        axios.post('/game',{type:2}).then(res=>{
            if (res.code === 10000){
                setOptions(res.data.items)
            }
        })
    },[])
    MobileDelBr(LABELS)
    return <Form {...layout}
        className='innovate-form busi-form'
        name="company-info"
        onFinish={props.onSubmit}
        form={form}
        onFinishFailed={onFinishFailed}
    >
        {/* 企业名称 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.nofund_label_company_name }}></i>}
            name="ch_name"
            rules={[{ required: true, message: '该项是必填项' }, { max: 200, message: '长度不能超过200个字符！' }]}
        >
            <Input placeholder='请输入企业全称' />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_name"
            rules={[{ required: true, message: '该项是必填项' }, { max: 200, message: '长度不能超过200个字符！' }, { validator: OnlyCH}]}
        >
            <Input placeholder="Company Name" />
        </Form.Item>

        {/* 所处赛道 */}
        <Form.Item
            className='double-input sector-checkbox'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.fund_label_company_sector}}></i>}
            name="sector"
            rules={[{ required: true, message: '该项是必填项！' }]}
        >
            <Checkbox.Group onChange={checkboxChange} >
                {
                    // checked.includes(item.id) false && checked.length === 2
                    options.map(item => {
                        return <Checkbox value={item.id} disabled={!checked.includes(item.id) && checked.length === 2 }>
                            {item.title}
                        </Checkbox>
                    })
                }
            </Checkbox.Group>
        </Form.Item>

        {/* 成立时间 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.nofund_label_company_founded }}></i>}
            name="founded"
            rules={[{ required: true, message: '该项是必填项！' }]}
        >
            <DatePicker disabledDate={disabledDate} placeholder='请选择成立时间/Founded in' />
        </Form.Item>

        {/* 企业所在地*/}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_location }}></i>}
            name="ch_location"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }]}
        >
            <Input placeholder='请输入公司所在地' />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_location"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }, { validator: OnlyCH}]}
        >
            <Input placeholder="Company Location" />
        </Form.Item>

        {/* 联系人姓名*/}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_lable_contact_name }}></i>}
            name="ch_contact_name"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }]}
        >
            <Input placeholder='请输入联系人姓名' />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_contact_name"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }, { validator: OnlyCH}]}
        >
            <Input placeholder="Primary Contact Name" />
        </Form.Item>

        {/* 联系人电话 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_phone }}></i>}
            name="ch_phone"
            rules={[{ validator: checkPhone }]}
        >
            <Input placeholder='请输入联系人电话/Primary Contact Phone Number' />
        </Form.Item>

        {/* 联系人邮箱 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_email }}></i>}
            name="ch_email"
            rules={[{ required:true, message:'该项是必填项！'},{ type: 'email',message:'格式不正确，请重新输入！'}]}
        >
            <Input placeholder='请输入联系人邮箱/Primary Contact Email' />
        </Form.Item>

        {/* 企业官网 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_website }}></i>}
            name="website"
            rules={[{ validator: websiteRule }]}
        >
            <Input placeholder='选填，请输入官网地址/Official Website' />
        </Form.Item>

        {/* 公司概况 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_intro }}></i>}
            name="ch_info"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 3000, message: '长度不能超过3000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入公司概况，3000字以内' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_info"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 6000, message: '长度不能超过6000个字符！' }, 
                { validator: OnlyCH}]}
        >
            <TextArea placeholder='(Optional) Enter company introduction，< 6000 characters' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>

        {/* 主营业务 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_converage }}></i>}
            name="ch_converage"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入主营业务' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_converage"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 10000, message: '长度不能超过10000个字符！' }, 
                { validator: OnlyCH}]}
        >
            <TextArea placeholder='(Optional) Enter main business' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_company_converage }}></span>

        {/* 融资履历 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_history }}></i>}
            name="ch_history"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入融资履历' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_history"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 10000, message: '长度不能超过10000个字符！' }, 
                { validator: OnlyCH}]}
        >
            <TextArea placeholder='(Optional) Enter funding history' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_company_resume }}></span>

        {/* 里程碑 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_mile }}></i>}
            name="ch_mile"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入商业里程碑' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_mile"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 10000, message: '长度不能超过10000个字符！' }, 
                { validator: OnlyCH}]}
        >
            <TextArea placeholder='(Optional) Enter milestone' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_company_mile }}></span>

        {/* 合作伙伴 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_partner }}></i>}
            name="ch_partner"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入重要合作伙伴' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_partner"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 10000, message: '长度不能超过10000个字符！' }, 
                { validator: OnlyCH}]}
        >
            <TextArea placeholder='(Optional) Enter partner' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_company_partner }}></span>

        {/* 知识产权 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_award }}></i>}
            name="ch_award"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入专利、奖励及证书等' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_award"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 10000, message: '长度不能超过10000个字符！' },
                { validator: OnlyCH}]}
        >
            <TextArea placeholder='(Optional) Enter patents, awards and certificates' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_company_award }}></span>

        {/* 发展规划 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_company_development }}></i>}
            name="ch_develpment"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入发展规划' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_develpment"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 10000, message: '长度不能超过10000个字符！' }, 
                { validator: OnlyCH}
            ]}
        >
            <TextArea placeholder='(Optional) Enter development plan' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_company_develpment }}></span>

        {/* 发展需求 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.nofund_label_company_need }}></i>}
            name="ch_need"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 5000, message: '长度不能超过5000个字符！' }
            ]}
        >
            <TextArea placeholder='选填，请输入发展需求' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_need"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 10000, message: '长度不能超过10000个字符！' }, 
                { validator: OnlyCH}
            ]}
        >
            <TextArea placeholder='(Optional) Enter resources needed' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_company_need }}></span>

        {/* 公司商业计划书/Business Plan: */}
        <h2 className='file-h2'>公司商业计划书/Business Plan:</h2>
        <Form.Item {...noLabelLayout}>
            <Space align="start">
                <Form.Item name="ch_plan_name" > <Input hidden/> </Form.Item>
                <Form.Item name="en_plan_name" > <Input hidden/> </Form.Item>
                <Form.Item
                    name="ch_plan"
                    rules={[{ required: true, message: '必须上传一个文件' }]}
                >
                    <Upload {...uploadProps} fileList={fileLists} onChange={handleChange.bind(this, 'ch')}>
                        {UploadIcon('ch', '必选')}
                    </Upload>
                    {/* 隐藏的file地址 */}
                    <Input hidden />
                </Form.Item>
                
                <Form.Item
                    name="en_plan"
                    // rules={[{ required: true, message: '必须上传一个文件' }]}
                >
                    <Upload {...uploadProps} fileList={fileEnLists} onChange={handleChange.bind(this, 'en')}>
                        {UploadIcon('en', 'Optional')}
                    </Upload>
                    <Input hidden />
                </Form.Item>
            </Space>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_company_plan }}></span>
        <Form.Item {...buttonLayout} className='button-box'>
            <Button type="primary" htmlType='submit'> 下一步 </Button>
        </Form.Item>
    </Form>
}
export default CompanyInfo