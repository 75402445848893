import React, { useState } from 'react'
import { message, Tabs } from 'antd'
import FundTeam from './component/FundTeam'
import FundCompanyInfo from './component/FundCompanyInfo'
import moment from 'moment'
import axios from 'libs/axios'

import 'styles/user/business.scss'

const { TabPane } = Tabs

const BusinessFund = props => {
    const [activeKey,setActiveKey ] = useState('1')
    const [isChange,setChange ] = useState(false)
    const [foundTime, setFoundTime] = useState(0)

    const tabChange = key => setActiveKey(key)

    //修改信息
    const changeMess = () => {
        setChange(true)
    }

    const tabFormCompany = v => {
        let obj = {}
        if (!v.founded){
            obj = Object.assign(obj, {...v, founded: foundTime})
        } else {
            obj = Object.assign(obj, {...v, founded: moment(v.founded).unix()})
        }
        const newData = Object.assign({}, {company: obj, id: props.match.params.id})
        axios.post('/apply', { data: JSON.stringify(newData)}).then(res => {
            console.log(res, 'success')
            if (res.code === 10000 ) {
                message.success('保存成功！')
                setChange(false)
            }
        })
    }

    const changeFound = value => {
        setFoundTime(value)
    }

    const tabFormTeam = v =>{
        let obj = Object.assign({}, {...v})
        const newData = Object.assign({}, {team: obj, id: props.match.params.id})
        axios.post('/apply', { data: JSON.stringify(newData)}).then(res => {
            console.log(res, 'success')
            if (res.code === 10000) {
                message.success('保存成功！')
                setChange(false)
            }
        })
    }

    return <div className='business-nofund container'>
        <div className="title">个人中心/Me <div className="bor"></div></div>
        <div className="title2"><span>我的报名/My Projects-创业大赛-融资组</span> 
            {/* <img src={require('assets/images/xiugai.png')} alt='xiugai' className="xiugai" onClick={changeMess}/> */}
        </div>
        <Tabs activeKey={activeKey} onChange={tabChange}>
            <TabPane tab="公司信息/Company Info" key="1">
                <FundCompanyInfo isChange={isChange} {...props} onSubmit={tabFormCompany} changeFound={changeFound} />
            </TabPane>
            <TabPane tab="团队成员信息/Team" key="2">
                <FundTeam isChange={isChange} {...props} onSubmit={tabFormTeam} />
            </TabPane>
        </Tabs>
    </div>
} 

export default BusinessFund
