import React, { useEffect, useState } from 'react'
import { Form,Button,Input,DatePicker,Checkbox,message } from 'antd'
import LABELS from 'pages/json/labels.json'
import TIPS from 'pages/json/tips.json'
import moment from 'moment'
import axios from 'libs/axios'
import { OnlyCH,websiteRule,MobileDelBr } from 'utils'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'

const { TextArea } = Input

const CompanyInfo = props => {
    const [ form ] = Form.useForm()
    const [ checked, setChecked ] = useState([])
    const [ options, setOptions ] = useState([])

    // 时间选择范围
    const disabledDate = current=>{
        // Can not select days after today and today
        return current && current > moment().endOf('day')
    }

    // 限制checkbox
    const checkboxChange = v => {
        setChecked(v)
    }

    // 请求多个赛道
    useEffect(()=>{
        axios.post('/game',{type:2}).then(res=>{
            if (res.code === 10000){
                setOptions(res.data.items)
            }
        })
    },[])
    // 提交失败
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        if (errorFields.length > 0) {message.warning('项目信息不完整！')}
    }
    MobileDelBr(LABELS)
    return <Form {...layout} autoComplete="off" form={form} className='innovate-form busi-form'
        name="company-info"
        onFinish={props.onSubmit}
        onFinishFailed={onFinishFailed}
        initialValues={{
            "ch_name":'',
            "en_name":'',
            "ch_award": '',
            "ch_develpment": '',
            "ch_location": '',
            "ch_need": '',
            "ch_partner": '',
            "ch_product": '',
            "en_award": '',
            "en_develpment": '',
            "en_location": '',
            "en_need": '',
            "en_partner": '',
            "en_product": '',
            "founded": 0,
            "sector": '',
            "website": ''
        }}
    >
        <h3 className='company-tip'>*详尽填写信息将有助于评委更好了解项目<br/>&nbsp;Filling in the information in detail will help the judges understand the project</h3>

        {/* 所处赛道 */}
        <Form.Item
            className='double-input sector-checkbox'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_sector}}></i>}
            name="sector"
            rules={[ { required: true, message: '该项是必填项！'}]}
        >
            <Checkbox.Group onChange={checkboxChange} >
                {
                    options.map(item => {
                        return <Checkbox value={item.id} disabled={!checked.includes(item.id) && checked.length === 2 }>
                            {item.title}
                        </Checkbox>
                    })
                }
            </Checkbox.Group>
        </Form.Item>

        {/* 成立时间 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_founded}}></i>}
            name="founded"
        >
            <DatePicker placeholder='选填，请选择成立时间/Founded in' disabledDate={disabledDate} allowClear={false}/>
        </Form.Item>

        {/* 企业所在地*/}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_location}}></i>}
            name="ch_location"
            rules={[{type:'string',max:50,message:'长度不能超过50个字符！'}]}
        >
            <Input placeholder='请输入所在地'/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_location"
            rules={[{type:'string',max:50,message:'长度不能超过50个字符！'},{ validator:OnlyCH }]}
        >
            <Input placeholder="Location"/>
        </Form.Item>

        {/* 企业官网 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_website}}></i>}
            name="website"
            rules={[{validator:websiteRule}]}
        >
            <Input placeholder='选填，请输入官网地址/Official Website'/>
        </Form.Item>

        {/* 主要产品 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_product}}></i>}
            name="ch_product"
            rules={[{type:'string',max:100,message:'长度不能超过100个字符！'}]}
        >
            <Input placeholder='请输入主要产品'/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_product"
            rules={[{ validator:OnlyCH },{type:'string',max:100,message:'长度不能超过100个字符！'}]}
        >
            <Input placeholder="Main Products"/>
        </Form.Item>

        {/* 合作伙伴 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_partner}}></i>}
            name="ch_partner"
            rules={[{required: true,  message: '该项是必填项！'}, {type:'string',max:5000,message:'长度不能超过5000个字符！'}]}
        >
            <TextArea placeholder='必填，请输入现有客户案例' autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_partner"
            rules={[{ validator:OnlyCH },{type:'string',max:10000,message:'长度不能超过10000个字符！'}]}
        >
            <TextArea placeholder='(Optional) Enter partner' autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_company_parter}}></span>

        {/* 知识产权 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_award}}></i>}
            name="ch_award"
            rules={[{required: true,  message: '该项是必填项！'}, {type:'string',max:5000,message:'长度不能超过5000个字符！'}]}
        >
            <TextArea placeholder='必填，请输入专利、奖励及证书等' autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_award" 
            rules={[{ validator:OnlyCH },{type:'string',max:10000,message:'长度不能超过10000个字符！'}]}
        >
            <TextArea placeholder='(Optional) Enter patents, awards and certificates' autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_company_award}}></span>

        {/* 发展规划 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_development}}></i>}
            name="ch_develpment" 
            rules={[{required: true,  message: '该项是必填项！'}, {type:'string',max:5000,message:'长度不能超过5000个字符！'}]}
        >
            <TextArea placeholder='必填，请输入发展规划' autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_develpment" 
            rules={[{ validator:OnlyCH },{type:'string',max:10000,message:'长度不能超过10000个字符！'}]}
        >
            <TextArea placeholder='(Optional) Enter development plan' autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_company_plan}}></span>

        {/* 发展需求 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_company_need}}></i>}
            name="ch_need"
            rules={[
                // {required: true,  message: '该项是必填项！'}, 
                {type:'string',max:5000,message:'长度不能超过5000个字符！'}
            ]}
        >
            <TextArea placeholder='选填，请输入发展需求' autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_need" 
            rules={[{ validator:OnlyCH },{type:'string',max:10000,message:'长度不能超过10000个字符！'}]}
        >
            <TextArea placeholder='(Optional) Enter resources needed' autoSize = {{ minRows: 4, maxRows: 4 }}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_company_need}}></span>
        <Form.Item {...buttonLayout} className='button-box'>
            <Button type="primary" htmlType='submit'> 保存 </Button>
        </Form.Item>
    </Form>
}
export default CompanyInfo