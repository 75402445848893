/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react'
import 'styles/judges/judges.scss'
import { Menu, Dropdown } from 'antd'
import { DownOutlined, UpOutlined } from '@ant-design/icons'
import className from 'classname'

class Judges extends Component {
    constructor(props) {
        super(props)
        this.state = {
            gudge: [],
            list: ['2021'],
            current: '2021',
            isVisible: false,
            currentIndex: null,
            num: null
        }
    }

    componentDidMount() {
        this.getJudgesData()
    }

    getJudgesData = () => {
        this.axios.post('/judges', { page: 1, size: 100, year: this.state.current }).then(res => {
            if (res.code === 10000) {
                this.setState({ gudge: res.data.items })
            }
        })
    }

    clickJudge = (i, n) => {
        const { num } = this.state
        if (num === i){
            let speakersUL = document.getElementById('speakers')
            let addNode = document.getElementById('add-node')
            if (addNode){
                speakersUL.removeChild(addNode)
            } else {
                let m = i % n
                let speakersUL = document.getElementById('speakers')
                let _this = this
                let data = this.state.gudge[i - 1]
                console.log(data, 'data')
                this.setState({ activeID: i - 1, currentIndex: i - 1 })
                let addNode = document.getElementById('add-node')
                if (addNode) {
                    speakersUL.removeChild(addNode)
                }
                addNode = document.createElement('div')
                addNode.setAttribute('id', 'add-node')
                addNode.innerHTML = `<h2>${data.name}</h2> 
                        <i id='close'><img src="${require('assets/images/x.png')}" alt="x"/></i>
                        <h3>${data.one_line ? data.one_line : ''}</h3>
                        <p>${data.content ? data.content : ''}</p>
                    `

                if (!m) {
                    speakersUL.insertBefore(addNode, speakersUL.childNodes[i])
                } else {
                    speakersUL.insertBefore(addNode, speakersUL.childNodes[i - m + n])
                }
                document.getElementById('close').addEventListener('click', function () {
                    speakersUL.removeChild(addNode)
                    _this.setState({ activeID: null })
                    _this.setState({ currentIndex: null })
                }, false)
            }
        } else {
            let m = i % n
            let speakersUL = document.getElementById('speakers')
            let _this = this
            let data = this.state.gudge[i - 1]
            console.log(data.name, 'data2')
            this.setState({ activeID: i - 1, currentIndex: i - 1 })
            let addNode = document.getElementById('add-node')
            if (addNode) {
                speakersUL.removeChild(addNode)
            }

            addNode = document.createElement('div')
            addNode.setAttribute('id', 'add-node')
            addNode.innerHTML = `<h2>${data.name}</h2>
                <i id='close'><img src="${require('assets/images/x.png')}" alt="x"/></i>
                <h3>${data.job ? data.job : ''}</h3>
                <p>${data.content ? data.content : ''}</p>
                <i id='close'></i>
            `

            if (!m) {
                speakersUL.insertBefore(addNode, speakersUL.childNodes[i])
            } else {
                speakersUL.insertBefore(addNode, speakersUL.childNodes[i - m + n])
            }
            document.getElementById('close').addEventListener('click', function () {
                speakersUL.removeChild(addNode)
                _this.setState({ activeID: null })
                _this.setState({ currentIndex: null })
            }, false)
        }
        
    }

    handleButtonClick = e => {
        this.setState({
            current: e.key,
            isVisible: false
        }, () => {
            this.getJudgesData()
        })
    }

    onVisibleChange = status => {
        this.setState({
            isVisible: status
        })
        // console.log('菜单显示状态', status)
    }
    render() {
        let ss = document.getElementById('add-node')
        const { gudge, list, current, isVisible, currentIndex } = this.state
        const menu = <Menu onClick={this.handleButtonClick} selectedKeys={[current]}>{
            list.map(item => {
                return <Menu.Item key={item}>{item}</Menu.Item>
            })
        }</Menu>
        return (
            <div className="judges-content">
                <div className="judges-body" id='speakers'>
                    {
                        gudge && gudge.map((item, index) => {
                            return <div className="item" key={index} onClick={document.body.clientWidth > 750 ? ()=>{ this.setState({num: index + 1}, this.clickJudge(index + 1,5))} : ()=>{ this.setState({num: index + 1}, this.clickJudge(index + 1,2))}}>
                                <img src={item.avatar} alt="1" className='avatar' />
                                <div className="name">{item.name}</div>
                                <div className="job">{item.one_line}</div>
                                <img src={require('assets/images/sanjiao.png')} alt='sanjiao' className={className(['sanjiao', { 'sanjiao-active': currentIndex === index && ss !== null || undefined }])} />
                            </div>
                        })
                    }
                </div>
            </div>
        )
    }
}

export default Judges
