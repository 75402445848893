import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Modal, Form, Button, message } from "antd"
import "styles/user/teaminvite.scss"
class deleteMember extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }
    componentDidMount() {}
  handleChange = value => {
      console.log(`selected ${value}`)
  };
  handleCancel = () => {
      this.props.closeDialog()
  }

  //移除队员
  deleteMember = () => {
      const {user_id} = this.props.selectItem
      const {team_id} = this.props
      this.axios.post('/team/teamMemberRemove', {user_id, team_id})
          .then(res => {
              if (res.code === 10000){
                  message.success('移除成功')
                  this.props.closeDialog()
              }
          })
  }
  render() {
      const { isShowDelMember, selectItem } = this.props
      return (
          <Modal
              visible={isShowDelMember}
              destroyOnClose
              footer={null}
              className="delete-team-info"
              centered={true}
              onCancel={this.handleCancel}
          >
              <div className="title"></div>
              <Form
                  name="basic"
                  autoComplete="off"
                  onFinish={this.deleteMember}
              >
                  <div className="info-content">
                      <div>是否确认将{selectItem.name_cn}移除队伍？</div> 
                  </div>
                  <Form.Item>
                      <div className="btns">
                          <Button type="primary" htmlType="submit" className="btn">确认</Button>
                          <Button htmlType="button" className="btn" onClick={this.handleCancel}>取消</Button>
                      </div>
                  </Form.Item>
              </Form>
          </Modal>
      )
  }
}
export default withRouter(deleteMember)
