import Layout from 'pages/layout'
import Home from 'pages/home'
import Judges from 'pages/judges/Judges'
import Business from 'pages/signup/Business'
import Innovate from 'pages/signup/Innovate'
import Challenge from 'pages/signup/Challenge'
import ChallengeTopics from 'pages/signup/ChallengeTopics'
import Guid from 'pages/signup/Guid'
import UserCenter from 'pages/user/Center'
import UserCenterBusinessNoFund from 'pages/user/BusinessNoFund'
import UserCenterBusinessFund from 'pages/user/BusinessFund'
import UserCenterInnovate from 'pages/user/Innovate'
import Agreement from 'pages/agreement/Agreement'
import PaySuccessModal from 'comps/PaySuccessModal'
import PayCode from 'comps/PayCode'
import Notice from 'pages/notice/Notice'
import Reading from 'pages/reading/Reading'
import MyTeam from 'pages/user/MyTeam'
import SupplementalResult from 'pages/user/SupplementalResult'
import Information from 'pages/user/Information'
// 大赛报名
import InnovateForm from 'pages/form/Innovate'
import BusinessGroup from 'pages/form/BusinessGroup'
import BusinessNoFund from 'pages/form/BusinessNoFund'
import BusinessFund from 'pages/form/BusinessFund'
import Login from 'pages/login/Login'
import Register from 'pages/login/Register'
import ResetPwd from 'pages/login/ResetPwd'
import News from 'pages/news/News'
import Video from 'pages/video/Video'

const PrivateRoutes = [{
    path: '/',
    component: Layout,
    childRoutes: [
        { path: '/', component: Home },
        { path: '/home', component: Home },
        { path: '/jury', component: Judges },
        { path: '/signup/business', component: Business },
        { path: '/signup/innovate', component: Innovate },
        { path: '/signup/challenge', component: Challenge },
        { path: '/signup/challenge/topics/:title', component: ChallengeTopics }, //企业挑战报名
        { path: '/signup/innovate/innovateform',component:InnovateForm }, // 创新大赛报名表单
        { path: '/signup/business/businessgroup',component:BusinessGroup }, //  创业大赛 选择组别
        { path: '/signup/business/nofund',component:BusinessNoFund }, // 创业大赛 未融资表单
        { path: '/signup/business/fund',component:BusinessFund }, // 创业大赛 融资表单
        { path: '/usercenter', component: UserCenter},
        { path: '/usercenter/business/nofund/:id', component: UserCenterBusinessNoFund}, //个人中心创业大赛未融资
        { path: '/usercenter/business/fund/:id', component: UserCenterBusinessFund}, //个人中心创业大赛融资
        { path: '/usercenter/innovate/:id/:is_run', component: UserCenterInnovate}, //创新大赛
        { path: '/agreement', component: Agreement}, //创新大赛
        { path: '/guid', component: Guid },
        { path: '/login', component:Login },
        { path: '/register', component:Register },
        { path: '/reset', component:ResetPwd },
        { path: '/news', component: News },
        { path: '/video', component: Video },
        { path: '/payback', component: PaySuccessModal },
        { path: '/pay/weixin', component: PayCode },
        { path: '/notice', component: Notice },
        { path: '/reading', component: Reading },
        { path: '/myteam/:team_id/:is_run', component: MyTeam },
        { path:'/supplementalresult/:apply_id/:is_run', component: SupplementalResult },
        { path:'/information', component: Information }
    ]
}]

export default PrivateRoutes
