
import React, { Component, useEffect, useState } from 'react'
import { Modal, Button, Form, Upload, message, Input } from 'antd'
import 'styles/comps/signup/upload-dialog.scss'
import PackUp from 'comps/PackUp'
import axios from 'libs/axios'
import className from 'classname'
import Dialog2 from 'comps/signup/Dialog2'
import { getStorage, clearStorage } from 'utils/storage'
import { APIBaseURL, Msg, UserColumns, UploadIcon, UploadFileError } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
import reqwest from 'reqwest'
const WikiUploadDialog = props => {
    const status = useSelector(state => state)
    const [ form ] = Form.useForm()
    const [defaultFileList, setDefaultFileList] = useState([])
    const [upFile, setUpFile] = useState({})
    const domainUrl = process.env.API_ENV === 'test' ? 'https://twww.synbiohive.com' : 'http://www.synbiochallenges.com'
    const name = props.wikiItem &&  props.wikiItem.team_info.en_name
    const [wikiUrl, setWikiUrl] = useState(`${domainUrl}/wiki/${props.version}/${name}/index.html`)
    const [downloadRequest, setDownlaodRequest] = useState({})
    const [isAble, setIsAble] = useState(false)
    const [is_run, setIsRun] = useState(props.wikiItem &&  props.wikiItem.is_run)
    console.log('document.domain', props.wikiItem)
    useEffect(() => {
        axios.post('/wiki').then(res => {
            if (res.code === 10000){
                setDownlaodRequest(res.data)
            }
        })
    }, [])
    
    //关闭弹框
    const closeDialog = () => {
        setDefaultFileList([])
        form.setFieldsValue({ fileName: ''})
        form.setFieldsValue({ fileUrl: ''})
        form.setFieldsValue({ filedata: ''})
        props.closeDialog()        
    }
    const handleOk = () => {

    }

    // 文件上传前
    const beforeUpload = file => {
        // console.log('file', file)
        setDefaultFileList([file])
        setUpFile(file)
        const isLt2M = file.size / 1024 / 1024 < 50
        if (!isLt2M) {
            message.error('文件大小不超过50M！')
        }
        return false
    }

    //上传文件
    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setDefaultFileList([...info.fileList])
        }
        // 删除文件
        if (info.file.status === 'removed'){
            setDefaultFileList([])
            form.setFieldsValue({ fileName: ''})
            form.setFieldsValue({ fileUrl: ''})
            form.setFieldsValue({ filedata: ''})
        }

        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.url, name = info.file.response.data.old_name
            const file = {name,url,status:'done',uid:url}
            setDefaultFileList([file])
            form.setFieldsValue({ fileName: name})
            form.setFieldsValue({ fileUrl: url})
            let filedata = { fileName: name, fileUrl: url}
            form.setFieldsValue({ filedata: JSON.stringify(filedata)})
            // console.log('upFileData', info.file.originFileObj)
            setUpFile(info.file.originFileObj)
        } else if (info.file.status === 'done' && info.file.response.code !== 10000) {
            UploadFileError(info.file.response)
            const file = { name:info.file.name,url:'',status:'error',uid:info.file.uid }
            setDefaultFileList([file])
            form.setFieldsValue({ fileName: ''})
            form.setFieldsValue({ fileUrl: ''})
            form.setFieldsValue({ filedata: ''})
        }
    }

    // 上传配置项
    let uploadProps = {
        accept:'.rar,.zip,.tar,.tar.gz,application/x-zip-compressed, application/x-tar, application/x-gzip, application/octet-stream',
        // accept: '.rar, application/x-zip-compressed',
        listType:'file',
        maxCount:1,
        action: `${APIBaseURL}/wiki/up`,
        data:{id: props.wikiItem.id},
        headers: { token: getStorage('token') },
        beforeUpload: beforeUpload,
        fileList: defaultFileList
    }

    //保存编辑信息
    const onFinish = values => {
        // console.log('value',values)
        // console.log('value',JSON.parse(values.filedata))
        // console.log('upFile', upFile)
        // console.log('defaultFileList', defaultFileList)
        const formData = new FormData()
        // defaultFileList.forEach(file => {
        //     formData.append('file', file)
        //     formData.append('id', 138)
        // })
        formData.append('file', upFile)
        formData.append('id', props.wikiItem.id)
        console.log('upFile', upFile, values)
        setIsAble(true)
        const isLt2M = upFile.size / 1024 / 1024 < 50
        if (!isLt2M) {
            message.error('文件大小不超过50M！')
            setIsAble(false)
        } else {
            const hide = message.success('正在提交请稍后', 0)
            reqwest({
                url: `${APIBaseURL}/wiki/up`,
                method: 'post',
                processData: false,
                data: formData,
                headers: { token: getStorage('token') },
                success: res => {
                    setIsAble(false)
                    if (res.code === 10000){
                        // console.log('成功res', res)
                        message.success('文件上传成功，可点击地址进行预览')
                        setTimeout(hide, 2500)
                        closeDialog()
                    } else if (res.code === 20041){
                        setTimeout(hide, 0)
                        message.error('上传文件中没有index.html文件！')
                        setIsAble(false)
                    } else if (res.code === 20039){
                        setTimeout(hide, 0)
                        message.error('上传文件发布失败，请重新上传！')
                        setIsAble(false)
                    }  else if (res.code === 20040){
                        setTimeout(hide, 0)
                        message.error('上传文件格式出错！')
                        setIsAble(false)
                    }  else if (res.code === 20000 || res.code === 20008){
                        setTimeout(hide, 0)
                        message.error('上传失败，请邮件联系info@synbiohive.com')
                        setIsAble(false)
                    }  else if (res.code === 20009){
                        setTimeout(hide, 0)
                        message.error('没有报名创新大赛，不能上传wiki！')
                        setIsAble(false)
                    } else {
                        message.error(res.message)
                        setTimeout(hide, 0)
                        setIsAble(false)
                    }
                },
                error: res => {
                    console.log('失败res', res)
                    message.error('提交失败')
                    setTimeout(hide, 0)
                    setIsAble(false)
                    // message.error('upload failed.')
                },
            })
        }
        
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Modal  visible={props.isWikiShow} onOk={handleOk} onCancel={closeDialog} destroyOnClose footer={null} centered={true} className={className(["challenge-topics-dialog", 'wiki-topics-dialog'])} >
            <div className="title">Wiki</div>
            <div className='url'><span>地址/URL：</span><a href={wikiUrl} target='_blank' rel="noopener noreferrer"className='wikiurl'>{wikiUrl}</a></div>
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                {
                    is_run === 1 ? <div>
                        <div className="dialog-content">
                            <div className="dialog-content-up">
                                <div className="lable">代码文件：</div>
                                <Form.Item 
                                    name="filedata" 
                                    rules={[ { required: true, message: '必须上传一个文件'}]}> 
                                    <Upload {...uploadProps} onChange={handleChange} fileList={defaultFileList}>
                                        <div className='icon-box'>
                                            <img src={require('assets/images/upload.png')} alt="上传icon"/>
                                            <div style={{ marginTop: 8 }}>{props.wikiStatus === 1 ? '重新上传' : '上传'}</div>
                                        </div>
                                    </Upload>
                                </Form.Item>
                        
                            </div>
                            <div className="reqbox">
                                <a href={downloadRequest.description_url} target='_blank' rel="noopener noreferrer"className='request'>代码要求说明</a>
                                <a href={downloadRequest.template_url} target='_blank' rel="noopener noreferrer"className='request'>Wiki模板下载</a>
                            </div>
                            {/* <div className="tips">代码文件要求：<br/>
                    1、压缩包格式需为rar、zip、tar、tar.gz<br/>
                    2、压缩包的第一层中必须包含index.html文件<br/>
                    3、所有的文件、图片链接均需使用相对目录<br/>
                    4、本项目目前不支持打包前端程序如vue、react等<br/>
                    5、所有的内部跳转地址链接需要加上/wiki/团队名/链接地址 <br/>
                    6、主办方将于9月底团队报名结束后，提供代码参考。团队可自行下载修改。<br/></div> */}
                            {/* <div className="tips">Note: {`<`}10M, in PPT or PDF format</div> */}
                        </div>
                        <Form.Item  className="btn-box">
                            <Button type="primary" htmlType="submit" className="ok" disabled={isAble}>确认提交</Button>
                            <div className="cancel" onClick={closeDialog}>取消</div>
                        </Form.Item>
                        <Form.Item  className="btn-box-h5">
                            <div className="cancel" onClick={closeDialog}>取消</div>
                            <Button type="primary" htmlType="submit" className="ok" disabled={isAble}>确认提交</Button>
                        </Form.Item>
                    </div> : null
                }
                
            </Form>
            
        </Modal>
    )
}

export default WikiUploadDialog