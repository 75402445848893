import React, { useState } from 'react'
import { Tabs, message } from 'antd'
import NoFundTeam from './component/NoFundTeam'
import CompanyInfo from './component/CompanyInfo'
import NoFundBaseInfo from './component/NoFundBaseInfo'
import TopFormBg from 'comps/TopFormBg'
import ModalSuccess from 'comps/ModalSuccess'
import SubmitModal from 'comps/SubmitModal'
import axios from 'libs/axios'
import H5ModalSuccess from 'comps/H5ModalSuccess'

const { TabPane } = Tabs

const BusinessNoFund = props => {
    const [ activeKey,setActiveKey ] = useState('1')
    const [ allData,setAllData ] = useState({})
    const [ isShow,setShow ] = useState(false)
    const [ isTrue, setIsTrue ] = useState(false)
    const [ h5IsShow, setH5IsShow] = useState(false)

    const tabChange = key => setActiveKey(key)

    // 下一步按钮
    const nextStep = () => {
        if (Number(activeKey) < 3){
            setActiveKey(String(Number(activeKey) + 1))
        }
    }
    // 基本信息
    const tabFormBase = values => {
        console.log('base form',values)
        nextStep()
        setAllData(Object.assign(allData,{'basicData':values}))
    }
    // 团队信息
    const tabFormTeam = v => {
        // cv_files: (2) [{…}, {…}] --- 队员简历
        // nofund_team: Array(2) --- 队员的其他信息
        // 验证队员简历
        const files_len = (v.cv_files && v.cv_files.length) || 0, // undefined的情况 默认是0
            data_len = v.nofund_team.length
        console.log('files',v.cv_files)
        if (files_len < data_len){
            message.warning('成员简历不完整！')
            return
        }
        for (let ele of v.cv_files){
            if (!ele.file){
                message.warning(`成员的中文简历不能为空`)
                return
            }
            // if (!ele.fileEN) {
            //     message.warning(`成员的英文简历不能为空`)
            //     return
            // }
        }
        console.log('teamData',v)
        nextStep()
        setAllData(Object.assign(allData,{'teamData':v}))
    }
    // 公司信息
    const tabFormCompany = v => {
        console.log('company data',v)
        // type=2 创业大赛，group=1 未融资组
        const newData = Object.assign(allData,
            {'companyData':{...v,founded:v.founded ? v.founded.format('YYYY-MM-DD') : ''},
                date:2021,type:2,group:1
            })
        // 判断是否包含 basicData,teamData 且不能为空
        if (!newData.hasOwnProperty('basicData')) {
            message.warning('基本信息不完善！')
            return
        } else if (!newData.hasOwnProperty('teamData')){
            message.warning('团队主创成员信息不完善！')
            return
        }
        console.log('all',newData)
        setAllData(newData)
        setIsTrue(true)
    }

    const submitForm = () => {
        // 点击提交，弹出提示框是否提交，确认后提示【提交成功】，回到【用户中心】页面
        // { data:JSON.stringify(newData) }
        // 提交所有数据 涉及到个人中心编辑，是分块提交 还是 合并提交
        axios.post('/apply',{ data:JSON.stringify(allData) }).then(res => {
            if (res.code === 10000){
                if (window.innerWidth >= 750){
                    // 第一次保存成功 弹框
                    setIsTrue(false)
                    setShow(true)
                } else {
                    setIsTrue(false)
                    setH5IsShow(true)
                }
            }
        })
    }
    // 确认弹框出现
    const changeTrue = value => setIsTrue(value)

    return <>
        <TopFormBg type={2}/>
        <section className='innovate-con-form container'>
            <h1>未融资组/Without Funding</h1>
            <Tabs activeKey={activeKey} onChange={tabChange}>
                <TabPane tab="基本信息/Base Info" key="1">
                    <NoFundBaseInfo onSubmit={tabFormBase}/>
                </TabPane>

                <TabPane tab="团队主创成员信息/Team" key="2">
                    <NoFundTeam onSubmit={tabFormTeam}/>
                </TabPane>

                <TabPane tab="项目信息/Project" key="3">
                    <CompanyInfo onSubmit={tabFormCompany}/>
                </TabPane>
            </Tabs>
        </section>
        <SubmitModal isTrue={isTrue} text='是否确认提交？' close={changeTrue} ok={submitForm}/>
        <ModalSuccess isShow={isShow} backURL ='/signup/business' text='感谢报名Synbio Hive创业大赛！平台已开启“企业挑战”特别环节，方向符合的项目可选择（非必须）其中一个方向参与企业挑战。有关赛事的任何问题，欢迎联系：info@synbiohive.com'/>
        <H5ModalSuccess isShow={h5IsShow} backURL ='/signup/business' text='感谢报名Synbio Hive创业大赛！平台已开启“企业挑战”特别环节，方向符合的项目可选择（非必须）其中一个方向参与企业挑战。有关赛事的任何问题，欢迎联系：info@synbiohive.com'/>
    </>
}

export default BusinessNoFund
