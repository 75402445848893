
import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Form,Button,Input,Upload,message,Space, Skeleton } from 'antd'
import { APIBaseURL, OnlyCH,UploadIcon,UploadFileError,MobileDelBr } from 'utils'
import { getStorage } from 'utils/storage'
import className from 'classname'
import "styles/user/supplementalresult.scss"
class SupplementalResult extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            safetyList: [],
            integrityList: [],
            agreementList: [],
            isLoading: true,
            apply_id: this.props.match.params.apply_id,
            team_id: '',
            team_name: '',
            letter_info: [],
            area_info: [],
            fund_source_info: [],
            type: 0,
            file_path: '',
            file_name: '',
            supply_info_modify_auth: false
        }
    }
    componentDidMount() {
        this.getSupplyInfo()
    }
    //上传文件
    handleChange = (type, info) => {
        // console.log('info', type, info)
        if (info.file.status === 'uploading') {
            if (type === 'safety'){
                this.setState({ safetyList: [...info.fileList] })
            } else if (type === 'integrity'){
                this.setState({ integrityList: [...info.fileList] })
            } else if (type === 'agreement'){
                this.setState({ agreementList: [...info.fileList] })
            }
        }
        // 删除文件
        if (info.file.status === 'removed'){
            if (type === 'safety'){
                this.formRef.current.setFieldsValue({ safety_url: ''})
                this.formRef.current.setFieldsValue({ safety_name: ''})
                this.setState({
                    safetyList: [],
                    file_path: '',
                    file_name: '',
                    type: 1
                },()=> {
                    this.supplyInfoUpdate({file_path: '', file_name: '', type: 1})
                }) 
            } else if (type === 'integrity'){
                this.formRef.current.setFieldsValue({ integrity_url: ''})
                this.formRef.current.setFieldsValue({ integrity_name: '' })
                this.setState({
                    integrityList: [],
                    file_path: '',
                    file_name: '',
                    type: 2
                },()=> {
                    this.supplyInfoUpdate({file_path: '', file_name: '', type: 2})
                }) 
            } else if (type === 'agreement'){
                this.formRef.current.setFieldsValue({ agreement_url: ''})
                this.formRef.current.setFieldsValue({ agreement_name: '' })
                this.setState({
                    agreementList: [],
                    file_path: '',
                    file_name: '',
                    type: 3
                },()=> {
                    this.supplyInfoUpdate({file_path: '', file_name: '', type: 3})
                }) 
            }
        }

        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.full_path, name = info.file.response.data.original_name
            const file = {name,url,status:'done',uid:url}
            // console.log('info.file', info.file, name, url)
            if (type === 'safety') {
                this.formRef.current.setFieldsValue({ safety_url: url})
                this.formRef.current.setFieldsValue({ safety_name: name})
                this.setState({
                    safetyList: [file],
                    file_path: url,
                    file_name: name,
                    type: 1
                },()=> {
                    this.supplyInfoUpdate({file_path: url,file_name: name,type: 1})
                }) 
            } else if (type === 'integrity'){
                this.formRef.current.setFieldsValue({ integrity_url: url })
                this.formRef.current.setFieldsValue({ integrity_name: name })
                this.setState({
                    integrityList: [file],
                    file_path: url,
                    file_name: name,
                    type: 2
                },()=> {
                    this.supplyInfoUpdate({file_path: url, file_name: name, type: 2})
                }) 
            } else if (type === 'agreement'){
                this.formRef.current.setFieldsValue({ agreement_url: url })
                this.formRef.current.setFieldsValue({ agreement_name: name })
                this.setState({
                    agreementList: [file],
                    file_path: url,
                    file_name: name,
                    type: 3
                },()=> {
                    this.supplyInfoUpdate({file_path: url, file_name: name, type: 3})
                }) 
            }
        } else if (info.file.status === 'done' && info.file.response.code !== 10000) {
            UploadFileError(info.file.response)
            const file = { name:info.file.name,url:'',status:'error',uid:info.file.uid }
            if (type === 'safety') {
                this.formRef.current.setFieldsValue({ safety_url: ''})
                this.formRef.current.setFieldsValue({ safety_name: ''})
                this.setState({ safetyList: [file] }) 
            } else if (type === 'integrity'){
                this.formRef.current.setFieldsValue({ integrity_url: '' })
                this.formRef.current.setFieldsValue({ integrity_name: '' })
                this.setState({ integrityList: [file] }) 
            } else if (type === 'agreement'){
                this.formRef.current.setFieldsValue({ agreement_url: '' })
                this.formRef.current.setFieldsValue({ agreement_name: '' })
                this.setState({ agreementList: [file] }) 
            }
        }
    }

    onFinish = values => {
        console.log('Received values of form:', values)
    };

    areaFinish = values => {
        console.log('areaFinish', values.area_info)
        this.setState({area_info: values.area_info, type: 4}, ()=> {
            this.supplyInfoUpdate({area_info: JSON.stringify(values.area_info), type: 4})
        })
    }

    sourceInfoFinish = values => {
        console.log('sourceInfoFinish', values)
        this.setState({fund_source_info: values.fund_source_info, type: 5}, ()=> {
            this.supplyInfoUpdate({fund_source_info: JSON.stringify(values.fund_source_info), type: 5})
        })
    }
    // 获取补充材料信息
    getSupplyInfo = () => {
        const {apply_id} = this.state
        this.axios.post('/gameApply/getSupplyInfo', {apply_id})
            .then(res => {
                if (res.code === 10000){
                    let safetyList = res.data.letter_info[0].file_path ? [{name: res.data.letter_info[0].file_name, uid: res.data.letter_info[0].file_path, url:  res.data.letter_info[0].file_path, status:'done'}] : []
                    let integrityList = res.data.letter_info[1].file_path ? [{name: res.data.letter_info[1].file_name, uid: res.data.letter_info[1].file_path, url:  res.data.letter_info[1].file_path, status:'done'}] : []
                    let agreementList = res.data.letter_info[2].file_path ? [{name: res.data.letter_info[2].file_name, uid: res.data.letter_info[2].file_path, url:  res.data.letter_info[2].file_path, status:'done'}] : []
                    this.setState({
                        team_name: res.data.team_name,
                        team_id: res.data.team_id,
                        letter_info: res.data.letter_info,
                        area_info: res.data.area_info,
                        fund_source_info: res.data.fund_source_info,
                        // area_info: [],
                        // fund_source_info: [],
                        safetyList,
                        integrityList,
                        agreementList,
                        supply_info_modify_auth: res.data.supply_info_modify_auth,
                        isLoading: false,
                    })
                    // res.data.letter_info[0] && this.formRef.current.setFieldsValue({ safety_url: res.data.letter_info[0].file_path})
                    // res.data.letter_info[0] && this.formRef.current.setFieldsValue({ safety_name: res.data.letter_info[0].file_name})
                    // res.data.letter_info[1] && this.formRef.current.setFieldsValue({ integrity_url: res.data.letter_info[1].file_path})
                    // res.data.letter_info[1] && this.formRef.current.setFieldsValue({ integrity_name: res.data.letter_info[1].file_name})
                    // res.data.letter_info[2] && this.formRef.current.setFieldsValue({ agreement_url: res.data.letter_info[2].file_path})
                    // res.data.letter_info[2] && this.formRef.current.setFieldsValue({ agreement_name: res.data.letter_info[2].file_name})
                }
            })
    }

    // 补充材料更新
    supplyInfoUpdate = data => {
        const {apply_id, team_id, type, file_path, file_name, area_info, fund_source_info} = this.state
        // console.log('补充材料更新', data)
        this.axios.post('/gameApply/supplyInfoUpdate', {apply_id, team_id, type, ...data })
            .then(res => {
                if (res.code === 10000){
                    if (type === 1 || type === 2 || type === 3){
                        data.file_path ?  message.success('文件提交成功') : message.success('文件删除成功')
                    } else {
                        message.success('保存成功')
                    }
                    this.setState({
                    }, () => {
                        this.getSupplyInfo()
                    })
                }
            })
    }
    render() {
        const { safetyList,integrityList, agreementList, isLoading, team_name, letter_info, area_info, fund_source_info, supply_info_modify_auth} = this.state
        const {is_run} = this.props.match.params
        console.log('is_run', is_run)
        // 上传配置项
        const uploadProps = {
            accept:'.pdf,application/pdf',
            listType:'file',
            maxCount:1,
            action: `${APIBaseURL}/up/uploadFile`,
            headers: { token: getStorage('token') },
            beforeUpload: this.beforeUpload,
            // showUploadList:{showRemoveIcon:false}
        }
        return (
            <div className="supplementalresult">
                <Skeleton loading={isLoading} active>
                    <div className="maintitle">当前队伍名称：{team_name}</div>
                    <div className="upload-container">
                        <Form  name="basic"
                            onFinish={this.onFinish}
                            onFinishFailed={this.onFinishFailed}
                            validateTrigger="onBlur" 
                            ref={this.formRef}
                            onValuesChange={this.onValuesChange}
                        >
                            <Form.Item>
                                <Space>
                                    <Form.Item name="safety_name" > <Input hidden/> </Form.Item>
                                    <Form.Item name="integrity_name" > <Input hidden/> </Form.Item>
                                    <Form.Item name="agreement_name" > <Input hidden/> </Form.Item>
                                    <div className="item">
                                        <div className="ite">
                                            <div className="title">安全保证书</div>
                                            <Form.Item
                                                name="safety_url"
                                                rules={[ { required: true, message: '必须上传一个文件'}]}
                                            >
                                                <Upload {...uploadProps} data={{type: 1}} onChange={ this.handleChange.bind(this,'safety')}  fileList={safetyList} disabled={!supply_info_modify_auth || Number(is_run) === 2}>
                                                    <div className='icon-box'>
                                                        <img src={require('assets/images/upload.png')} alt="上传icon"/>
                                                        <Button className={className(['btn', {'btndisable': Number(is_run) === 2}])} disabled={!supply_info_modify_auth || Number(is_run) === 2}>{safetyList.length ? '重新上传' : '上传'}</Button>
                                                    </div>
                                                </Upload>
                                                {/* 隐藏的file地址 */}
                                                <Input hidden/>
                                            </Form.Item>
                                        </div>
                                        <div className="tips">要求：<a href={letter_info[0] && letter_info[0].template_url} target="_blank" rel="noopener noreferrer">点击下载模版</a > 签字后回传PDF格式</div>
                                    </div>
                                    <div className="item">
                                        <div className="ite">
                                            <div className="title">诚信承诺书</div>
                                            <Form.Item
                                                name="integrity_url"
                                                rules={[ { required: true, message: '必须上传一个文件'}]}
                                            >
                                                <Upload {...uploadProps}  data={{type: 2}} onChange={ this.handleChange.bind(this,'integrity')}  fileList={integrityList} disabled={!supply_info_modify_auth || Number(is_run) === 2}>
                                                    <div className='icon-box'>
                                                        <img src={require('assets/images/upload.png')} alt="上传icon"/>
                                                        <Button className={className(['btn', {'btndisable': Number(is_run) === 2}])} disabled={!supply_info_modify_auth || Number(is_run) === 2}>{integrityList.length ? '重新上传' : '上传'}</Button>
                                                    </div>
                                                </Upload>
                                                <Input hidden/>
                                            </Form.Item>
                                        </div>
                                        <div className="tips">要求：<a href={letter_info[1] && letter_info[1].template_url} target="_blank" rel="noopener noreferrer">点击下载模版</a> 签字后回传PDF格式</div>
                                    </div>
                                    <div className="item">
                                        <div className="ite">
                                            <div className="title">院校参赛同意书</div>
                                            <Form.Item
                                                name="agreement_url"
                                                rules={[ { required: true, message: '必须上传一个文件'}]}
                                            >
                                                <Upload {...uploadProps}  data={{type: 3}} onChange={ this.handleChange.bind(this,'agreement')}  fileList={agreementList} disabled={!supply_info_modify_auth || Number(is_run) === 2}>
                                                    <div className='icon-box'>
                                                        <img src={require('assets/images/upload.png')} alt="上传icon"/>
                                                        <Button className={className(['btn', {'btndisable': Number(is_run) === 2}])} disabled={!supply_info_modify_auth || Number(is_run) === 2}>{agreementList.length ? '重新上传' : '上传'}</Button>
                                                    </div>
                                                </Upload>
                                                <Input hidden/>
                                            </Form.Item>
                                        </div>
                                        <div className="tips">要求：<a href={letter_info[2] && letter_info[2].template_url} target="_blank" rel="noopener noreferrer">点击下载模版</a> 签字后回传PDF格式</div>
                                    </div>
                                </Space>
                            </Form.Item>
                        </Form>
                    </div>
                    <div className="addinfo-container">
                        <div className="addinfo-item">
                            <div className="title">实验场地信息</div>
                            <Form name="area_info" onFinish={this.areaFinish} autoComplete="off" initialValues={{ 'area_info': area_info.length ? area_info : [{area: '', leader: ''}]}}>
                                <Form.List name="area_info">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(field => (
                                                <Space key={field.key} align="baseline">
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                        }
                                                    >
                                                        {() => (
                                                            <Form.Item
                                                                {...field}
                                                                label="实验所用场地"
                                                                name={[field.name, 'area']}
                                                                fieldKey={[field.fieldKey, 'area']}
                                                            >
                                                                {(supply_info_modify_auth && Number(is_run) === 1) ? <Input /> : <div className="con">{area_info[field.fieldKey] && area_info[field.fieldKey].area}</div>}
                                                                
                                                            </Form.Item>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...field}
                                                        label="场地负责人"
                                                        name={[field.name, 'leader']}
                                                        fieldKey={[field.fieldKey, 'leader']}
                                                    >
                                                        {(supply_info_modify_auth && Number(is_run) === 1) ? <Input /> : <div className="con">{area_info[field.fieldKey] && area_info[field.fieldKey].leader}</div>}
                                                        
                                                    </Form.Item>
                                                    {(supply_info_modify_auth && Number(is_run) === 1) ? <div className='action-box'>
                                                        <i className='addicon' onClick={() => add()}/>
                                                        {fields.length > 1 && <i className='removeicon' onClick={() => remove(field.name)} />}
                                                    </div> : null}
                                                </Space>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                                {(supply_info_modify_auth && Number(is_run) === 1) ? <Form.Item>
                                    <Button type="primary" htmlType="submit">保存</Button>
                                </Form.Item> : null}
                            </Form>
                        </div>

                        <div className="addinfo-item">
                            <div className="title">经费来源信息</div>
                            <Form name="fund_source_info" onFinish={this.sourceInfoFinish} autoComplete="off" initialValues={{ 'fund_source_info': fund_source_info.length ? fund_source_info : [{ source: '', amount: '' }] }}>
                                <Form.List name="fund_source_info">
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map(field => (
                                                <Space key={field.key} align="baseline">
                                                    <Form.Item
                                                        noStyle
                                                        shouldUpdate={(prevValues, curValues) => prevValues.area !== curValues.area || prevValues.sights !== curValues.sights
                                                        }
                                                    >
                                                        {() => (
                                                            <Form.Item
                                                                {...field}
                                                                label="经费来源"
                                                                name={[field.name, 'source']}
                                                                fieldKey={[field.fieldKey, 'source']}
                                                            >
                                                                {(supply_info_modify_auth && Number(is_run) === 1) ? <Input /> : <div className="con">{fund_source_info[field.fieldKey] && fund_source_info[field.fieldKey].source}</div>}
                                                            </Form.Item>
                                                        )}
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...field}
                                                        label="金额"
                                                        name={[field.name, 'amount']}
                                                        fieldKey={[field.fieldKey, 'amount']}
                                                    >
                                                        {(supply_info_modify_auth && Number(is_run) === 1) ? <Input /> : <div className="con">{fund_source_info[field.fieldKey] && fund_source_info[field.fieldKey].amount}</div>}
                                                    </Form.Item>
                                                    {(supply_info_modify_auth && Number(is_run) === 1) ? <div className='action-box'>
                                                        <i className='addicon' onClick={() => add()}/>
                                                        {fields.length > 1 && <i className='removeicon' onClick={() => remove(field.name)} />}
                                                    </div> : null}
                                                </Space>
                                            ))}
                                        </>
                                    )}
                                </Form.List>
                                {(supply_info_modify_auth && Number(is_run) === 1) ? <Form.Item>
                                    <Button type="primary" htmlType="submit">保存</Button>
                                </Form.Item> : null}
                            </Form>
                        </div>
                    </div>
                </Skeleton>
            </div>
        )
    }
}
export default withRouter(SupplementalResult)
