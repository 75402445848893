import React, { Component } from 'react'
import { Form, Input, Button, Select } from 'antd'
import TopFormBg from 'comps/TopFormBg'
import TIPS from 'pages/json/tips.json'
import LABELS from 'pages/json/labels.json'
import AREA from 'pages/json/area.json'
import ModalSuccess from 'comps/ModalSuccess'
import SubmitModal from 'comps/SubmitModal'
import { OnlyCH,MobileDelBr } from 'utils'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'
import H5ModalSuccess from 'comps/H5ModalSuccess'
import 'styles/form/innovate.scss'

const { Option } = Select
let province = []
const domainUrl = process.env.API_ENV === 'test' ? 'http://www.synbiochallenges.com' : 'http://www.synbiochallenges.com'
class Innovate extends Component {
    formRef = React.createRef
    constructor(props) {
        super(props)
        this.state = {
            banner: '',
            city: [], // 市
            county: [], // 县
            name_cn: '',
            name_en: '',
            email: '',
            isShow: false,
            teamName: '',
            isTrue: false,
            str: '',
            h5IsShow: false
        }
    }

    componentDidMount() {
        MobileDelBr(LABELS)
        this.axios.post('/branch', { type: 1 }).then(res => {
            if (res.code === 10000) {
                this.setState({ banner: res.data })
            }
        })
        this.axios.post('/user/info').then(res => {
            if (res.code === 10000) {
                const { name_cn, name_en, email } = res.data
                this.setState({
                    name_cn,
                    name_en,
                    email
                })
            }
        })
        this.change()
    }

    onFinish = values => {
        console.log('Success:', values)
        let obj = {
            ...values,
            date: 2021,
            type: 1
        }
        this.setState({
            str: JSON.stringify(obj),
            isTrue: true
        })
    }

    submitForm = () => {
        this.axios.post('/apply', { data: this.state.str }).then(res => {
            if (res.code === 10000) {
                if (window.innerWidth >= 750){
                    this.setState({ 
                        isTrue: false,
                        isShow: true
                    })
                } else {
                    this.setState({
                        isTrue: false,
                        h5IsShow: true
                    })
                }
            }
        })
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }

    // email
    checkEmail = (rule, value) => {
        const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
        if (reg.test(value)) {
            return Promise.resolve()
        }
        if (!value || value.length === 0) {
            return Promise.reject('负责老师邮箱不能为空！')
        }
        return Promise.reject('邮箱格式不对！')
    }

    // 区域分级  
    change = () => {
        AREA.forEach(item => {
            if (item.parent === '100000') {
                province.push(item)
            }
        })
        province.forEach(item => {
            item.items = []
            AREA.find(i => {
                if (i.parent === item.id + '') {
                    item.items.push(i)
                }
                return null
            })
        })
        province.forEach(item => {
            item.items.forEach(i => {
                i.items = []
                AREA.find(n => {
                    if (n.parent === i.id + '') {
                        i.items.push(n)
                    }
                    return null
                })
            })
        })
    }

    close = () => {
        this.setState({ isShow: false })
    }

    checkTeam = (rule, value) => {
        if (!value || value.length === 0) {
            return Promise.reject('该项是必填项！')
        }
        if (value.length > 50) {
            return Promise.reject('长度不能超过50个字符！')
        }
        let reg = /^\S+$/
        if (!reg.test(value)){
            return Promise.reject('不允许输入空格')
        }
        this.setState({ teamName: value })
        return Promise.resolve()
    }

    provinceChange = (value, type) => {
        let arr = []
        AREA.find(item => {
            if (item.parent === value + '') {
                arr.push(item)
            }
            return null
        })
        type === 1 ? this.setState({ city: arr }) : this.setState({ county: arr })
    }

    changeTrue = value => {
        this.setState({ isTrue: value })
    }

    render() {
        const { banner, city, county, name_cn, name_en, email, isShow, teamName } = this.state
        return (
            <>
                <TopFormBg type={1} />
                <section className='innovate-con-form container'>
                    <p className='header-tip' dangerouslySetInnerHTML={{ __html: TIPS.innovation_contest_tips }}></p>
                    <h1>队伍信息/Team</h1>
                    <Form
                        {...layout}
                        className='innovate-form'
                        name="innovate"
                        onFinish={this.onFinish}
                        onFinishFailed={this.onFinishFailed}
                    >
                        {/* 负责老师姓名 */}
                        <Form.Item
                            className='double-input'
                            label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_ch_name }}></i>}
                        >
                            <div className="double-input-div">{name_cn}</div>
                        </Form.Item>
                        <Form.Item
                            {...noLabelLayout}
                        >
                            <div className="double-input-div">{name_en}</div>
                        </Form.Item>

                        {/* 负责老师邮箱 */}
                        <Form.Item
                            className='double-input'
                            label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_ch_email }}></i>}
                        >
                            <div className="double-input-div">{email}</div>
                        </Form.Item>

                        {/* 负责老师所在学校 */}
                        <Form.Item
                            className='double-input'
                            label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_ch_institution }}></i>}
                            name="instructor_ch_institution"
                            rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }]}
                        >
                            <Input placeholder='请输入负责老师所在学校' />
                        </Form.Item>
                        <Form.Item
                            {...noLabelLayout}
                            name="instructor_en_institution"
                            rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }, { validator: OnlyCH}]}
                        >
                            <Input placeholder="Instructor's Institution" />
                        </Form.Item>
                        <p className='btm-tip' dangerouslySetInnerHTML={{ __html: TIPS.ins_tip_institution }}></p>

                        {/* 负责老师所在学院 */}
                        <Form.Item
                            className='double-input'
                            label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_ch_department }}></i>}
                            name="instructor_ch_department"
                            rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }]}
                        >
                            <Input placeholder='请输入负责老师所在学院' />
                        </Form.Item>
                        <Form.Item
                            {...noLabelLayout}
                            name="instructor_en_department"
                            rules={[{ required: true, message: '该项是必填项！' }, { max: 50, message: '长度不能超过50个字符！' }, { validator: OnlyCH}]}
                        >
                            <Input placeholder="Instructor's Department" />
                        </Form.Item>
                        <p className='btm-tip' dangerouslySetInnerHTML={{ __html: TIPS.ins_tip_department }}></p>

                        {/* 队伍名称 */}
                        <Form.Item
                            className='double-input'
                            label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_team_name }}></i>}
                            name="instructor_team_name"
                            // rules={[{ required: true, message: '队伍名称不能为空！' }, { max: 20, message: '长度不能超过20个字符！' }]}
                            rules={[{ validator: this.checkTeam }, { validator: OnlyCH}]}
                        >
                            <Input placeholder='请输入队伍名称/Team Name' />
                        </Form.Item>
                        <p className='btm-tip' dangerouslySetInnerHTML={{ __html: TIPS.ins_tip_team_name }}></p>


                        {/* 队伍所在地区 */}
                        <Form.Item
                            label={<i dangerouslySetInnerHTML={{ __html: LABELS.ins_label_team_location }}></i>}
                        >
                            <Input.Group compact>
                                <Form.Item
                                    name={['location', 'province']}
                                    noStyle
                                    rules={[{ required: true, message: '请选择省份！' }]}
                                >
                                    <Select placeholder="省" onChange={(value, type) => { this.provinceChange(value, 1) }}>
                                        {
                                            province.map((item, index) => {
                                                return <Option key={index} value={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    name={['location', 'city']}
                                    noStyle
                                    rules={[{ required: true, message: '请选择市！' }]}
                                >
                                    <Select placeholder="市" onChange={(value, type) => { this.provinceChange(value, 2) }} >
                                        {
                                            city.map((item, index) => {
                                                return <Option key={index} value={item.id}>{item.name}</Option>
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                                {
                                    <Form.Item
                                        name={['location', 'area']}
                                        noStyle
                                        rules={[{ required: true, message: '请选择区县！' }]}
                                    >
                                        <Select placeholder="区县">
                                            {
                                                county.map((item, index) => {
                                                    return <Option key={index} value={item.id}>{item.name}</Option>
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                }
                            </Input.Group>
                        </Form.Item>

                        <Form.Item {...buttonLayout} className='button-box'>
                            <Button type="primary" htmlType="submit"> 提交 </Button>
                        </Form.Item>
                    </Form>
                </section>
                <SubmitModal isTrue={this.state.isTrue} text='队伍名称提交后不可修改，是否确认提交？' close={this.changeTrue} ok={this.submitForm} />
                <ModalSuccess isShow={this.state.isShow} backURL='/signup/innovate' text={`您团队的Wiki地址是${domainUrl}/wiki/2021/${teamName}/index.html，可到个人中心进行查看。我们将于报名整体截止后开放Wiki提交功能，请关注官方通知及邮箱获取最新消息。`} />
                <H5ModalSuccess isShow={this.state.h5IsShow} backURL='/signup/innovate' text={`您团队的Wiki地址是${domainUrl}/wiki/2021/${teamName}/index.html，可到个人中心进行查看。我们将于报名整体截止后开放Wiki提交功能，请关注官方通知及邮箱获取最新消息。`} />
            </>
        )
    }
}

export default Innovate