
import React, { Component } from 'react'
import Swiper from 'swiper'
import 'swiper/css/swiper.css'
import 'styles/comps/swiper-comp.scss'
class SwiperComp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            slideIndex: 0,
            list: [],
            num: 1, 
        }

    }
    componentDidMount() {
        this.instanceSwiper()
        this.axios.post('/notice').then(res => {
            if (res.code === 10000 ) {
                this.setState({ list: res.data })
            }
        })
    }

    instanceSwiper = () => {
        let that = this
        new Swiper('#SwiperComp', {
            autoplay: {// 自动滑动
                delay: 5000, //3秒切换一次
                // stopOnLastSlide: false,
                disableOnInteraction: false,//
            },
            // loop : true,
            observer: true,//修改swiper自己或子元素时，自动初始化swiper    重要
            observeParents: true,//修改swiper的父元素时，自动初始化swiper  重要
            paginationClickable: true,//点击分页器是否会切换
            autoplayDisableOnInteraction: false,//用户操作swiper之后，是否禁止autoplay
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            slidesPerView: 1.5, //设置slider容器能够同时显示的slides数量(carousel模式),loop='auto'模式下，还需要设置loopedSlides。
            // loopedSlides: 8, 
            spaceBetween: -120, //在slide之间设置距离（单位px）。
            centeredSlides: true, //设置活动块居中
            initialSlide: 1, // 默认选中项索引
            onInit: function (swiper) {//回调函数，初始化后执行。
                swiper.slides[2].className = "swiper-slide swiper-slide-active"//第一次打开不要动画
            },
            // pagination: {
            //     el: '.swiper-pagination',
            //     clickable: true,
            //     renderBullet: function (index, className) {
            //         return '<span class="' + className + '">' + (index + 1) + '</span>'
            //     },
            // },
            watchSlidesProgress : true,
            on:{
                slideChange: function(){
                    that.setState({
                        slideIndex: this.activeIndex - 2,
                        num: this.activeIndex
                    }, () => {
                        console.log('slideIndex', that.state.slideIndex)
                    })
                    console.log('改变了，activeIndex为' + this.activeIndex)
                },
            },
        })
    }

    render() {
        const {slideIndex, list, num} = this.state
        return (
            <div className="swiper-box">
                <div className="content">
                    <div className="swiper-container" id="SwiperComp">
                        <div className="swiper-wrapper">
                            {
                                list.map((item,index)=>{
                                    return <img key={index} src={item.img} alt="" className='swiper-slide' />
                                })
                            }
                        </div>
                        <div className="swiper-pagination"></div>
                    </div>
                    <div class="swiper-button-prev"></div>
                    <div class="swiper-button-next"></div>
                </div>
                <div className="intro" dangerouslySetInnerHTML={{__html: list[num] && list[num].content}}>
                </div>
            </div>
        )
    }
}
export default SwiperComp

