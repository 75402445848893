
import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import 'styles/login/login.scss'
class Dialog extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }

    }
    componentDidMount(){
    }

    handleOk = () => {

    }

    handleCancel = () => {
        this.props.closeDialog()
    }

    login = () => {
        window.location.href = '/login'
    }
    render() {
        const {isShow, type} = this.props
        return (
            <Modal  visible={isShow} onOk={this.handleOk} onCancel={this.handleCancel} closable={true} destroyOnClose footer={null} width={634}>
                <img src={require('assets/images/human.png')} alt='human' className='human' />
                <img src={require('assets/images/shanchu1.png')} alt='shanchu1' className='shanchu1' style={{float: 'right'}} onClick={()=>this.login()} />
                <div className='title'>{type === 'reset' ? '密码重置成功' : '恭喜您注册成功'}</div>
                {type === 'register' && <div className='title2'>请登录后到用户中心进行个人信息完善</div>}
                <Button type="primary" htmlType="submit" onClick={this.login}>{type === 'reset' ? '重新登录' : '前往登录'}</Button>
            </Modal>
        )
    }
}
export default Dialog

