
import React, { Component, useEffect, useState } from 'react'
import 'styles/signup/challenge-topics.scss'
import { Skeleton } from 'antd'
import PackUp from 'comps/PackUp'
import axios from 'libs/axios'
import className from 'classname'
import ChallengeTopicsItem from 'comps/signup/ChallengeTopicsItem'
import { getStorage, clearStorage } from 'utils/storage'
import { useSelector, useDispatch } from 'react-redux'
const ChallengeTopics = props => {
    const status = useSelector(state => state)
    // console.log('props', status)
    const [isPC, setIsPC] = useState(status.isPC)
    const [isShow, setIsShow] = useState(false)
    const [itemsId, setItemsId] = useState(props.match.params.title)
    const [challengeList, setChallengeList] = useState([])
    const [challengeItem, setChallengeitem] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        axios.post('/challenge').then(res => {
            if (res.code === 10000) {
                setChallengeList(res.data)
                let item = res.data.filter(item => item.title === itemsId)
                setChallengeitem(...item)
                setIsLoading(false)
            }
        })
        
        // return componentWillUnmount
    }, [itemsId])

    // const componentWillUnmount = () => {
    //     // 组件销毁时
    //     window.removeEventListener('resize',getPageClientWidth())
    // }
    // const getPageClientWidth = () => {
    //     setIsPC(window.innerWidth > 750 ? true : false)
    // }

    //赛道切换
    const navOnclick = title => {
        setItemsId(title)
        let item = challengeList.filter(item => item.title === title)
        setChallengeitem(...item)
        props.history.push({ pathname:'/signup/challenge/topics/' + title })
    }
    return (
        <>
            <Skeleton active loading={isLoading}>
                <div className="sign-up-challenge-topics">
                    <div className="sign-up-challenge-topics-content">
                        <div className="navlist">
                            {
                                challengeList.map(item => {
                                    return <div className={className(['item', {'item-active': item.title === itemsId}])} onClick={() => navOnclick(item.title)}>{item.title}</div>
                                })
                            }
                        </div>
                        <div className="topics-cont">
                            <div className="title">企业挑战-{itemsId}赛道</div>
                            <div className="intro">{challengeItem && challengeItem.summary}</div>
                            {
                                challengeItem && challengeItem.items && challengeItem.items.map((item, index) => {
                                    return <ChallengeTopicsItem topicsData={item} index={index + 1} status={challengeItem.status}></ChallengeTopicsItem>
                                })
                            }
                        </div>
                    </div>
               
                </div>
            </Skeleton>
        </>
    )
}

export default ChallengeTopics