import React from 'react'


class PayCode extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            data: {}
        }
    }
    componentDidMount () {
        // console.log('codeurl', this.getQueryString('code'), this.getQueryString('id'))
        let params = {
            id: this.getQueryString('id'),
            code: this.getQueryString('code'),
            type: 1,
            platform: 3
        }
        this.axios.post('/pay', params).then(res => {
            // console.log('paypay', res)
            if (res.code === 10000){
                // window.location.href = res.data
                let data = Object.assign({}, res.data)
                this.setState({data}, () => {
                    console.log('datadata', this.state.data)
                    if (typeof WeixinJSBridge === "undefined") {
                        if (document.addEventListener) {
                            document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(this.state.data), false)
                        } else if (document.attachEvent) {
                            document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(this.state.data))
                            document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(this.state.data))
                        }
                    } else {
                        this.onBridgeReady(this.state.data)
                    }
                })
            }
        })

        
    }

    getQueryString = name => {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
        var r = window.location.search.substr(1).match(reg)
        if (r != null) {return  unescape(r[2])} return null
    }

    onBridgeReady = params => {
        var ua = navigator.userAgent.toLowerCase()
        var isWeixin = ua.indexOf('micromessenger') !== -1
        if (isWeixin) {}
        
        // eslint-disable-next-line no-undef
        WeixinJSBridge.invoke('getBrandWCPayRequest', 
        // {
        //     "appId": "wx2421b1c4370ec43b",     //公众号ID，由商户传入     
        //     "timeStamp": "1395712654",     //时间戳，自1970年以来的秒数     
        //     "nonceStr": "e61463f8efa94090b1f366cccfbbb444",      //随机串     
        //     "package": "prepay_id=up_wx21201855730335ac86f8c43d1889123400",
        //     "signType": "RSA",     //微信签名方式：     
        //     "paySign": "oR9d8PuhnIc+YZ8cBHFCwfgpaK9gd7vaRvkYD7rthRAZ\/X+QBhcCYL21N7cHCTUxbQ+EAt6Uy+lwSN22f5YZvI45MLko8Pfso0jm46v5hqcVwrk6uddkGuT+Cdvu4WBqDzaDjnNa5UK3GfE1Wfl2gHxIIY5lLdUgWFts17D4WuolLLkiFZV+JSHMvH7eaLdT9N5GBovBwu5yYKUR7skR8Fu+LozcSqQixnlEZUfyE55feLOQTUYzLmR9pNtPbPsu6WVhbNHMS3Ss2+AehHvz+n64GDmXxbX++IOBvm2olHu3PsOUGRwhudhVf7UcGcunXt8cqNjKNqZLhLw4jq\/xDg==" //微信签名 
        // }
            params,
            res => {
                console.log('微信端支付res', res.err_msg)
                // // 使用以上方式判断前端返回,微信团队郑重提示：
                // //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
                if (res.err_msg === "get_brand_wcpay_request:ok") {
                    // alert("支付成功")
                    window.location.href = '/usercenter'
                    //支付成功后跳转的页面
                } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
                    // alert("支付取消")
                    window.location.href = '/usercenter'
                } else if (res.err_msg === "get_brand_wcpay_request:fail") {
                    // alert("支付失败")
                    window.location.href = '/usercenter'
                    // WeixinJSBridge.call("closeWindow");
                }
            })

    }
    
    render(){
        return  <div>
        </div>
        
    }
}
export default PayCode
