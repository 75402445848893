import React, { useState } from 'react'
import { Form,Button,Input,Upload,message,Space } from 'antd'
import LABELS from 'pages/json/labels.json'
import TIPS from 'pages/json/tips.json'
import { APIBaseURL,OnlyCH,UploadIcon,UploadFileError,MobileDelBr } from 'utils'
import { getStorage } from 'utils/storage'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'

const NoFundBaseInfo = props => {
    const [ form ] = Form.useForm()
    const [ fileLists, setFileLists ] = useState([])
    const [ fileEnLists, setFileEnLists ] = useState([])

    // 文件上传前
    const beforeUpload = file => {
        const isLt2M = file.size / 1024 / 1024 < 30
        if (!isLt2M) {
            message.error('文件大小不超过30M！')
        }
        return isLt2M
    }
    // 文件上传过程中
    const handleChange = (type,info) => {
        // 上传中
        if (info.file.status === 'uploading') {
            if (type === 'ch'){
                console.log([...info.fileList], 111)
                setFileLists([...info.fileList])
            } else {
                setFileEnLists([...info.fileList])
            }
        }
        // 删除文件
        if (info.file.status === 'removed'){
            if (type === 'ch'){
                setFileLists([])
                form.setFieldsValue({ ch_com_plan: ''})
                form.setFieldsValue({ ch_com_plan_name: ''})
            } else {
                setFileEnLists([])
                form.setFieldsValue({ en_com_plan: ''})
                form.setFieldsValue({ en_com_plan_name: ''})
            }
        }
        // 上传成功
        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.url,name = info.file.response.data.old_name
            const file = {name,url,status:'done',uid:url}
            if (type === 'ch'){
                setFileLists([file])
                form.setFieldsValue({ ch_com_plan: url})
                form.setFieldsValue({ ch_com_plan_name: name})
            } else {
                setFileEnLists([file])
                form.setFieldsValue({ en_com_plan: url})
                form.setFieldsValue({ en_com_plan_name: name})
            }
        } else if (info.file.status === 'done' && info.file.response.code !== 10000){
            UploadFileError(info.file.response)
            const file = { name:info.file.name,url:'',status:'error',uid:info.file.uid }
            if (type === 'ch'){
                setFileLists([file])
                form.setFieldsValue({ ch_com_plan: ''})
                form.setFieldsValue({ ch_com_plan_name: ''})
            } else {
                setFileEnLists([file])
                form.setFieldsValue({ en_com_plan: ''})
                form.setFieldsValue({ en_com_plan_name: ''})
            }
        }
    }
    // 上传配置项
    const uploadProps = {
        accept:'.pdf,.ppt,.pptx,application/pdf',
        listType:'file',
        maxCount:1,
        action: `${APIBaseURL}upload`,
        headers: { token: getStorage('token') },
        beforeUpload,
    }
    MobileDelBr(LABELS)
    return <Form form={form} {...layout} autoComplete="off" className='innovate-form busi-form' 
        name='basicForm' 
        onFinish={props.onSubmit}
    >
        {/* 队伍名称 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_ch_teamname}}></i>}
            name="ch_team_name"
            rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:200,message:'长度不能超过200个字符！'}]}
        >
            <Input placeholder='请输入队伍名称'/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_team_name"
            rules={[ { required: true, message: '该项是必填项！'},
                { type:'string',max:200,message:'长度不能超过200个字符！'},
                { validator:OnlyCH }]}
        >
            <Input placeholder="Team Name"/>
        </Form.Item>
        <span className="btm-tip basic-btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_tean_name}}></span>

        {/* 公司商业计划书 */}
        <h2 className='file-h2'>公司商业计划书/Business Plan：</h2>
        <Form.Item {...noLabelLayout}>
            <Space align="start">
                <Form.Item name="ch_com_plan_name" > <Input hidden/> </Form.Item>
                <Form.Item name="en_com_plan_name" > <Input hidden/> </Form.Item>
                <Form.Item name="ch_com_plan"
                    rules={[ { required: true, message: '必须上传一个文件'}]}
                >
                    <Upload {...uploadProps}  fileList={fileLists} onChange={ handleChange.bind(this,'ch')}>
                        {UploadIcon('ch', '必选')}
                    </Upload>
                    {/* 隐藏的file地址 */}
                    <Input hidden/>
                </Form.Item>
                
                <Form.Item name="en_com_plan"
                    // rules={[ { required: true, message: '必须上传一个文件'}]}
                >
                    <Upload {...uploadProps} fileList={fileEnLists} onChange={ handleChange.bind(this,'en')}>
                        {UploadIcon('en', 'Optional')}
                    </Upload>
                    <Input hidden/>
                </Form.Item>
            </Space>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_busi_plan}}></span>
        <Form.Item {...buttonLayout} className='button-box'>
            <Button type="primary" htmlType='submit'> 下一步 </Button>
        </Form.Item>

    </Form>
}

export default NoFundBaseInfo