
import { Error500,Error404,Error403 } from 'pages/error'


const PublicRoutes = [
    {
        path:'/error500',
        component:Error500,
        exact:true
    },{
        path:'/error404',
        component:Error404,
        exact:true
    },{
        path:'/error403',
        component:Error403,
        exact:true
    }
]

export default PublicRoutes