import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Modal, Select, Form, Button } from "antd"
import "styles/user/teaminvite.scss"
class TeamInvite extends Component {
    constructor(props) {
        super(props)
        this.state = {
            
        }
    }
    componentDidMount() {}
    handleCancel = () => {
        this.props.closeDialog()
    }
  handleChange = value => {
      console.log(`selected ${value}`)
  };

  //接受邀请
  agreeTeamInvite = () => {
      this.axios.post('/teamInvite/agreeTeamInvite', {invite_id: this.props.selectItem.invite_log_id}).then(res => {
          if (res.code === 10000) {
              this.props.closeDialog()
          }
      })
  }

  //拒绝邀请
  refuseTeamInvite = () => {
      this.axios.post('/teamInvite/refuseTeamInvite', {invite_id: this.props.selectItem.invite_log_id}).then(res => {
          if (res.code === 10000) {
              this.props.closeDialog()
          }
      })
  }
  render() {
      const { isShowTeamInvite, selectItem } = this.props
      return (
          <Modal
              visible={isShowTeamInvite}
              destroyOnClose
              footer={null}
              className="teaminvite"
              centered={true}
              onCancel={this.handleCancel}
          >
              <div className="title">{selectItem.title}</div>
              <Form
                  name="basic"
                  autoComplete="off"
              >
                  <div className="info-content">
                      {/* <div>xxx（邀请人名字）邀请您加入xxxx（队伍名称）队伍，是否接受邀请？</div> 
                      <div>如有任何疑问，请联系xxx（邀请人名字）</div>
                      <div>邮箱：xxxxxxxxxxxx@xxx.com（邀请人邮箱）</div> */}
                      {selectItem.content}
                  </div>
                  {
                      selectItem.btn_type === 0 ? null : <Form.Item>
                          {selectItem.btn_type === 1 ? <div className="btns">
                              <Button type="primary" htmlType="submit" className="btn" onClick={this.agreeTeamInvite}>接受邀请</Button>
                              <Button htmlType="button" className="btn" onClick={this.refuseTeamInvite}>拒绝邀请</Button>
                          </div> : <div className="btns">
                              <Button htmlType="button" className="btn" >{selectItem.btn_type === 2 ? '已同意' : '已拒绝'}</Button>
                          </div>}
                      </Form.Item>
                  }
                      
              </Form>
          </Modal>
      )
  }
}
export default withRouter(TeamInvite)
