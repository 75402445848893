import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import 'styles/login/login.scss'
const H5ModalSuccess = props =>{
    const { isShow, text } = props
    return (
        <Modal visible={isShow} destroyOnClose footer={null} className='move-modal-tip' centered={true}>
            <div className="title">恭喜您报名信息提交成功</div>
            <div className="title2">{text}</div>
            <Button className='h5-btn' type='primary' htmlType='submit' href='/usercenter'>进入用户中心</Button>
        </Modal>
    )
}

export default H5ModalSuccess