import { Carousel } from 'antd'
import axios from 'libs/axios'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

function Test2() {
    let history = useHistory()
    const [list, setList] = useState([])
    const onChange = (a, b, c) => {
        console.log(a, b, c)
    }
    useEffect(()=>{
        axios.post('/banner', { type: 1 }).then(res => {
            if (res.code === 10000) {
                setList(res.data)
            }
        })
    },[])
    const contentStyle = {
        // height: '178px',
        width: '100%'
    }
    return <Carousel afterChange={onChange}>
        {
            list.map((item,index)=>{
                return <div key={index}>
                    <img src={item.img} alt="" style={contentStyle} onClick={()=>window.location.href = item.url} />
                </div>
            })
        }
    </Carousel>
}

export default Test2