
import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import 'styles/comps/footer.scss'
class Footer extends Component {
    constructor(props) {
        super(props)
        this.state = {
        
        }
    }

    componentDidMount(){
    }

    toAgreement = () => {
        this.props.history.push({pathname:'/agreement', state:{type: 'privacy'}})
    }
    
    render() {
        return (
            <div className="footer-container">
                <div className="content">
                    <div className="conleft">
                        <img src={require('assets/images/logo.png')} alt='logo'/>
                    </div>
                    <div className="conright">
                        {
                            window.innerWidth > 750 ? <div className="list">
                                <div className="item">
                                    {/* <img src={require('assets/images/lianxiwomen-2.png')} alt=""/> */}
                                    <div>联系我们：</div><div><div>info@synbiochallenges.com（创新大赛）</div><div>info@synbiohive.com（创业大赛）</div></div>
                                </div>
                                <div className="item">
                                    {/* <img src={require('assets/images/ziyuan.png')} alt=""/> */}
                                    <div>寻求报道：</div><div><div>info@synbiochallenges.com（创新大赛）</div><div>info@synbiohive.com（创业大赛）</div></div>
                                </div>
                                <div className="item">
                                    {/* <img src={require('assets/images/shangwuhezuo.png')} alt=""/> */}
                                    <div>商务合作：</div><div>marketing@synbiohive.com</div>
                                </div>
                                <div className="item privacy" onClick={this.toAgreement}>
                                    {/* <img src={require('assets/images/yinsi-2.png')} alt=""/> */}
                            隐私政策
                                </div>
                            </div> : <div className="list">
                                <div className="item"><div>联系我们/寻求报道</div><div>info@synbiochallenges.com（创新大赛）</div><div>info@synbiohive.com（创业大赛）</div></div>
                                <div className="item">
                                    <div>商务合作：</div><div>marketing@synbiohive.com</div>
                                </div>
                            </div>
                        }
                        
                    </div>
                    
                </div>
                <div className="btom">
                    {/* <div>京公网安备 342342343434号</div> */}
                    {
                        window.location.href.includes('http://www.synbiochallenges.com/') ? <div><a href='https://beian.miit.gov.cn/#/Integrated/index'>粤ICP备18059912号</a></div> : 
                            <div><a href='https://beian.miit.gov.cn/#/Integrated/index'>京ICP备16049925号-16</a><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502044713'><img src={require('assets/images/police.png')} alt="police" />京公网安备 11010502044713号</a></div>
                    }
                    {/* <div><a href='https://beian.miit.gov.cn/#/Integrated/index'>京ICP备16049925号-16</a><a href='http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502044713'><img src={require('assets/images/police.png')} alt="police" />京公网安备 11010502044713号</a></div> */}
                </div>
            </div>
        )
    }
}
export default withRouter(Footer)

