import React from 'react'
import { Modal, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import 'styles/login/login.scss'

const ModalSuccess = props => {
    const { isShow, text,backURL } = props 
    let history = useHistory()
    const ModalOnCancel = () => {
        // 回退报名展示页面
        history.push(backURL)
    }
    return (
        <Modal visible={isShow} destroyOnClose footer={null} className='modal-tip' centered={true}>
            <img src={require('assets/images/human2.png')} alt='human' className='human'/>
            <img src={require('assets/images/x.png')} alt="shanchu" className='shanchu' onClick={ModalOnCancel}/>
            <div className='title'>恭喜您报名信息提交成功</div>
            <div className='title2'>{text}</div>
            <Button type="primary" htmlType="submit" href='/usercenter'>进入用户中心</Button>
        </Modal>
    )
}

export default ModalSuccess

