import React, { Component } from 'react'
import { Tabs, Row, Col, Form, Input, Button, Checkbox } from 'antd'
import 'styles/login/login.scss'
import { setStorage } from 'utils/storage'
import { Msg } from 'utils'

class Login extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            tabKey: 1,
            seconds: 60,
            isDisabled: false,
            dlgTipTxt: '发送验证码'
        }

    }
    componentDidMount() {
    }

    //tab切换
    callback = key => {
        this.setState({
            tabKey: key
        })
    }

    onFinish = values => {
        const { tabKey } = this.state
        if (parseInt(tabKey) === 1) {
            this.axios.post('/login', { mobile: values.username, password: values.password }).then(res => {
                if (res.code === 10000) {
                    setStorage('token', res.data.token)
                    this.props.history.push('/home')
                }
            })
        } else if (parseInt(tabKey) === 2) {
            this.axios.post('/login', { mobile: values.username, code: values.code }).then(res => {
                if (res.code === 10000) {
                    setStorage('token', res.data.token)
                    this.props.history.push('/home')
                } else if (res.code === 20009){
                    // console.log(874926)
                    Msg('warning', '该号码未注册，请先注册新账号')
                }
            })
        }
    }

    onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    };

    //发送验证码
    sendCode = () => {
        console.log('发送验证码')
        this.formRef.current.validateFields(['username']).then(value => {
            this.axios.post('/code', { mobile: this.formRef.current.getFieldValue('username'), type: 3 }).then(res => {
                if (res.code === 10000) {
                    Msg('success', '短信验证码已发出！')
                    this.setState({ isDisabled: false })
                    // 验证码倒计时
                    let siv = setInterval(() => {
                        this.setState(preState => ({
                            seconds: preState.seconds - 1,
                            dlgTipTxt: `${preState.seconds - 1}s`
                        }), () => {
                            if (this.state.seconds === 0) {
                                clearInterval(siv)
                                this.setState({
                                    isDisabled: false, dlgTipTxt: "获取验证码", seconds: 60
                                })
                            }
                        })
                    }, 1000)
                }
            })
        }).catch(error => {
            console.log(error, 'error')
        })
    }
    // username
    checkUserValue = (rule, value) => {
        const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
        if (reg.test(value) && value.length === 11) {
            return Promise.resolve()
        }
        if (!value || value.length === 0) {
            return Promise.reject('手机号码不能为空！')
        }
        return Promise.reject('手机号码格式错误！')
    }
    // pwd1
    checkPwd1 = (rule, value) => {
        const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,12}$/
        if (reg.test(value)) {
            return Promise.resolve()
        }
        return Promise.reject('长度为6-12个字符，需含有字母大写、字母小写、数字组合，不允许有空格、中文！')
    }

    render() {
        const { TabPane } = Tabs
        const { tabKey, seconds, isDisabled, dlgTipTxt } = this.state
        return (
            <>
                <div className='content-box'>
                    <div className="login">
                        <div className="logo">
                            <img src={require('assets/images/logo.png')} alt='logo' />
                        </div>
                        {/* <div className="logo-h5">
                            <img src={require('assets/images/h5-mainlogo.png')} alt=""/>
                        </div> */}
                        <Tabs defaultActiveKey="1" onChange={this.callback} >
                            <TabPane tab="账号登录" key="1">
                                <Form
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    validateTrigger="onBlur"
                                >
                                    <Form.Item
                                        name="username"
                                        className='user'
                                        rules={[{ validator: this.checkUserValue }]}
                                    >
                                        <Input autocomplete="off" prefix={<img src={require('assets/images/shouji.png')} alt='shouji' />} placeholder='请输入登录账号' />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        className='pwd'
                                        rules={[{ validator: this.checkPwd1 }]}
                                    >
                                        <Input.Password prefix={<img src={require('assets/images/mima1.png')} alt='mima1' />} placeholder='请输入登录密码' />
                                    </Form.Item>

                                    <Form.Item >
                                        <Button type="primary" htmlType="submit">登录</Button>
                                    </Form.Item>
                                </Form>
                                <a href='/reset' className='forgetpwd'>忘记密码?</a>
                                <a href='/register' className='register'>注册新账号</a>
                            </TabPane>
                            <TabPane tab="短信登录" key="2">
                                <Form
                                    ref={this.formRef}
                                    name="basic"
                                    initialValues={{ remember: true }}
                                    onFinish={this.onFinish}
                                    onFinishFailed={this.onFinishFailed}
                                    validateTrigger="onBlur"
                                >
                                    <Form.Item
                                        name="username"
                                        className='user'
                                        rules={[{ validator: this.checkUserValue }]}
                                    >
                                        <Input autocomplete="off" prefix={<img src={require('assets/images/shouji.png')} alt='shouji' />} placeholder='请输入手机号码' />
                                    </Form.Item>

                                    <Form.Item
                                        name="code"
                                        className='pwd'
                                        rules={[{ required: true, message: '短信验证码不能为空!' }]}
                                    >
                                        <Input prefix={<img src={require('assets/images/mima1.png')} alt='mima1' />} suffix={<div className='sendcode' disabled={isDisabled} onClick={this.sendCode}>{dlgTipTxt}</div>} placeholder='请输入验证码' />
                                    </Form.Item>

                                    <Form.Item >
                                        <Button type="primary" htmlType="submit">登录</Button>
                                    </Form.Item>
                                </Form>
                                <a href='/reset' className='forgetpwd'>忘记密码?</a>
                                <a href='/register' className='register'>注册新账号</a>
                            </TabPane>
                        </Tabs>
                        <img src={require('assets/images/shanchu1.png')} alt='shanchu1' className='cancel' onClick={() => this.props.history.go(-1)} />
                    </div>
                </div>
            </>
        )
    }
}
export default Login

