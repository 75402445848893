
import React, { Component } from 'react'
import { Modal, Button, Skeleton } from 'antd'
import PackUp from 'comps/PackUp'
import Dialog2 from 'comps/signup/Dialog2'
import { getStorage, clearStorage } from 'utils/storage'
import 'styles/signup/innovate.scss'
import { connect } from 'react-redux'
import { getClientWidth } from '@redux/ispc/action'
@connect(state => state, { getClientWidth })

class Innovate extends Component {
    constructor(props) {
        super(props)
        this.state = {
            info:{},
            typeData: [],
            awardsData: [],
            isShow: false, //是否展示弹框
            isUserCheck: true, //是否信息完善
            typeDataH5: [],
            isLoading: true,
            // isPC: window.innerWidth >= 750 ? true : false,
        }
    }

    componentDidMount(){
        this.getGameIntro()
        this.getAwards()
        // window.addEventListener('resize', this.getPageClientWidth)
    }

    componentWillUnmount(){
        // window.removeEventListener('resize',this.getPageClientWidth)
    }

    getPageClientWidth = () => {
        this.setState({
            isPC: window.innerWidth > 750 ? true : false
        })
    }
    //大赛介绍
    getGameIntro = () => {
        this.axios.post('/game', { type: 1 }).then(res => {
            if (res.code === 10000) {
                let oddArr = [], evenArr = []
                res.data.items.forEach((item, index) => {
                    if (index % 2){
                        oddArr.push(item)
                    } else {
                        evenArr.push(item)
                    }
                })
                let typeDataH5 = this.state.typeDataH5
                typeDataH5.push(...evenArr, ...oddArr)
                this.setState({
                    info: res.data.info,
                    typeData: res.data.items,
                    typeDataH5,
                    isLoading: false
                })
            }
        })
    }

    //奖项
    getAwards = () => {
        this.axios.post('/game/awards', { type: 1 }).then(res => {
            if (res.code === 10000 ) {
                this.setState({
                    awardsData: res.data,
                })
            } 
        })
    }
    
    //点击报名按钮
    goToSignUp = () => {
        //已经登录查看是否信息完善---->信息完善---->是否报名过
        getStorage('token') ? this.checkUser() : window.location.href = '/login'
    }

    //是否信息完善
    checkUser = () => {
        this.axios.post('/user/check').then(res => {
            // console.log('是否信息完善', res)
            if (res.code === 10000){
                if (res.data){
                    this.checkSignUp()
                } else {
                    // window.location.href = '/usercenter'
                    this.setState({
                        isShow: true,
                        isUserCheck: false
                    })
                }
            }
        })
    }
  
    //是否报名过
    checkSignUp = () => {
        let url = '/signup/innovate/innovateform'
        var date = new Date()
        var year = date.getFullYear()

        this.axios.post('/check', { type: 1, date: year }).then(res => {
            // console.log('是否报名过', res)
            if (res.code === 10000) {
                if (res.data === 0){
                    window.location.href = url
                } else {
                    this.setState({
                        isShow: true,
                    })
                }
            }
        })
    }

    //关闭弹框
    closeDialog = () => {
        this.setState({
            isShow:false
        })
    }

    render() {
        const { info, typeData, awardsData, isShow, isUserCheck, typeDataH5, isLoading } = this.state
        const {isPC} = this.props
        return (
            <>
                <Skeleton loading={isLoading} active>
                    {
                        isPC ? <div className="innovate-container">
                            <div className="innovate-sign-up-content">
                                <div className="signup-top">
                                    <div className="signup-content-box">
                                        <div className="content">
                                            <div className="title">{info.title}</div>
                                            <div className="entitle">{info.title_en}</div>
                                            <div className="intro">{info.summary}</div>
                                            <div className='btnsbox'>
                                                {info.is_run === 1 ? <div className="btn" onClick={this.goToSignUp}>进入报名</div> : null}
                                                {info.last_url ? <a href={info.last_url} target="_blank" rel="noopener noreferrer"><div className="btn" >决赛</div></a> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="timetable">
                                    <div className="content">
                                        <div className="title"><div className="bor"></div><span>时间表</span></div>
                                        <img src={info.pc_timeline} alt="" className='timeimg'/>
                                    </div>
                                </div>
                                {
                                    <div className={typeData.length % 4 &&  typeData.length % 4 <= 2 ? "gametype" + typeData.length % 4 : "gametype"}>
                                        {
                                            typeData.map((item, index) => {
                                                return <dl key={item.title} className={index % 4 === 0 ? 'espe' : ''} >
                                                    <dt><img src={item.img} alt=""/></dt>
                                                    <div>
                                                        <dd className="title">{item.title}</dd>
                                                        <dd className="intro" title={item.summary}>{item.summary}</dd>
                                                    </div>
                                                </dl>
                                            })
                                        }
                                    </div>
                                }
                                <div className="guid">
                                    <div className="guid-content">
                                        <img src={require('assets/images/in-juxing1.png')} alt="" className="juxing"/>
                                        <div className="guidlist">
                                            <div className="maintitle">报名指南</div>
                                            {info.guide && <PackUp maxHeight={590} introduce={info.guide}></PackUp>}
                               
                                        </div>
                                    </div>
                                    <img src={require('assets/images/in-guid.png')} alt="" className="guid-bg"/>
                                </div>
                                <div className="judge">
                                    <div className="guid-content">
                                        <img src={require('assets/images/in-juxing2.png')} alt="" className="juxing"/>
                                        <div className="guidlist">
                                            <div className="maintitle">评审标准</div>
                                            {
                                                info.standard && <PackUp maxHeight={590} introduce={info.standard}></PackUp>
                                            }
                                
                                        </div>
                                    </div>
                                </div>
                                <div className="awards">
                                    <div className="guid-content">
                                        <img src={require('assets/images/in-juxing2.png')} alt="" className="juxing"/>
                                        <div className="guidlist">
                                            <div className="maintitle">奖项</div>
                                            <div className="awards-content">
                                                {
                                                    awardsData.map((item, index) => {
                                                        return <div className="item" key={index}>
                                                            <div className="iconbg">
                                                                <img src={item.back_img} alt=""  className="duobianx"/>
                                                                <div className="awardicon">
                                                                    <img src={item.img} alt=""  className="litileicon"/> 
                                                                </div>
                                                            </div>
                                                            <div className="title">{item.title}</div>
                                                            <div className="intro">{item.summary}</div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <img src={require('assets/images/in-awardsbg.png')} alt="" className="awardsbg"/>
                                </div>
                            </div>
                            {/* <Dialog2 isShow={isShow} isUserCheck={isUserCheck} closeDialog={this.closeDialog} isPC={isPC}></Dialog2> */}
                        </div> : <div className="innovate-container-h5">
                            <div className="innovate-sign-up-content">
                                <div className="signup-top">
                                    <div className="signup-content-box">
                                        <div className="content">
                                            <div className="title">{info.title}</div>
                                            <div className="entitle">{info.title_en}</div>
                                            <div className="intro">{info.summary}</div>
                                            <div className='btnsbox'>
                                                {info.is_run === 1 ? <div className="btn" onClick={this.goToSignUp}>进入报名</div> : null}
                                                {info.last_url ? <a href={info.last_url} target="_blank" rel="noopener noreferrer"><div className="btn" >决赛</div></a> : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="timetable">
                                    <div className="content">
                                        <div className="title"><span>时间表</span><div className="bor"></div></div>
                                        <img src={info.m_timeline} alt="" className='timeimg'/>
                                    </div>
                                </div>
                                {
                                    <div className="gametype">
                                        {
                                            typeDataH5.map((item, index) => {
                                                return <div className='gametype-item'>
                                                    <dl key={item.title}>
                                                        <dt><img src={item.img} alt=""/></dt>
                                                        <div>
                                                            <dd className="title">{item.title}</dd>
                                                            <dd className="intro" title={item.summary}><div className='introbox'>{item.summary}</div></dd>
                                                        </div>
                                                    </dl>
                                                </div>
                                            })
                                        }
                                    </div>
                                }
                                <div className="guid">
                                    <div className="guid-content">
                                        <img src={require('assets/images/h5-in-juxing1.png')} alt="" className="juxing"/>
                                        <div className="guidlist">
                                            <div className="maintitle">报名指南</div>
                                            {info.guide && <PackUp maxHeight={590} introduce={info.guide}></PackUp>}
                               
                                        </div>
                                    </div>
                                </div>
                                <div className="judge">
                                    <div className="guid-content">
                                        <img src={require('assets/images/in-juxing2.png')} alt="" className="juxing"/>
                                        <div className="guidlist">
                                            <div className="maintitle">评审标准</div>
                                            {
                                                info.standard && <PackUp maxHeight={590} introduce={info.standard}></PackUp>
                                            }
                                
                                        </div>
                                    </div>
                                </div>
                                <div className="awards">
                                    <div className="guid-content">
                                        <img src={require('assets/images/in-juxing2.png')} alt="" className="juxing"/>
                                        <div className="guidlist">
                                            <div className="maintitle">奖项</div>
                                            <div className="awards-content">
                                                {
                                                    awardsData.map((item, index) => {
                                                        return <div className="item">
                                                            <div className="iconbg">
                                                                <img src={item.back_img} alt=""  className="duobianx"/>
                                                                <div className="awardicon">
                                                                    <img src={item.img} alt=""  className="litileicon"/> 
                                                                </div>
                                                            </div>
                                                            <div className="title">{item.title}</div>
                                                            <div className="intro">{item.summary}</div>
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    } 
                    <Dialog2 isShow={isShow} isUserCheck={isUserCheck} closeDialog={this.closeDialog} isPC={isPC}></Dialog2>
                </Skeleton>
                
            </>
        )
    }
}
export default Innovate

