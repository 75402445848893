/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import Nav from 'comps/Nav'
import Footer from 'comps/Footer'
import Navh5 from '../components/Navh5'
import { connect } from 'react-redux'
import { getClientWidth } from '@redux/ispc/action'
@connect(state => state, { getClientWidth })

class WebLayout extends React.Component {
    static propTypes = {
    	children: PropTypes.node
    }
    constructor(props) {
        super(props)
        this.state = {
            isPC: window.innerWidth >= 750 ? true : false,
        }
    }
    componentDidMount(){
        this.getPageClientWidth()
        window.addEventListener('resize', this.getPageClientWidth)
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.getPageClientWidth)
    }

    getPageClientWidth = () => {
        this.setState({
            isPC: window.innerWidth > 750 ? true : false
        })
        let status = window.innerWidth >= 750 ? true : false
        this.props.getClientWidth(status)
    }
    // 跳转之后重新回到顶端 router4.x的坑
    componentDidUpdate(prevProps) {
    	if (this.props.location.pathname !== prevProps.location.pathname) {
    		window.scrollTo(0, 0)
    	}
    }
    render() {
        const { pathname } = this.props.location
        const {isPC} = this.state
        // console.log(pathname, 'pathname')
    	return (
    		<Layout className='app-container'>
                {
                    pathname === '/login' || pathname === '/reset' || pathname === '/register' || pathname === '/pay/weixin'  || (pathname === '/news' && !isPC) || (pathname === '/notice' && !isPC) || (pathname === '/video' && !isPC) ? 
                        <>
                            <>
                                {
                                    isPC ? <Nav/> : <Navh5/>
                                }
                            </>
                            {
                                isPC ? <div className='pc-container-box'>{this.props.children}</div> : this.props.children
                            }
                        </>
                        :
                        <>
                            {
                                isPC ? <Nav/> : <Navh5/>
                            }
                            {
                                isPC ? <div className='pc-container-box'>{this.props.children}
                                    <Footer/></div> : <>{this.props.children}
                                    <Footer/></>
                            }
                        </>
                }
    		</Layout>
    	)
    }
}

export default WebLayout
