import React,{ useEffect, useState } from 'react'
import { Form,Input,Upload,message,Button } from 'antd'
import LABELS from 'pages/json/labels.json'
import TIPS from 'pages/json/tips.json'
import className from 'classname'
import { getStorage } from 'utils/storage'
import { APIBaseURL, OnlyCH,UploadIcon,UploadFileError,MobileDelBr } from 'utils'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'
import axios from 'libs/axios'

const { TextArea } = Input

const FundTeam = props => {
    const [ form ] = Form.useForm()
    const [ fileLists, setFileLists ] = useState([])
    const [ fileEnLists, setFileEnLists ] = useState([])
    const {isChange} = props
    
    const beforeUpload = file => {
        const isLt2M = file.size / 1024 / 1024 < 20
        if (!isLt2M) {
            message.error('文件大小不超过20M！')
        }
        return isLt2M
    }
    // 文件上传过程中
    const handleChange = (type,info) => {
        // 上传中
        if (info.file.status === 'uploading') {
            if (type === 'ch'){
                setFileLists([...info.fileList])
            } else {
                setFileEnLists([...info.fileList])
            }
        }
        // 删除文件
        if (info.file.status === 'removed'){
            if (type === 'ch'){
                setFileLists([])
                form.setFieldsValue({ fun_company_cvs: ''})
                form.setFieldsValue({ fun_company_cvs_name: ''})
            } else {
                setFileEnLists([])
                form.setFieldsValue({ en_fun_company_cvs: ''})
                form.setFieldsValue({ en_fun_company_cvs_name: ''})
            }
        }
        // 上传成功
        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.url,name = info.file.response.data.old_name
            const file = {name,url,status:'done',uid:url}
            // setFileLists([file])
            // form.setFieldsValue({ fun_company_cvs: url})
            // form.setFieldsValue({ fun_company_cvs_name: name})
            if (type === 'ch'){
                setFileLists([file])
                form.setFieldsValue({ fun_company_cvs: url})
                form.setFieldsValue({ fun_company_cvs_name: name})
            } else {
                console.log('name', name)
                setFileEnLists([file])
                form.setFieldsValue({ en_fun_company_cvs: url})
                form.setFieldsValue({ en_fun_company_cvs_name: name})
            }
        } else if (info.file.status === 'done' && info.file.response.code !== 10000) {
            UploadFileError(info.file.response)
            const file = { name:info.file.name,url:'',status:'error',uid:info.file.uid }
            // setFileLists([file])
            // form.setFieldsValue({ fun_company_cvs: ''})
            // form.setFieldsValue({ fun_company_cvs_name: ''})
            if (type === 'ch'){
                setFileLists([file])
                form.setFieldsValue({ fun_company_cvs: ''})
                form.setFieldsValue({ fun_company_cvs_name: ''})
            } else {
                setFileEnLists([file])
                form.setFieldsValue({ en_fun_company_cvs: ''})
                form.setFieldsValue({ en_fun_company_cvs_name: ''})
            }
        }
    }

    // 提交失败
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        if (errorFields.length > 0) {message.warning('基本信息不完整！')}
    }
    // 上传配置项
    const uploadProps = {
        // accept: '.rar,application/x-zip-compressed',
        accept: '.rar,.zip,.tar,.tar.gz,application/x-zip-compressed, application/x-tar, application/x-gzip, application/octet-stream',
        listType: 'file',
        maxCount: 1,
        action: `${APIBaseURL}/upload`,
        headers: { token: getStorage('token') },
        beforeUpload,
    }
    useEffect(()=>{
        axios.post('/game/get', { id: props.match.params.id }).then(res => {
            if (res.code === 10000) {
                console.log('res', res.data)
                form.setFieldsValue({...res.data.team})
                const fileCH = { name: res.data.team.fun_company_cvs_name, url: res.data.team.fun_company_cvs, status: 'done', uid: 1}
                const fileEN = { name: res.data.team.en_fun_company_cvs_name, url: res.data.team.en_fun_company_cvs, status: 'done', uid: 2}
                setFileLists([fileCH])
                setFileEnLists(res.data.team.en_fun_company_cvs_name ? [fileEN] : [])
            }
        })
    },[form, props.match.params.id])
    MobileDelBr(LABELS)
    return <Form {...layout}
        className='innovate-form busi-form'
        name="company-info"
        form={form}
        onFinish={props.onSubmit}
        onFinishFailed={onFinishFailed}
    >
        {/* 管理团队情况 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.fund_label_team_management}}></i>}
            name="ch_partner"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入管理团队情况' autoSize = {{ minRows: 4, maxRows: 4 }} readOnly={!isChange} className={className([isChange && 'changemess'])}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_partner"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 20000, message: '长度不能超过20000个字符！' },
                { validator: OnlyCH}]}
        >
            <TextArea placeholder='(Optional) Enter management team' autoSize = {{ minRows: 4, maxRows: 4 }} readOnly={!isChange} className={className([isChange && 'changemess'])}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.fund_tip_team_management}}></span>

        {/* 研发团队情况 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.fund_label_team_RD}}></i>}
            name="ch_award"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入研发团队情况' autoSize = {{ minRows: 4, maxRows: 4 }} readOnly={!isChange} className={className([isChange && 'changemess'])}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_award"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 20000, message: '长度不能超过20000个字符！' }, 
                { validator: OnlyCH}
            ]}
        >
            <TextArea placeholder='(Optional) Enter R&D team' autoSize = {{ minRows: 4, maxRows: 4 }} readOnly={!isChange} className={className([isChange && 'changemess'])}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.fund_tip_team_RD}}></span>

        {/* 销售团队情况 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{__html:LABELS.fund_label_team_sales}}></i>}
            name="ch_develpment"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入销售团队情况' autoSize = {{ minRows: 4, maxRows: 4 }} readOnly={!isChange} className={className([isChange && 'changemess'])}/>
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_develpment"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 20000, message: '长度不能超过20000个字符！' }, 
                { validator: OnlyCH}
            ]}
        >
            <TextArea placeholder='(Optional) Enter sales team' autoSize = {{ minRows: 4, maxRows: 4 }} readOnly={!isChange} className={className([isChange && 'changemess'])}/>
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.fund_tip_team_sales}}></span>

        {/* 团队成员简历/Team Members' CVs： */}
        <h2 className='file-h2'>团队成员简历/Team Members' CVs：</h2>
        <Form.Item name="fun_company_cvs_name" > <Input hidden/> </Form.Item>
        <Form.Item name="en_fun_company_cvs_name" > <Input hidden/> </Form.Item>
        <div className="upload-box">
            <Form.Item
                name="fun_company_cvs"
                rules={[{ required: true, message: '必须上传一个文件' }]}
            >
                <Upload {...uploadProps} fileList={fileLists} onChange={handleChange.bind(this, 'ch')} disabled={!isChange}>
                    {UploadIcon('ch','必选')}
                </Upload>
                {/* 隐藏的file地址 */}
                <Input hidden />
            </Form.Item>
            <Form.Item
                name="en_fun_company_cvs"
                // rules={[{ required: true, message: '必须上传一个文件' }]}
            >
                <Upload {...uploadProps} fileList={fileEnLists} onChange={handleChange.bind(this, 'en')} disabled={!isChange}>
                    {UploadIcon('en', 'Optional')}
                </Upload>
                <Input hidden />
            </Form.Item>
        </div>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_team_CVs }}></span>
        <Form.Item {...buttonLayout} className='button-box'>
            {
                isChange && <Button type="primary" htmlType='submit' > 保存 </Button>
            }
        </Form.Item>
    </Form>
}
export default FundTeam