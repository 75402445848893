import React, { Component } from "react"
import { Link, withRouter } from "react-router-dom"
import { Modal, Select, Form, Button, Input, message } from "antd"
import "styles/user/addmember.scss"
class AddMember extends Component {
    formRef = React.createRef()
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            selectRorle: ''
        }
    }
    componentDidMount() {
        // this.getRoleOption()
    }
  handleChange = value => {
      //   console.log(`selected ${value}`)
  };

  handleCancel = () => {
      this.props.closeDialog()
  }

  //   获取角色
  getRoleOption = () => {
      this.axios.post('/role/getRoleOption')
          .then(res => {
              if (res.code === 10000){
                  this.setState({
                      list: res.data
                  })
              }
          })
  }


  //   添加成员
  onFinish = values => {
      this.axios.post('/team/teamMemberInvite', {...values, team_id: this.props.team_id})
          .then(res => {
              if (res.code === 10000){
                  message.success('已发送加入队伍邀请，请等待对方确认')
                  this.props.closeDialog()
              }
          })
  }

  //校验手机号
  checkUserMobile = (rule, value) => {
      const reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/
      if (reg.test(value) && value.length === 11) {
          return Promise.resolve()
      }
      if (!value || value.length === 0) {
          return Promise.reject('手机号码不能为空！')
      }
      return Promise.reject('手机号码格式错误！')
  }

   //校验邮箱
   checkEmail = (rule, value) => {
       const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/
       if (reg.test(value)) {
           return Promise.resolve()
       }
       if (!value || value.length === 0) {
           return Promise.reject('邮箱不能为空！')
       }
       return Promise.reject('邮箱格式不对！')
   }
   render() {
       const { isShowAddMember, roleOption } = this.props
       const { list } = this.state
       const { Option } = Select
       const layout = {
           labelCol: { span: 8 },
       }
       return (
           <Modal
               visible={isShowAddMember}
               destroyOnClose
               footer={null}
               className="add-member"
               centered={true}
               closable
               onCancel={this.handleCancel}
           >
               <div className="title">添加队伍成员</div>
               <Form
                   name="basic"
                   ref={this.formRef}
                   onFinish={this.onFinish}
                   autoComplete="off"
                   {...layout}
               >
                   <Form.Item
                       name="user_mobile"
                       label="队伍成员手机号码"
                       rules={[
                           {
                               required: true,
                           },
                           { validator: this.checkUserMobile }
                       ]}
                   >
                       <Input />
                   </Form.Item>
                   <Form.Item
                       name="user_name"
                       label="队伍成员姓名"
                       rules={[
                           {
                               required: true,
                           }
                       ]}
                   >
                       <Input />
                   </Form.Item>
                   <Form.Item
                       name="user_email"
                       label="队伍成员邮箱"
                       rules={[
                           {
                               required: true,
                           },
                           {validator: this.checkEmail}
                       ]}
                   >
                       <Input />
                   </Form.Item>
                   <Form.Item
                       name="role_id"
                       label="授权角色"
                       rules={[
                           {
                               required: true,
                           },
                       ]}
                   >
                       <Select
                           placeholder=""
                           onChange={this.handleChange}
                       >
                           {roleOption.map(item => (
                               <Option value={item.id}>{item.role_name}</Option>
                           ))}
                       </Select>
                   </Form.Item>
                   <Form.Item>
                       <div className="btns">
                           <Button type="primary" htmlType="submit" className="btn" >确认</Button>
                           <Button htmlType="button" className="btn" onClick={this.handleCancel}>取消</Button>
                       </div>
                   </Form.Item>
               </Form>
               <div className="tips">
              提示：以上队伍成员信息请录入该成员注册账号时填写的信息，如未注册，请联系该成员先进行注册，注册后再进行添加操作。
               </div>
           </Modal>
       )
   }
}
export default withRouter(AddMember)
