
import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import 'styles/agreement/agreement.scss'
class Guid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        }

    }
    componentDidMount(){
        console.log('props', this.props)
        this.getData()
    }

    getData = () => {
        let params = {type: this.props.location.state.type}
        this.axios.post('/game', params).then(res => {
            // console.log('res', res)
            if (res.code === 10000){
                this.setState({
                    data: res.data.info.guide
                })
            }
        })
    }
    render() {
        const {data} = this.state
        return (
            <div className="agreement">
                <div className="title">指南</div>
                <div className="cont" dangerouslySetInnerHTML={{__html: data}}></div>
            </div>
        )
    }
}
export default Guid

