
/* eslint-disable */


function unReadNum(state = 0,action) {
    const {type, status}  = action
    // console.log('action',action)
    switch (type) {
        case 'UNREAD':
            return status
        default:
            return state
    }
}

export {unReadNum}