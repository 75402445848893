import React,{ useState } from 'react'
import { Form, Input, Button,Space,Upload,message } from 'antd'
import { APIBaseURL,OnlyCH,UploadIcon,UploadFileError,MobileDelBr } from 'utils'
import { getStorage } from 'utils/storage'
import TIPS from 'pages/json/tips.json'
import LABELS from 'pages/json/labels.json'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'

const { TextArea } = Input

const NoFundTeam = props => {
    const [ form ] = Form.useForm()
    // filecvs:[{file:...,file_name:...,fileEN:...,fileEN_name:...}]
    const [ filecvs,setFilescvs ] = useState([]) // 传给后台的数据格式
    // fileLists:{0:[{url,name,uid:url,status:'done'}],1:[{url,name,uid:url,status:'done'}]}
    const [ fileLists,setFileLists ] = useState({}) // upload的中文文件列表
    // fileEnLists:{0:[{url,name,uid:url,status:'done'}],1:[{url,name,uid:url,status:'done'}]}
    const [ fileEnLists,setFileEnLists ] = useState({}) //  upload的英文文件列表
    // 提交失败
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        if (errorFields.length > 0) {message.warning('成员信息不完整！')}
    }
    // 文件的大小验证
    const beforeUpload = file => {
        const isLt2M = file.size / 1024 / 1024 < 10
        if (!isLt2M) {
            message.error('文件大小不超过10M！')
        }
        return isLt2M
    }
    // 文件上传改变
    const handleChange = (type,key,info) => { //ch,0,files
        // 上传中
        if (info.file.status === 'uploading') {
            let uploadFile = {}
            uploadFile[key] = [info.file]
            
            if (type === 'ch'){
                let files = Object.assign({},fileLists,uploadFile)
                setFileLists(files) // 更新upload列表
            } else {
                let files = Object.assign({},fileEnLists,uploadFile)
                setFileEnLists(files)
            }
        }
        // 删除文件
        if (info.file.status === 'removed'){
            let delObj = filecvs[key] // 当前key的文件对象 传递给后台的数据
            console.log('delete',delObj)
            if (type === 'ch'){
                delete delObj.file 
                delete delObj.file_name
                let deleteFile = {...fileLists}
                delete deleteFile[key] // 删除当前的队员中文的文件
                setFileLists(deleteFile) // 更新中文文件列表
            } else {
                delete delObj.fileEN
                delete delObj.fileEN_name
                let deleteFile = {...fileEnLists}
                delete deleteFile[key] // 删除当前的队员英文的文件
                setFileEnLists(deleteFile) // 更新英文文件列表
            }
            const newfilecvs = [...filecvs]
            // 插入
            newfilecvs.splice(key,1,delObj)
            setFilescvs(newfilecvs) // 传递给后台的数据格式
            // 把文件 赋值到input
            form.setFieldsValue({cv_files:newfilecvs})
        }
        

        // 上传成功
        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.url,name = info.file.response.data.old_name

            let uploadFile = {}
            uploadFile[key] = [{url,name,uid:url,status:'done'}]

            let obj = filecvs[key] ? filecvs[key] : {} // 当前key的文件对象 传递给后台的数据
            
            if (type === 'ch'){
                let files = Object.assign(fileLists,uploadFile)
                setFileLists(files) // 更新upload列表
                obj = Object.assign(obj,{file:url,file_name:name})
            } else {
                let files = Object.assign(fileEnLists,uploadFile)
                setFileEnLists(files)
                obj = Object.assign(obj,{fileEN:url,fileEN_name:name})
            }
            const newfilecvs = [...filecvs]
            // 插入
            newfilecvs.splice(key,1,obj)
            setFilescvs(newfilecvs) // 传递给后台的数据格式
            // 把文件 赋值到input
            form.setFieldsValue({cv_files:newfilecvs})
        } else if (info.file.status === 'done' && info.file.response.code !== 10000) {
            UploadFileError(info.file.response)
        }
    }
    // 上传配置项
    const uploadProps = {
        accept:'.pdf,.doc,.docx, application/msword, application/pdf',
        listType:'file',
        maxCount:1,
        action: `${APIBaseURL}upload`,
        headers: { token: getStorage('token') },
        beforeUpload,
    }
    MobileDelBr(LABELS)
    return <Form className='innovate-form busi-form' form={form} autoComplete="off"
        name="dynamic_nofund_team"
        initialValues={{ 'nofund_team': [{ fieldKey: 0, key: 0, name: 0 }] }}
        onFinish={props.onSubmit}
        onFinishFailed={onFinishFailed}
    >
        <Form.List name="nofund_team">
            {(fields, { add, remove }) => {
                return <>
                    {fields.map(({ key, name, fieldKey }) =>{
                        console.log('key, name, fieldKey', key, name, fieldKey)
                        return <Space key={key} className='nofund-team-space' align='start'>
                            <div>
                                {/* 主创成员姓名 */}
                                <Form.Item
                                    {...layout}
                                    className='double-input'
                                    label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_teammember_name}}></i>}
                                    name={[name, 'mem_ch_name']}
                                    fieldKey={[fieldKey, 'mem_ch_name']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:50,message:'长度不能超过50个字符！'}]}
                                >
                                    <Input placeholder='请输入主创成员姓名'/>
                                </Form.Item>
                                <Form.Item
                                    {...noLabelLayout}
                                    name={[name, 'mem_en_name']}
                                    fieldKey={[fieldKey, 'mem_en_name']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:50,message:'长度不能超过50个字符！'},{ validator:OnlyCH }]}
                                >
                                    <Input placeholder="Name"/>
                                </Form.Item>

                                {/* 项目职位 */}
                                <Form.Item
                                    {...layout}
                                    className='double-input'
                                    label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_teammember_posotion}}></i>}
                                    name={[name, 'mem_ch_position']}
                                    fieldKey={[fieldKey, 'mem_ch_position']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:50,message:'长度不能超过50个字符！'}]}
                                >
                                    <Input placeholder='请输入所在项目职位'/>
                                </Form.Item>
                                <Form.Item
                                    {...noLabelLayout}
                                    name={[name, 'mem_en_position']}
                                    fieldKey={[fieldKey, 'mem_en_position']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:50,message:'长度不能超过50个字符！'},{ validator:OnlyCH }]}
                                >
                                    <Input placeholder="Position within the Team"/>
                                </Form.Item>

                                {/* 学术机构单位/企业组织/Institution:*/}
                                <Form.Item
                                    {...layout}
                                    className='double-input'
                                    label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_teammember_institution}}></i>}
                                    name={[name, 'mem_ch_institution']}
                                    fieldKey={[fieldKey, 'mem_ch_institution']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:50,message:'长度不能超过50个字符！'}]}
                                >
                                    <Input placeholder='请输入所在研究、学术机构职位'/>
                                </Form.Item>
                                <Form.Item
                                    {...noLabelLayout}
                                    name={[name, 'mem_en_institution']}
                                    fieldKey={[fieldKey, 'mem_en_institution']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:50,message:'长度不能超过50个字符！'},{ validator:OnlyCH }]}
                                >
                                    <Input placeholder="Institution"/>
                                </Form.Item>

                                {/* 所在研究、学术机构职位/Position in Institution:*/}
                                <Form.Item
                                    {...layout}
                                    className='double-input'
                                    label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_teammember_posins}}></i>}
                                    name={[name, 'mem_ch_posins']}
                                    fieldKey={[fieldKey, 'mem_ch_posins']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:50,message:'长度不能超过50个字符！'}]}
                                >
                                    <Input placeholder='请输入所在研究、学术机构职位'/>
                                </Form.Item>
                                <Form.Item
                                    {...noLabelLayout}
                                    name={[name, 'mem_en_posins']}
                                    fieldKey={[fieldKey, 'mem_en_posins']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:50,message:'长度不能超过50个字符！'},{ validator:OnlyCH }]}
                                >
                                    <Input placeholder="Position in Institution"/>
                                </Form.Item>
                        

                                {/* 学术研究方向/Research Focus:*/}
                                <Form.Item
                                    {...layout}
                                    className='double-input'
                                    label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_teammember_resfoc}}></i>}
                                    name={[name, 'mem_ch_resfoc']}
                                    fieldKey={[fieldKey, 'mem_ch_resfoc']}
                                    rules={[ { required: true, message: '该项是必填项！'},{type:'string',max:500,message:'长度不能超过500个字符！'}]}
                                >
                                    <TextArea placeholder='必填，请输入学术研究方向，500字以内' autoSize = {{ minRows: 4, maxRows: 4 }}/>
                                </Form.Item>
                                <Form.Item
                                    {...noLabelLayout}
                                    name={[name, 'mem_en_resfoc']}
                                    fieldKey={[fieldKey, 'mem_en_resfoc']}
                                    rules={[
                                        // { required: true, message: '该项是必填项！'},
                                        {type:'string',max:3000,message:'长度不能超过3000个字符！'},{ validator:OnlyCH }
                                    ]}
                                >
                                    <TextArea placeholder='(Optional) Enter research focus，< 3000 characters' autoSize = {{ minRows: 4, maxRows: 4 }}/>
                                </Form.Item>

                                {/* 研究成果/Research Results:*/}
                                <Form.Item
                                    {...layout}
                                    className='double-input'
                                    label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_teammember_resres}}></i>}
                                    name={[name, 'mem_ch_resres']}
                                    fieldKey={[fieldKey, 'mem_ch_resres']}
                                    rules={[ { required: true, message: '该项是必填项！'},{ type:'string',max:5000,message:'长度不能超过5000个字符！' }]}
                                >
                                    <TextArea placeholder='必填，请输入学术研究成果' autoSize = {{ minRows: 4, maxRows: 4 }}/>
                                </Form.Item>
                                <Form.Item
                                    {...noLabelLayout}
                                    name={[name, 'mem_en_resres']}
                                    fieldKey={[fieldKey, 'mem_en_resres']}
                                    rules={[ 
                                        // { required: true, message: '该项是必填项！'},
                                        { type:'string',max:10000,message:'长度不能超过10000个字符！' },
                                        { validator:OnlyCH }
                                    ]}
                                >
                                    <TextArea placeholder='(Optional) Enter research results' autoSize = {{ minRows: 4, maxRows: 4 }}/>
                                </Form.Item>
                                <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_team_resres}}></span>

                                {/* 技术转化案例（如有）/Application Case (If any):*/}
                                <Form.Item
                                    {...layout}
                                    className='double-input'
                                    label={<i dangerouslySetInnerHTML={{__html:LABELS.nofund_label_teammember_application}}></i>}
                                    name={[name, 'mem_ch_application']}
                                    fieldKey={[fieldKey, 'mem_ch_application']}
                                    rules={[ { type:'string',max:5000,message:'长度不能超过5000个字符！' }]}
                                    initialValue=''
                                >
                                    <TextArea placeholder='请输入学术研究成果' autoSize = {{ minRows: 4, maxRows: 4 }}/>
                                </Form.Item>
                                <Form.Item
                                    {...noLabelLayout}
                                    name={[name, 'mem_en_application']}
                                    fieldKey={[fieldKey, 'mem_en_application']}
                                    rules={[ { type:'string',max:10000,message:'长度不能超过10000个字符！' },{ validator:OnlyCH }]}
                                    initialValue=''
                                >
                                    <TextArea placeholder='(Optional) Enter application case' autoSize = {{ minRows: 4, maxRows: 4 }}/>
                                </Form.Item>
                                <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_team_case}}></span>

                                {/* 中英文简历/CV in Chinese and English： */}
                                <h2 className='file-h2'>中英文简历/CV in Chinese and English：</h2>
                                <Form.Item {...noLabelLayout}>
                                    <Space align="start">
                                        {/* rules={[{ required: true, message: '必须上传一个文件' }]} */}
                                        <Form.Item name="cv_files">
                                            <Upload {...uploadProps} fileList={fileLists[key] ? fileLists[key] : []} onChange={ handleChange.bind(this,'ch',key)}>
                                                {UploadIcon('ch', '必选')}
                                            </Upload>
                                        </Form.Item>
                                        
                                        <Upload {...uploadProps} fileList={fileEnLists[key] ? fileEnLists[key] : []}onChange={ handleChange.bind(this,'en',key)}>
                                            {UploadIcon('en', 'Optional')}
                                        </Upload>
                                    </Space>
                                </Form.Item>
                                <span className="btm-tip" dangerouslySetInnerHTML={{__html:TIPS.nofund_tip_cv}}></span>
                            </div>
                            <div className='action-box'>
                                {fields.length < 5 && <i className='addicon' onClick={() => add()}/>}
                                {fields.length > 1 && <i className='removeicon' onClick={() => remove(name)} />}
                            </div>
                        </Space>
                    })}
                </>
            }}
        </Form.List>
        {/* 存储简历文件 一并提交 */}
        <Form.Item {...noLabelLayout} name='cv_files'>
            <Input hidden/>
        </Form.Item>

        <Form.Item {...buttonLayout} className='button-box'>
            <Button type="primary" htmlType='submit'> 下一步 </Button>
        </Form.Item>
    </Form>
}

export default NoFundTeam