import React, { Component, useEffect, useState } from 'react'
import SwiperComp from 'comps/SwiperComp'
import Test from 'comps/Test'
import Test2 from 'comps/Test2'
import 'styles/home/home.scss'
import axios from 'libs/axios'
import PackUp from 'comps/PackUp'
import { useHistory } from "react-router-dom"
import { getStorage } from 'utils/storage'
import { useDispatch } from 'react-redux'
import { getUnReadNum } from '@redux/information/action'
const HomeIndex = () => {
    let history = useHistory()
    const [content, setContest] = useState([])
    const [notice, setNotice] = useState([])
    const [question, setQuestion] = useState([])
    const [about, setAbout] = useState({})
    const [time, setIime] = useState({})
    const [organizer, setOrganizer] = useState([])
    const [company, setCompany] = useState([])
    const dispatch = useDispatch()
    useEffect(()=>{
        axios.post('/question').then(res => {
            if ( res.code === 10000){
                setQuestion(res.data[0] && res.data[0].content)
            }
        })
        axios.post('/company').then(res => {
            if ( res.code === 10000){
                setOrganizer(res.data)
            }
        })
        axios.post('/company2').then(res => {
            console.log('发起方',res.data)
            if ( res.code === 10000){
                setCompany(res.data)
            }
        })
        axios.post('/about').then(res => {
            if ( res.code === 10000){
                setAbout(res.data)
            }
        })
        axios.post('/time', { type: 1 }).then(res => {
            if ( res.code === 10000 ) {
                setIime(res.data)
            }
        })
        axios.post('/notice').then(res => {
            if ( res.code === 10000){
                setNotice(res.data)
            }
        })
        axios.post('/game/list').then(res => {
            if (res.code === 10000){
                setContest(res.data)
            }
        }).then(res=>{
            if (document.body.clientWidth > 750){
                let test = document.getElementById('test'), banner = document.getElementById('banner')
                if (test){
                    test.style.height = window.innerWidth * 0.5 + 'px'
                    banner.style.marginTop = test.clientHeight * -0.15 + 'px'
                }
            }
        })
        getStorage('token') ? axios.post('/message/getUnreadMessageNotice').then(res => {
            if (res.code === 10000) {
                dispatch(getUnReadNum(res.data.unread_count))
            }
        }) : dispatch(getUnReadNum(0))
    },[])
    useEffect(()=>{
        window.onresize = function(){
            let test = document.getElementById('test'), banner = document.getElementById('banner')
            if (test){
                test.style.height = window.innerWidth * 0.5 + 'px'
                banner.style.marginTop = test.clientHeight * -0.2 + 'px'
            }
        }
    },[])
    return (
        <>
            <div className='home-container'>
                <Test />
                <div className='banner-box' style={{backgroundColor: '#FCD34C'}}>
                    <div className='banner' id='banner'>
                        <div className='banner-body'>
                            <div className='banner-item' onClick={notice[0] && notice[0].url !== '' ? ()=>{history.push(notice[0].url)} : ''}>
                                <img src={notice[0] && notice[0].img} className='item-img'  alt=''/>
                                <div title={notice[0] && notice[0].content}>{notice[0] && notice[0].content}</div>
                            </div>
                            <div className='banner-item' onClick={notice[1] && notice[1].url !== '' ? ()=>{history.push(notice[1].url)} : ''}>
                                <img src={notice[1] && notice[1].img} className='item-img'  alt=''/>
                                <div title={notice[1] && notice[1].content}>{notice[1] && notice[1].content}</div>
                            </div>
                        </div>
                        <div className='banner-body'>
                            <div className='banner-item' onClick={notice[2] && notice[2].url !== '' ? ()=>{history.push(notice[2].url)} : ''}>
                                <img src={notice[2] && notice[2].img} className='item-img'  alt=''/>
                                <div title={notice[2] && notice[2].content}>{notice[2] && notice[2].content}</div>
                            </div>
                            <div className='banner-item' onClick={notice[3] && notice[3].url !== '' ? ()=>{history.push(notice[3].url)} : ''}>
                                <img src={notice[3] && notice[3].img} className='item-img'  alt=''/>
                                <div title={notice[3] && notice[3].content}>{notice[3] && notice[3].content}</div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* 关于 */}
                <div className='about-body'>
                    <h3>
                        <img src={about.img}  alt=''/>{about.title}
                    </h3>
                    <div className='about-content'>
                        <div className='about-item' dangerouslySetInnerHTML={{__html: about.left_content}}></div>
                        <div className='about-item' dangerouslySetInnerHTML={{__html: about.right_content}}></div>
                    </div>
                </div>
                {/* 介绍 */}
                <div className='intros'>
                    <div className='intro-body'>
                        {
                            content.map((item,index)=>{
                                return <div className='intro-item' key={index} onClick={item.type === 1 ? ()=>history.push('/signup/innovate') : item.type === 2 ? ()=>history.push('/signup/business') : ()=>{history.push('/signup/challenge')}}>
                                    <img src={item.cover}  alt=''/>
                                    <p>{item.title}</p>
                                    <div className='intro-content'>
                                        {item.in_a_word}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                {/* 时间表 */}
                <div className='time'>
                    <div className='title'><div className='bor'></div><span>时间表</span></div>
                    <div className='time-img'>
                        <img src={time.img_pc}  alt=''/>
                    </div>
                </div>
                {/* 发起方 */}
                <div className='organizer'>
                    {
                        organizer.map((item,index)=>{
                            return <div className='organizer-item' key={index}>
                                <div className='title'>
                                    <img src={require('assets/images/ju.png')}  alt=''/>
                                    {item.title}
                                </div>
                                <div className='item-body'>
                                    {
                                        item.items.map((i,n)=>{
                                            return <div><img key={n} src={i.img}  alt=''/><div>{i.title}</div></div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>

                {/* 创新创业企业挑战承办方 */}
                <div className='all-organizer'>
                    {
                        company.map(item => {
                            return <>
                                <div className='innovate'>
                                    <div className='maintitle'>{item.title}</div>
                                    <div className='organizer'>
                                        {
                                            item.items && item.items.map((ite,index)=>{
                                                return <div className='organizer-item' key={index}>
                                                    { ite.items.length ? <div className='title'>
                                                        <img src={require('assets/images/ju.png')}  alt=''/>
                                                        {ite.title}
                                                    </div> : null}
                                                    <div className='item-body'>
                                                        {
                                                            ite.items.map((i,n)=>{
                                                                return <div><img key={n} src={i.img}  alt=''/><div>{i.title}</div></div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>

                                {/* {
                                    item.items && item.items.map(it => {
                                        return it.items.length ? 
                                            <div className='innovate'>
                                                <div className='maintitle'>{item.title}</div>
                                                <div className='organizer'>
                                                    {
                                                        item.items && item.items.map((ite,index)=>{
                                                            return <div className='organizer-item' key={index}>
                                                                { ite.items.length ? <div className='title'>
                                                                    <img src={require('assets/images/ju.png')}  alt=''/>
                                                                    {ite.title}
                                                                </div> : null}
                                                                <div className='item-body'>
                                                                    {
                                                                        ite.items.map((i,n)=>{
                                                                            return <div><img key={n} src={i.img}  alt=''/><div>{i.title}</div></div>
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div> : null
                                    })
                                } */}

                               
                            </>
                        })
                    }
                </div>

                {/* 相关问题 */}
                {/* <div className='problem'>
                    <div className='problem-body' style={{height: '100%'}}>
                        <div className='problem-title'>
                            <img src={require('assets/images/title2.png')} />
                            相关问题
                        </div>
                        {
                            question && <PackUp maxHeight={450} introduce={question} />
                        }
                    </div>
                </div> */}
            </div>
            {/* 移动端 */}
            <div className='home-container-move'>
                <Test2 />
                <div className='banner'>
                    {
                        notice.map((item,index)=>{
                            return <div className='banner-item' key={index}>
                                <img src={item.img} className='item-img'  alt=''/>
                                <div dangerouslySetInnerHTML={{__html:item.content}}></div>
                            </div>
                        })
                    }
                </div>
                {/* 关于 */}
                <div className='about-body'>
                    <h3>
                        {about.title}
                    </h3>
                    <div className='about-content'>
                        <div className='about-item' dangerouslySetInnerHTML={{__html: about.left_content}}></div>
                        <div className='about-item' dangerouslySetInnerHTML={{__html: about.right_content}}></div>
                    </div>
                </div>
                {/* 介绍 */}
                <div className='intros'>
                    <div className='intro-body'>
                        {
                            content.map((item,index)=>{
                                return <div className='intro-item' key={index} onClick={item.type === 1 ? ()=>history.push('/signup/innovate') : item.type === 2 ? ()=>history.push('/signup/business') : ()=>{history.push('/signup/challenge')}}>
                                    <img src={item.cover}  alt=''/>
                                    <p>{item.title}</p>
                                    <div className='intro-content'>
                                        {item.in_a_word}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                {/* 时间表 */}
                <div className='time'>
                    <div className='title'><div className='bor'></div><span>时间表</span></div>
                    <div className='time-img'>
                        <img src={time.img_h5}  alt=''/>
                    </div>
                </div>
                {/* 发起方 */}
                <div className='organizer'>
                    {
                        organizer.map((item,index)=>{
                            return <div className='organizer-item' key={index}>
                                <div className='title'>
                                    <img src={item.img}  alt=''/>
                                    {item.title}
                                </div>
                                <div className='item-body'>
                                    {
                                        item.items.map((i,n)=>{
                                            return <div><img key={n} src={i.img}  alt=''/><div>{i.title}</div></div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>

                {/* 创新创业企业挑战承办方 */}
                <div className='all-organizer'>
                    {
                        company.map(item => {
                            return <div className='innovate'>
                                <div className='maintitle'>{item.title}</div>
                                <div className='organizer'>
                                    {
                                        item.items && item.items.map((ite,index)=>{
                                            return <div className='organizer-item' key={index}>
                                                { ite.items.length ? <div className='title'>
                                                    <img src={require('assets/images/ju.png')}  alt=''/>
                                                    {ite.title}
                                                </div> : null}
                                                <div className='item-body'>
                                                    {
                                                        ite.items.map((i,n)=>{
                                                            return <div><img key={n} src={i.img}  alt=''/><div>{i.title}</div></div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        })
                                    }
                                </div>
                            </div>
                        })
                    }
                </div>
                
                {/* 相关问题 */}
                {/* <div className='problem'>
                    <div className='problem-body'>
                        <div className='problem-title'>
                            {
                                document.body.clientWidth > 750 ? <img src={require('assets/images/title2.png')} /> : <img src={require('assets/images/h5-in-juxing1.png')} />
                            }
                            相关问题
                        </div>
                        <PackUp maxHeight={450} introduce={question} />
                    </div>
                </div> */}
            </div>
        </>    
    )
}

export default HomeIndex