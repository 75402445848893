
import React, { Component, useEffect, useState } from 'react'
import { Modal, Button, Form, Upload, message, Input } from 'antd'
import 'styles/comps/signup/upload-dialog.scss'
import PackUp from 'comps/PackUp'
import axios from 'libs/axios'
import className from 'classname'
import Dialog2 from 'comps/signup/Dialog2'
import { getStorage, clearStorage } from 'utils/storage'
import { APIBaseURL, Msg, UserColumns, UploadIcon, UploadFileError } from 'utils'
import { useSelector, useDispatch } from 'react-redux'
const UploadDialog = props => {
    const status = useSelector(state => state)
    // console.log('props', status)
    const [isPC, setIsPC] = useState(status.isPC)
    let {isShow} = props
    const [ form ] = Form.useForm()
    const [defaultFileList, setDefaultFileList] = useState([])
    const [upFile, setUpFile] = useState({})
    // console.log('isPCisPC', isPC)
    useEffect(() => {

    }, [])
    
    //关闭弹框
    const closeDialog = () => {
        setDefaultFileList([])
        form.setFieldsValue({ fileName: ''})
        form.setFieldsValue({ fileUrl: ''})
        form.setFieldsValue({ filedata: ''})
        props.closeDialog()        
    }
    const handleOk = () => {

    }

    // 文件上传前
    const beforeUpload = file => {
        // console.log('file', file)
        const isLt2M = file.size / 1024 / 1024 < 10
        if (!isLt2M) {
            message.error('文件大小不超过10M！')
        }
        return isLt2M
    }

    //上传文件
    const handleChange = info => {
        // console.log('info', info)
        if (info.file.status === 'uploading') {
            setDefaultFileList([...info.fileList])
        }
        // 删除文件
        if (info.file.status === 'removed'){
            setDefaultFileList([])
            let filedata = { fileName: '', fileUrl: ''}
            form.setFieldsValue({ fileName: ''})
            form.setFieldsValue({ fileUrl: ''})
            form.setFieldsValue({ filedata: ''})
        }

        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.url, name = info.file.response.data.old_name
            const file = {name,url,status:'done',uid:url}
            setDefaultFileList([file])
            form.setFieldsValue({ fileName: name})
            form.setFieldsValue({ fileUrl: url})
            let filedata = { fileName: name, fileUrl: url}
            form.setFieldsValue({ filedata: JSON.stringify(filedata)})
            setUpFile(info.file)
        } else if (info.file.status === 'done' && info.file.response.code !== 10000) {
            UploadFileError(info.file.response)
            const file = { name:info.file.name,url:'',status:'error',uid:info.file.uid }
            setDefaultFileList([file])
            form.setFieldsValue({ fileName: ''})
            form.setFieldsValue({ fileUrl: ''})
            form.setFieldsValue({ filedata: ''})
        }
    }

    // 上传配置项
    const uploadProps = {
        accept:'.pdf,.ppt,.pptx,application/pdf',
        listType:'file',
        maxCount:1,
        action: `${APIBaseURL}upload`,
        headers: { token: getStorage('token') },
        beforeUpload: beforeUpload,
    }

    //保存编辑信息
    const onFinish = values => {
        // console.log('value',values)
        // console.log('value',JSON.parse(values.filedata))
        // console.log('defaultFileList', defaultFileList)
        // console.log('upFile', upFile)
        // console.log('props', props)
        let params = {
            date: '2021',
            new_date: '2021',
            company_date: '2021',
            track_id: props.track_id,
            file: upFile.response.code ? upFile.response.data.name : '',
            id: props.id
        }
        // console.log('params', params)
        axios.post('/challenge/reg', params).then(res => {
            // console.log('res', res)
            if (res.code === 10000){
                closeDialog()
                setDefaultFileList([])
                form.setFieldsValue({ fileName: ''})
                form.setFieldsValue({ fileUrl: ''})
                form.setFieldsValue({ filedata: ''})
                if (props.isChallSignup) {
                    // window.location.href = '/usercenter'
                    message.success('上传成功，请到个人中心进行查看')
                }
            }
        })
        
    }

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo)
    }
    
    return (
        <Modal  visible={isShow} onOk={handleOk} onCancel={closeDialog} destroyOnClose footer={null} centered={true} className="challenge-topics-dialog" >
            <div className="title">{props.id ?  '重新上传材料' : '报名'}</div>
            <Form
                form={form}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <div className="dialog-content">
                    <div className="dialog-content-up">
                        <div className="lable">报名材料：</div>
                        <Form.Item 
                            name="filedata" 
                            rules={[ { required: true, message: '必须上传一个文件'}]}> 
                            <Upload {...uploadProps} onChange={handleChange} fileList={defaultFileList}>
                                <div className='icon-box'>
                                    <img src={require('assets/images/upload.png')} alt="上传icon"/>
                                    <div style={{ marginTop: 8 }}>{props.id ? '重新上传' : '上传'}</div>
                                </div>
                            </Upload>
                        </Form.Item>
                        
                    </div>
                    <div className="tips">提示：请上传PDF或者PPT文件，大小不超过10M</div>
                    <div className="tips">Note: {`<`}10M, in PPT or PDF format</div>
                </div>
                <Form.Item  className="btn-box">
                    <Button type="primary" htmlType="submit" className="ok">确认提交</Button>
                    <div className="cancel" onClick={closeDialog}>取消</div>
                </Form.Item>
                <Form.Item  className="btn-box-h5">
                    <div className="cancel" onClick={closeDialog}>取消</div>
                    <Button type="primary" htmlType="submit" className="ok">确认提交</Button>
                </Form.Item>
            </Form>
            
        </Modal>
    )
}

export default UploadDialog