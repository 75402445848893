import React, { useState } from 'react'
import { Form, Input, Upload, message, Button } from 'antd'
import LABELS from 'pages/json/labels.json'
import TIPS from 'pages/json/tips.json'
import { getStorage } from 'utils/storage'
import { APIBaseURL, OnlyCH,UploadIcon,UploadFileError,MobileDelBr } from 'utils'
import { layout, noLabelLayout, buttonLayout } from 'pages/config'

const { TextArea } = Input

const FundTeam = props => {
    const [form] = Form.useForm()
    const [ fileLists, setFileLists ] = useState([])
    const [ fileEnLists, setFileEnLists ] = useState([])

    const beforeUpload = file => {
        const isLt2M = file.size / 1024 / 1024 < 20
        if (!isLt2M) {
            message.error('文件大小不超过20M！')
        }
        return isLt2M
    }
    const handleChange = (type,info) => {
        console.log(type,info)
        // 上传中
        if (info.file.status === 'uploading') {
            if (type === 'ch'){
                setFileLists([...info.fileList])
            } else {
                setFileEnLists([...info.fileList])
            }
        }
        // 删除文件
        if (info.file.status === 'removed'){
            if (type === 'ch'){
                setFileLists([])
                form.setFieldsValue({ fun_company_cvs: ''})
                form.setFieldsValue({ fun_company_cvs_name: ''})
            } else {
                setFileEnLists([])
                form.setFieldsValue({ en_fun_company_cvs: ''})
                form.setFieldsValue({ en_fun_company_cvs_name: ''})
            }
        }
        // 上传成功
        if (info.file.status === 'done' && info.file.response.code === 10000) {
            const url = info.file.response.data.url,name = info.file.response.data.old_name
            const file = {name,url,status:'done',uid:url}
            if (type === 'ch'){
                setFileLists([file])
                form.setFieldsValue({ fun_company_cvs: url})
                form.setFieldsValue({ fun_company_cvs_name: name})
            } else {
                console.log('name', name)
                setFileEnLists([file])
                form.setFieldsValue({ en_fun_company_cvs: url})
                form.setFieldsValue({ en_fun_company_cvs_name: name})
                // form.setFieldsValue({ fun_company_cvs_name: name})
            }
        } else if (info.file.status === 'done' && info.file.response.code !== 10000) {
            UploadFileError(info.file.response)
            const file = { name:info.file.name,url:'',status:'error',uid:info.file.uid }
            if (type === 'ch'){
                setFileLists([file])
                form.setFieldsValue({ fun_company_cvs: ''})
                form.setFieldsValue({ fun_company_cvs_name: ''})
            } else {
                setFileEnLists([file])
                form.setFieldsValue({ en_fun_company_cvs: ''})
                form.setFieldsValue({ en_fun_company_cvs_name: ''})
            }
        }
    }
    const onFinishFailed = ({ values, errorFields, outOfDate }) => {
        console.log(values, 'values')
        console.log(errorFields, 'errorFields')
        if (errorFields.length > 0) { message.warning('团队成员信息不完整！') }
    }
    // 上传配置项
    const uploadProps = {
        // accept: '.rar, application/x-zip-compressed',
        accept: '.rar,.zip,.tar,.tar.gz,application/x-zip-compressed, application/x-tar, application/x-gzip, application/octet-stream',
        listType: 'file',
        maxCount: 1,
        action: `${APIBaseURL}/upload`,
        headers: { token: getStorage('token') },
        beforeUpload,
    }
    MobileDelBr(LABELS)
    return <Form {...layout}
        className='innovate-form busi-form'
        name="company-info"
        onFinish={props.onSubmit}
        form={form}
        onFinishFailed={onFinishFailed}
    >
        {/* 管理团队情况 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_team_management }}></i>}
            name="ch_partner"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入管理团队情况' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_partner"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 20000, message: '长度不能超过20000个字符！' }, 
                { validator: OnlyCH}
            ]}
        >
            <TextArea placeholder='(Optional) Enter management team' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_team_management }}></span>

        {/* 研发团队情况 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_team_RD }}></i>}
            name="ch_award"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入研发团队情况' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_award"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 20000, message: '长度不能超过20000个字符！' }, 
                { validator: OnlyCH}
            ]}
        >
            <TextArea placeholder='(Optional) Enter R&D team' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_team_RD }}></span>

        {/* 销售团队情况 */}
        <Form.Item
            className='double-input'
            label={<i dangerouslySetInnerHTML={{ __html: LABELS.fund_label_team_sales }}></i>}
            name="ch_develpment"
            rules={[{ required: true, message: '该项是必填项！' }, { max: 5000, message: '长度不能超过5000个字符！' }]}
        >
            <TextArea placeholder='必填，请输入销售团队情况' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <Form.Item
            {...noLabelLayout}
            name="en_develpment"
            rules={[
                // { required: true, message: '该项是必填项！' }, 
                { max: 20000, message: '长度不能超过20000个字符！' }, 
                { validator: OnlyCH}
            ]}
        >
            <TextArea placeholder='(Optional) Enter sales team' autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_team_sales }}></span>

        {/* 团队成员简历/Team Members' CVs： */}
        <h2 className='file-h2'>团队成员简历/Team Members' CVs：</h2>
        <Form.Item name="fun_company_cvs_name" > <Input hidden/> </Form.Item>
        <Form.Item name="en_fun_company_cvs_name" > <Input hidden/> </Form.Item>
        <div className="upload-box">
            <Form.Item
                name="fun_company_cvs"
                rules={[{ required: true, message: '必须上传一个文件' }]}
            >
                <Upload {...uploadProps} fileList={fileLists} onChange={handleChange.bind(this, 'ch')}>
                    {UploadIcon('ch', '必选')}
                </Upload>
                {/* 隐藏的file地址 */}
                <Input hidden />
            </Form.Item>

            <Form.Item
                name="en_fun_company_cvs"
                // rules={[{ required: true, message: '必须上传一个文件' }]}
            >
                <Upload {...uploadProps} fileList={fileEnLists} onChange={handleChange.bind(this, 'en')}>
                    {UploadIcon('en', 'Optional')}
                </Upload>
                <Input hidden />
            </Form.Item>
        </div>
        <span className="btm-tip" dangerouslySetInnerHTML={{ __html: TIPS.fund_tip_team_CVs }}></span>
        <Form.Item {...buttonLayout} className='button-box'>
            <Button type="primary" htmlType='submit'> 保存 </Button>
        </Form.Item>
    </Form>
}
export default FundTeam