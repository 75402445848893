
import React, { Component } from 'react'
import 'styles/reading/reading.scss'
class Reading extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: ''
        }

    }
    componentDidMount(){
        this.getData()
    }

    getData = () => {
        this.axios.post('/service', {type: 20}).then(res => {
            console.log('res', res)
            if (res.code === 10000){
                this.setState({
                    data: res.data
                })
            }
        })
    }
    render() {
        const {data} = this.state
        return (
            <div className="reading">
                {/* <div className="title">报名须知</div> */}
                <div className="cont" dangerouslySetInnerHTML={{__html: data}}></div>
            </div>
        )
    }
}
export default Reading

