import React, { Component, useState, useEffect } from 'react'
import 'styles/news/news.scss'
import { Pagination } from 'antd'
import axios from 'libs/axios'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

function News(props) {
    let history = useHistory()
    const [h5List, setH5List] = useState([])
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [h5Page, setH5Page] = useState(1)
    const [isLoading, setLoading] = useState(false)
    const [height, setHeight] = useState(0)
    const getList = page => {
        axios.post('/news', { page: page, size: 5 }).then(res => {
            if (res.code === 10000) {
                setList(res.data.items)
                setTotal(res.data.total)
            }
        })
    }
    const getH5List = page => {
        setLoading(false)
        axios.post('/news', {page: page, size: 5}).then(res => {
            if (res.code === 10000) {
                setH5List(h5List.concat(res.data.items))
                setH5Page(h5Page + 1)
                setLoading(true)
            }
        })
    }
    const onChange = page => {
        setPage(page)
        getList(page)
        window.scrollTo(0, 0)
    }
    const onScroll = () => {
        // console.log('滚动的高度', window.pageYOffset)
        // console.log('可使区高度', document.body.clientHeight)
        // console.log('页面高度', document.body.scrollHeight)
        let height = document.body.scrollHeight - document.body.clientHeight - window.pageYOffset
        // console.log('h', height)
        if (height <= 0){
            total > h5List.length && getH5List(h5Page)
        }
    }
    const onTouchMove = () => {
        // console.log('滚动的高度', window.pageYOffset)
        // console.log('可使区高度', document.body.clientHeight)
        // console.log('页面高度', document.body.scrollHeight)
        let height = document.body.scrollHeight - document.body.clientHeight - window.pageYOffset
        // console.log('h', height)
        setHeight(height)
    }
    useEffect(() => {
        getList(1)
        getH5List(h5Page)
    }, [])
    return <>
        <div className='news'>
            {
                list.map((item, index) => {
                    return <div className="news-item" key={index} onClick={() => window.open(item.url)}>
                        <img src={item.img} alt="1" />
                        <div className="news-body">
                            <div className="news-title" title={item.title}>{item.title}</div>
                            <div className="news-content" title={item.summary}>{item.summary}</div>
                            <div className="news-other">
                                <p className="author" title={item.author}>{item.author}</p>
                                <p className="time">{moment(item.start_time * 1000).format('YYYY-MM-DD')}</p>
                            </div>
                        </div>
                    </div>
                })
            }
            {/* <PayModal /> */}
            <Pagination onChange={onChange} total={total} pageSize={5} current={page} />
        </div>
        <div className="news-h5">
            <div className="section-title">媒体资讯</div>
            <div className="news-body" onTouchEnd={()=>{onScroll()}}  onTouchMove={() => {onTouchMove()}}>
                {
                    h5List.map((item, index) => {
                        return <div className="news-item" key={index} onClick={() => window.location.href = item.url}>
                            <div className="news-top">
                                <div className="news-left">
                                    <div className="news-title">{item.title}</div>
                                    <div className="news-content">{item.summary}</div>
                                </div>
                                <img src={item.img} alt="1" />
                            </div>
                            <div className="news-bottom">
                                <div className="author">{item.author}</div>
                                <div className="time">{moment(item.start_time * 1000).format('YYYY-MM-DD')}</div>
                            </div>
                        </div>
                    })
                }
                {/* {
                    console.log('height', height, isLoading)
                } */}
                {height <= 0 && !isLoading &&  <div style={{textAlign: 'center', marginBottom: '10px'}}>加载中...</div>}
            </div>
        </div>
    </>

}

export default News