import React, { useEffect, useState } from 'react'
import { Skeleton } from 'antd'
import axios from 'libs/axios'

const TopFormBg = props => {
    const [ URL, setURL ] = useState('')
    useEffect(()=>{
        // 适用于 创新大赛 未融资 融资 挑战报名表单页面的顶部图片
        !props.branch && axios.post('/reg',{type:props.type}).then(res => {
            if (res.code === 10000){
                setURL(res.data)
            }
        })
        // 适用创业大赛分组图片
        props.branch && axios.post('/branch',{type:props.type}).then(res => {
            if (res.code === 10000){
                setURL(res.data)
            }
        })
    },[props.type,props.branch])
    return <div className='top-form-bg'>
        <Skeleton loading={!URL} paragraph={{rows:12}} active>
            <img src={URL} alt="顶部图片"/>
        </Skeleton>
    </div>
}
export default TopFormBg