
import React, { Component } from 'react'
import 'styles/agreement/agreement.scss'
class Agreement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data: null,
            type: this.props.location.state && this.props.location.state.type
        }
    }

    componentDidMount(){
        this.getData()
    }

    componentDidUpdate(preveProps, preveState) {
        // console.log('preveProps', preveProps.location.pathname.state.type)
        // console.log('this.props', this.props.location.pathname.state.type)
        if ( preveProps.location.state && this.props.location.state && preveProps.location.state.type !== this.props.location.state.type ) {
            this.getData()
        }

    }
    getData = () => {
        this.axios.post('/service', {type: this.props.location.state && this.props.location.state.type === 'register' ? 18 : 17}).then(res => {
            // console.log('res', res)
            if (res.code === 10000){
                this.setState({
                    data: res.data
                })
            }
        })
    }
    
    render() {
        const {data} = this.state
        return (
            <div className="agreement container">
                <div className="title">{this.props.location.state && this.props.location.state.type === 'register' ? '用户服务协议' : '用户隐私和服务条款'}</div>
                <div className="cont" dangerouslySetInnerHTML={{__html: data}}></div>
            </div>
        )
    }
}
export default Agreement

