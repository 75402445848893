import React from 'react'
import { message, Badge, Popover } from 'antd'
import moment from 'moment'
import 'styles/utils.scss'

const TEST_API = 'https://thiveapi.synbiohive.com/' // 测试环境接口
const PROD_API = 'https://api.synbiohive.com/'  // 正式环境接口
export const APIBaseURL = process.env.API_ENV === 'test' ? TEST_API : PROD_API
/**
 * debounce 防抖函数
 * @param {function} fn
 * @param {number} delay 时间ms
 * @return {function}
 */
export function debounce(func, delay) {
    let timer = null
    return function() {
        if (timer) {clearTimeout(timer)}
        timer = setTimeout(function() {
            func.apply(this, arguments)
            timer = null
        }, delay)
    }
}
/**
 * throttle 节流函数
 * @param {function} fn
 * @param {number} delay 时间ms
 * @return {function}
 */
export function throttle(func, delay) {
    let timer = null
    return function() {
        if (timer) {return}
        timer = setTimeout(function() {
            func.apply(this, arguments)
            timer = null
        }, delay)
    }
}
/**
 * deepClone 深拷贝
 * @param {object} object
 * @param {array} cache
 * @return {object} obj
 * 方式1：JSON.parse(JSON.stringify(displayTags))
 * 缺点：如果对象或者数组里有函数什么的, 就会出问题
 */
export function deepClone(obj, cache = []){
    // typeof [] => 'object' typeof {} => 'object'
    if (obj === null || typeof obj !== 'object') {
        return obj
    }
    // 如果传入的对象与缓存的相等, 则递归结束, 这样防止循环
    /**
	 * 类似下面这种
	 * var a = {b:1}
	 * a.c = a
	 * 资料: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value
    */
    const hit = cache.filter(c => c.original === obj)[0]
    if (hit) {
        return hit.copy
    }

    const copy = Array.isArray(obj) ?  [] :   {}
    // 将copy首先放入cache, 因为我们需要在递归deepClone的时候引用它
    cache.push({
        original: obj,
        copy
    })
    Object.keys(obj).forEach(key => {
        copy[key] = deepClone(obj[key], cache)
    })

    return copy
}

// h5提示
export function H5Msg(text, time){
    message.success({
        icon: ' ',
        content: <div>{text}</div>,
        guration: time,
        getContainer: true,
        className: 'h5-tip'
    })
}

// 全局提示
export function Msg(type,text){
    message.destroy()
    if (type === 'warning'){
        message.warning(text)
    } else if (type === 'error') {
        message.error(text)
    } else if (type === 'success'){
        message.success(text)
    } else if ( type === 'info'){
        message.info(text)
    }
}

// 只能输入汉字
export function OnlyCH(rule,value){
    if (escape(value).indexOf( "%u" ) > -1){
        return Promise.reject('不允许输入汉字，请重新输入！')
    } else {
        return Promise.resolve()
    }
}

// 验证网址的格式
export const websiteRule = (rule,value) => {
    // eslint-disable-next-line no-useless-escape
    // const reg = /^(?=^.{3,255}$)(http(s)?:\/\/)?(www\.)?[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+(:\d+)*(\/\w+\.\w+)*([\?&]\w+=\w*)*$/
    // const reg = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\*\+,;=.]+$/
    const reg = /[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+\.?/
    if (reg.test(value) || !value) {
        return Promise.resolve()
    } else {
        return Promise.reject('格式不正确，请重新输入！')
    }
}


// 上传文件的icon
export function UploadIcon (type, op){
    return <>
        <img src={require('assets/images/upload.png')} alt="上传icon"/>
        <div style={{ marginTop: 8 }}>{type === 'ch' ?  <span>中文/Chinese{op && <div>({op})</div>}</span> : <span>英文/English{op && <div>({op})</div>}</span>}</div>
    </>
}

// 文件上传失败
export function UploadFileError(res){
    // 上传文件为空的情况  token不存在的情况
    if (res.code === 20001 ){
        message.warning('登录信息已过期，请重新登录！')
        setTimeout(()=>{
            window.location.href = '/login'
        },1500)
    } else {
        message.error(res.message)
    }
}

// 手机尺寸的情况 去掉Lable的<br/>
export function MobileDelBr(data){
    const clientWidth = document.body.clientWidth
    if (clientWidth < 576){
        for (let i in data){
            data[i] = data[i].replace(/<br\/>/g,'')
        }
    }
    return data
}

// 个人中心 table 表头
export const UserColumns = [
    {
        title: '队伍名称/公司名称',
        dataIndex: 'team_name',
        width: '24%',
        key: 'team_name',
        render: (text, record) => (
            <div>{record.type_id === 3 ? <span>{record.team_name}</span> : <span>{record.team_info.cn_name && `${record.team_info.cn_name + '/'}`}{record.team_info.en_name}</span>}</div>
        ),
    },
    {
        title: '大赛类型',
        dataIndex: 'type_id',
        width: '14%',
        key: 'type_id',
        render: (text, record) => (
            <span>{text === 1 ? '创新大赛' : text === 2 ? '创业大赛' : '企业挑战'}</span>
        ),
    },
    {
        title: '最近更新日期',
        dataIndex: 'update_time',
        key: 'update_time',
        width: '20%',
        render: (text, record) => (
            <span>{moment(text * 1000).format('YYYY-MM-DD')}</span>
        ),
    },
    {
        title: <div style={{textAlign:'center'}}>操作</div>,
        dataIndex: 'id',
        key: 'id',
        render: (text,record) => {
            return <div className='opera'>
                {
                    console.log('text', text, 'record', record) 
                }
                {record.type_id !== 3 && <a href={goToDetailHref(record)} className='checkDetail'>查看详情</a>}
                {record.type_id === 3 && <span>重新上传材料</span>}
                {record.type_id === 3 && <span>删除</span>}
                {record.type_id === 1 && <span>Wiki</span>}
                <a href='/signup/challenge'>{record.type_id === 1 ? '企业挑战（选报)' : record.type_id === 2 ? '企业挑战（必报)' : ''}</a>
                {/* <a href='/signup/challenge'>企业挑战（必报）</a> */}
                {/* {record.type_id === 1 && <Badge offset={[3,0]} size='small' count={<Popover content='如需开发票，请联系info@synbiochallenges.com' trigger='click'><img src={require('../assets/images/zhuyi.png')} alt=''/></Popover>}>
                    {<a href>已支付</a>}
                </Badge>} */}
                {/* <a>去支付</a> */}
            </div>
        }
    },
]

export const goToDetailHref = data => {
    let href
    if (data.type_id === 1){ // type_id === 1创新大赛
        href = `/usercenter/innovate/${data.id}`
    } else if (data.type_id === 2){ // type_id === 2 创业大赛  
        // group_id === 1 未融资组/usercenter/business/nofund/:id group_id === 2 融资组 /usercenter/business/fund/:id 
        href = `/usercenter/business/${data.group_id === 1 ? 'no' : ''}fund/${data.id}`
    }
    return href
}
