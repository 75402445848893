import React, { Component } from 'react'
import { Modal, Button } from 'antd'
import 'styles/login/submitModal.scss'

const SubmitModal = props => {
    const { isTrue, text, close, ok } = props
    return (
        <Modal visible={isTrue} destroyOnClose footer={null} centered={true} className='submitModal' getContainer={false}>
            <div className='modal-body'>{text}</div>
            <div className='btns'>
                <Button className='btn' type='primary' onClick={()=>ok()}>确认</Button>
                <Button className='btn' type='primary' onClick={()=>close(false)}>取消</Button>
            </div>
        </Modal>
    )
}

export default SubmitModal