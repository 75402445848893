
/* eslint-disable */


function isPC(state = true,action) {
    const {type, status}  = action
    // console.log('action',action)
    switch (type) {
        case 'ISPC':
            return status
        default:
            return state
    }
}

export {isPC}