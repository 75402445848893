import React from 'react'
import className from 'classname'
import 'styles/comps/pack-up.scss'
import 'styles/notice/notice.scss'

class NoticeItem extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
            btnIsVisible: false,
            btnStatus:false
        }
        this.saveRef = React.createRef()
    }
    componentDidMount () {
        // console.log(this.props, 'props')
        if (this.saveRef.current && this.saveRef.current.offsetHeight > this.props.maxHeight) {
            this.setState({
                btnStatus: true,
                btnIsVisible: true
            })
        }
    }
    openCont = e =>{
        e.stopPropagation()
        this.setState({
            btnStatus: !this.state.btnStatus
        })
    }
    
    render(){
        const {maxHeight, introduce} = this.props
        return  <div>
            <div className={className('des-con')} style={{height: !this.state.btnStatus ? 'auto' : maxHeight + 'px'}} >
                <div  className="intro" ref={this.saveRef} dangerouslySetInnerHTML={{__html: introduce}}>
                </div> 
            </div>
            {
                this.state.btnIsVisible && this.state.btnStatus 
                    ? <div className="zhankai" onClick={this.openCont}><img src={require('assets/images/zhankai2.png')} alt=""/>展开</div> : 
                    this.state.btnIsVisible && <div className="zhankai" onClick={this.openCont}><img src={require('assets/images/shouqi2.png')} alt=""/>收起</div>
            }
        </div> 
        
    }
}
export default NoticeItem
