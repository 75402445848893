import React, { Component, useState } from 'react'
import 'styles/video/video.scss'
import { Pagination } from 'antd'
import axios from 'libs/axios'
import { useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import moment from 'moment'

function Video(props) {
    let history = useHistory()
    const [h5List, setH5List] = useState([])
    const [list, setList] = useState([])
    const [total, setTotal] = useState(0)
    const [page, setPage] = useState(1)
    const [h5Page, setH5Page] = useState(1)
    const [isLoading, setLoading] = useState(false)
    const [height, setHeight] = useState(0)
    const getList = page => {
        axios.post('/movie', { page: page, size: 12 }).then(res => {
            if (res.code === 10000) {
                setList(res.data.items)
                setTotal(res.data.total)
            }
        })
    }
    const getH5List = page =>{
        setLoading(false)
        axios.post('/movie', {page: page, size: 2}).then(res => {
            if (res.code === 10000) {
                setH5List(h5List.concat(res.data.items))
                setH5Page(h5Page + 1)
                setLoading(true)
            }
        })
    }
    const onChange = page => {
        setPage(page)
        getList(page)
    }
    const onScroll = () => {
        // console.log('滚动的高度', window.pageYOffset)
        // console.log('可使区高度', document.body.clientHeight)
        // console.log('页面高度', document.body.scrollHeight)
        let height = document.body.scrollHeight - document.body.clientHeight - window.pageYOffset
        if (height <= 0){
            total > h5List.length && getH5List(h5Page)
        }
    }
    const onTouchMove = () => {
        // console.log('滚动的高度', window.pageYOffset)
        // console.log('可使区高度', document.body.clientHeight)
        // console.log('页面高度', document.body.scrollHeight)
        let height = document.body.scrollHeight - document.body.clientHeight - window.pageYOffset
        // console.log('h', height)
        setHeight(height)
    }
    useEffect(() => {
        getList(1)
        getH5List(h5Page)
    }, [])
    return <>
        <div className='video'>
            <div className="video-box">
                {
                    list.map((item, index) => {
                        return <div className="video-item" key={index} onClick={() => window.open(item.url)}>
                            <img src={item.img} alt="1" />
                            <div className="video-body">
                                <div className="video-title" title={item.title}>{item.title}</div>
                                <div className="video-content" title={item.summary}>{item.summary}</div>
                            </div>
                            <div className="video-time">{moment(item.start_time * 1000).format('YYYY-MM-DD')}</div>
                        </div>
                    })
                }
            </div>
            <Pagination onChange={onChange} total={total} pageSize={12} current={page} />
        </div>
        <div className="video-h5">
            <div className="section-title">相关视频</div>
            <div className="video-body" onTouchEnd={()=>{onScroll()}} onTouchMove={() => {onTouchMove()}}>
                {
                    h5List.map((item,index)=>{
                        return <div className="video-item" key={index} onClick={() => window.location.href = item.url}>
                            <img src={item.img} alt="1"/>
                            <div className="video-title">{item.title}</div>
                            <div className="video-content">{item.summary}</div>
                            <div className="video-time">{moment(item.start_time * 1000).format('YYYY-MM-DD')}</div>
                        </div>
                    })
                }
                {height <= 0 && !isLoading &&  <div style={{textAlign: 'center', marginBottom: '10px'}}>加载中...</div>}
            </div>
        </div>
    </>

}

export default Video