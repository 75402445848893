import { createStore, applyMiddleware, compose } from 'redux'
// import { composeWithDevTools } from 'redux-devtools-extension'
import thunk from 'redux-thunk'
import rootReducer from './rootReducers'

import { persistStore, persistReducer } from 'redux-persist' // 持久化存储redux
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key:"root",
    storage
}

// 创建一个store
export default (initialState = {}) => {
    const persistedReducer = persistReducer(persistConfig, rootReducer)
    const store = createStore(persistedReducer, compose(applyMiddleware(thunk),
        window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f )) // 谷歌插件
    if (module.hot && process.env.NODE_ENV !== 'production') {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('./rootReducers', () => {
            const nextRootReducer = require('./rootReducers').default
            store.replaceReducer(nextRootReducer)
        })
    }
    let persistor = persistStore(store)
    return { store, persistor }
}
