
import React, { Component, useEffect, useState } from 'react'
import 'styles/signup/challenge.scss'
import PackUp from 'comps/PackUp'
import axios from 'libs/axios'
const Challenge = props => {
    const [awardsList, setAwardsList] = useState([])
    const [info, setInfo] = useState({})
    const [items, setItems] = useState([])
    const [time, setTime] = useState({})
    useEffect(() => {
        axios.post('/game/awards', { type: 3 }).then(res => {
            if (res.code === 10000) {
                setAwardsList([res.data[0]])
            }
        })
        axios.post('/game', { type: 3 }).then(res => {
            if (res.code === 10000) {
                setInfo(res.data.info)
                // let arr = res.data.items
                // arr.splice(6)
                // console.log('arr', arr)
                setItems(res.data.items)
            }
        })
    }, [])

    //点击报名
    const toSignUp = title => {
        props.history.push({ pathname:'/signup/challenge/topics/' + title })
    }
    return (
        <>
            <div className="sign-up-challenge">
                <div className='challenge'>
                    <div className="challenge-content">
                        <div className='title'>{info.title}</div>
                        <div className="entitle">{info.title_en}</div>
                        <div className="challenge-body">
                            {info.summary}
                        </div>
                        {info.last_url ? <a href={info.last_url} target="_blank" rel="noopener noreferrer" className='btnurl'><div className="btn" >决赛</div></a> : null}
                    </div>
                </div>
                {/* <div className='time'>
                    <div className="title"><div className='bor'></div><span>时间表</span></div>
                    <div className="time-img">
                        <img src={info.pc_timeline} alt=''/>
                    </div>
                </div> */}
                <div className="timetable">
                    {/* <img src={require('assets/images/time.png')} alt="" className='bg'/> */}
                    <div className="content">
                        <div className="title"><div className="bor"></div><span>时间表</span></div>
                        <img src={info.pc_timeline} alt="" className='timeimg'/>
                    </div>
                </div>
                <div className="apply">
                    {
                        items.map((item, index) => {
                            return (
                                <>
                                    {/* (index % 4 === 1 || index % 4 === 0) && (index === items.length - 1 || index === items.length) */}
                                    {
                                        (index % 4 === 1 && index === items.length) ||  (index === items.length - 1 &&  index % 4 === 0) ? <>
                                            <div className='apply-item apply-item-other 1' key={index}>
                                                <img src={item.img} alt=''/>
                                                <div className="apply-btn-other">
                                                    <img src={require('assets/images/redzuo.png')} alt=''/>
                                                    <div>{item.title}</div>
                                                    {
                                                        item.status === 4 ? <button className='apply-yes' onClick={() => toSignUp(item.title)}>点击报名</button> : <button className='apply-no'>报名未开始</button>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                            :
                                            <>
                                                {
                                                    index % 2 === 0 ?
                                                        <div className="apply-item 2" key={index}>
                                                            <img src={item.img} alt=''/>
                                                            <div className="apply-btn">
                                                                <img src={require('assets/images/redshang.png')} alt=''/>
                                                                <div>{item.title}</div>
                                                                {
                                                                    item.status === 4 ? <button className='apply-yes' onClick={() => toSignUp(item.title)}>点击报名</button> : <button className='apply-no'>报名未开始</button>
                                                                }
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="apply-item 3" key={index}>
                                                            <div className="apply-btn">
                                                                <div>{item.title}</div>
                                                                {
                                                                    item.status === 4 ? <button className='apply-yes' onClick={() => toSignUp(item.title)}>点击报名</button> : <button className='apply-no'>报名未开始</button>
                                                                }
                                                                <img src={require('assets/images/redxia.png')} alt=''/>
                                                            </div>
                                                            <img src={item.img} alt=''/>
                                                        </div>
                                                }
                                            </>
                                    }
                                </>
                            )
                        })
                    }
                </div>
                {/* 报名指南 */}
                <div className='guide'>
                    <img src={require('assets/images/back2.png')} alt=''/>
                    <div className="guide-center">
                        <div className='guide-body'>
                            <div className='guide-title'>
                                <img src={require('assets/images/title3.png')} alt=''/>
                        报名指南
                            </div>
                            {info.guide && <PackUp maxHeight={600} introduce={info.guide} />}
                        </div>
                    </div>
                </div>
                {/* 评审标准 */}
                <div className='review'>
                    <div className="review-center">
                        <div className='review-body'>
                            <div className='review-title'>
                                <img src={require('assets/images/title3.png')} alt=''/>
                        评审标准
                            </div>
                            {info.standard && <PackUp maxHeight={600} introduce={info.standard} />}
                        </div>
                    </div>
                </div>
                {/* 奖项 */}
                <div className="awards">
                    <div className="awards-content">
                        <div className='awards-title'>
                            <img src={require('assets/images/title3.png')} alt=''/>
                        奖项
                        </div>
                        <div className="awards-body">
                            {
                                awardsList.map((item, index) => {
                                    return <div className="awards-item" key={index}>
                                        <div className="awards-back" style={{ background: `url(${item.back_img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                            <img src={item.img} alt=''/>
                                        </div>
                                        <div className="rank">{item.title}</div>
                                        <div className="awards-intro">
                                            {item.summary}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className="sign-up-challenge-h5">
                <div className='challenge'>
                    <div className="challenge-content">
                        <div className='title'>{info.title}</div>
                        <div className="entitle">{info.title_en}</div>
                        <div className="challenge-body">
                            {info.summary}
                        </div>
                        {info.last_url ? <a href={info.last_url} target="_blank" rel="noopener noreferrer" className='btnurl'><div className="btn" >决赛</div></a> : null}
                    </div>
                </div>
                <div className='time'>
                    <div className="title"><div className='bor'></div><span>时间表</span></div>
                    <div className="time-img">
                        <img src={info.m_timeline} alt=''/>
                    </div>
                </div>
                <div className="apply">
                    <div className="apply-content">
                        {
                            items.map((item,index)=>{
                                return <div className="apply-item" key={index}>
                                    <img src={item.img} alt=''/>
                                    <div>{item.title}</div>
                                    {
                                        item.status === 4 ? <button className='apply-yes' onClick={() => toSignUp(item.title)}>点击报名</button> : <button className='apply-no'>报名未开始</button>
                                    }
                                </div>
                            })
                        }
                    </div>
                </div>
                {/* 报名指南 */}
                <div className='guide'>
                    <div className="guide-center">
                        <div className='guide-body'>
                            <div className='guide-title'>
                                <img src={require('assets/images/title3.png')} alt=''/>
                        报名指南
                            </div>
                            {info.guide && <PackUp maxHeight={600} introduce={info.guide} />}
                        </div>
                    </div>
                </div>
                {/* 评审标准 */}
                <div className='review'>
                    <div className="review-center">
                        <div className='review-body'>
                            <div className='review-title'>
                                <img src={require('assets/images/title3.png')} alt=''/>
                        评审标准
                            </div>
                            {info.standard && <PackUp maxHeight={600} introduce={info.standard} />}
                        </div>
                    </div>
                </div>
                {/* 奖项 */}
                <div className="awards">
                    <div className="awards-content">
                        <div className='awards-title'>
                            <img src={require('assets/images/title3.png')} alt=''/>
                        奖项
                        </div>
                        <div className="awards-body">
                            {
                                awardsList.map((item, index) => {
                                    return <div className="awards-item" key={index}>
                                        <div className="awards-back" style={{ background: `url(${item.back_img})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
                                            <img src={item.img} alt=''/>
                                        </div>
                                        <div className="rank">{item.title}</div>
                                        <div className="awards-intro">
                                            {item.summary}
                                        </div>
                                    </div>
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Challenge